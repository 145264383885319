import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirm } from '@actions';

const useConfirm = () => {
  const dispatch = useDispatch();
  const { confirm } = useSelector((state) => state.notifications);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = useCallback(
    (prompt, buttonLabels = { proceed: 'Ok', cancel: 'Cancel' }) => {
      const promise = new Promise((resolve, reject) => {
        dispatch(
          setConfirm({
            prompt,
            isOpen: true,
            proceed: resolve,
            cancel: reject,
            buttonLabels
          })
        );
        setNeedsCleanup(true);
      });

      return promise.then(
        () => {
          dispatch(setConfirm({ isOpen: false }));
          return true;
        },
        () => {
          dispatch(setConfirm({ isOpen: false }));
          return false;
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed
  };
};

export default useConfirm;
