import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCell = styled.div`
  padding: 0.5rem;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cell = ({ children, ...rest }) => (
  <StyledCell {...rest}>{children}</StyledCell>
);

Cell.propTypes = {
  children: PropTypes.node
};

export default Cell;
