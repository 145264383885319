import React from 'react';
import PropTypes from 'prop-types';
import { handleClientSideSearch } from '@utils';
import FormLabel from '../FormLabel';
import SearchSelector from '../../SearchSelector';
import { countries } from './countries';

const CountryField = ({ formik, name, label, stateFieldName }) => {
  const handleSearch = (searchStr) =>
    handleClientSideSearch({
      searchStr,
      searchItems: countries,
      searchOn: 'label'
    });

  return (
    <>
      <FormLabel for={name}>{label}*</FormLabel>
      <SearchSelector
        fieldName={name}
        initialValue={formik.values[name]}
        resolveValue={(value) =>
          countries.find((item) => item.value === value)?.label
        }
        handleSearch={handleSearch}
        Item={({ item }) => (
          <div
            key={item.value}
            onClick={() => {
              // first set Country field value
              formik.setFieldValue(name, item.value);
              // then set State field value to empty since it might not match the new country
              if (formik.values[stateFieldName]) {
                formik.setFieldValue(stateFieldName, '');
              }
            }}
            tabIndex={-1}
            className='p-2'
          >
            {item.label}
          </div>
        )}
      />
    </>
  );
};

CountryField.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  stateFieldName: PropTypes.string.isRequired
};

export default CountryField;
