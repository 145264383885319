import { getErrorToast, getErrorMessage } from '@utils';
import {
  SET_TOAST,
  GET_ITEM_FULFILLMENT,
  BULK_ADD_ITEMS,
  TOGGLE_LOADING_GL_ENTRIES
} from '../types';

// Temp allowing unused vars until server endpoints set up
// so we can see which args should be passed
/* eslint-disable no-unused-vars */

export const getItemFulfillment =
  (fulfillmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_GL_ENTRIES,
        payload: true
      });

      const itemFulfillmentRes = await api.get(
        `/item-fulfillment/${fulfillmentId}`
      );

      dispatch({
        type: GET_ITEM_FULFILLMENT,
        payload: itemFulfillmentRes.data.summary
      });
      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'itemFulfillment',
          items: itemFulfillmentRes.data.items
        }
      });

      dispatch({
        type: TOGGLE_LOADING_GL_ENTRIES,
        payload: false
      });

      return itemFulfillmentRes.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const deleteItemFulfillment =
  (fulfillmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/item-fulfillment/${fulfillmentId}`);

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const swapSublineItemFulfillments =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/item-fulfillment/swapItems/`, formValues);

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'itemFulfillment',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
