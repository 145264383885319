import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { startOfMonth, endOfMonth } from 'date-fns';
import { formatDate } from '@utils';
import InlineTextInputForm from './Forms/InlineTextInputForm';

const StyledForm = styled(InlineTextInputForm)`
  max-width: 400px;
`;

export const DateRangeSearchForm = ({
  name = 'dateRange',
  label = 'Search by Date',
  handleSearch,
  initialStart = new Date(),
  initialEnd = new Date(),
  useDefaultInitialDateRange = true
}) => {
  const initialDateRange = `${formatDate(
    startOfMonth(initialStart)
  )} - ${formatDate(endOfMonth(initialEnd))}`;

  const handleSubmit = useCallback(
    async ({ dateRange }) => {
      const [start, end] = dateRange.split(' - ') || [];
      const startDate = start ? new Date(start).getTime() : null;
      const endDate = end ? new Date(end).getTime() : null;

      if (startDate && endDate) {
        await handleSearch({ startDate, endDate });
      }
    },
    [handleSearch]
  );

  useEffect(() => {
    if (useDefaultInitialDateRange) {
      handleSubmit({ dateRange: initialDateRange });
    }
  }, [handleSubmit, initialDateRange, useDefaultInitialDateRange]);

  return (
    <StyledForm
      field={{
        name,
        label,
        componentName: 'DateRangeInput',
        initialValue: useDefaultInitialDateRange ? initialDateRange : '',
        validator: Yup.string().required('Date Range is required')
      }}
      handleSubmit={handleSubmit}
      buttons={[{ text: 'Search', type: 'submit', color: 'primary' }]}
      displayValidStatus={false}
    />
  );
};

DateRangeSearchForm.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  initialStart: PropTypes.any,
  initialEnd: PropTypes.any,
  handleSearch: PropTypes.func.isRequired,
  useDefaultInitialDateRange: PropTypes.bool
};

export default DateRangeSearchForm;
