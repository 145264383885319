import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_VENDOR_PROFILE_SUCCESS,
  UPDATE_VENDOR_PROFILE_SUCCESS,
  SAVE_VENDOR_CONTACT_SUCCESS,
  UPDATE_VENDOR_CONTACT_SUCCESS,
  MERGE_VENDOR_CONTACT_SUCCESS,
  SAVE_CONTACT_EMAIL_SUCCESS,
  REMOVE_CONTACT_EMAIL_SUCCESS,
  SAVE_CONTACT_PHONE_SUCCESS,
  REMOVE_CONTACT_PHONE_SUCCESS,
  SAVE_VENDOR_INSTRUCTIONS_SUCCESS,
  REMOVE_CONTACT_SUCCESS,
  SET_TOAST,
  TOGGLE_PAGE_ERROR,
  RESET_VENDOR_PROFILE,
  CREATE_VENDOR_ADDRESS,
  UPDATE_VENDOR_ADDRESS,
  DELETE_VENDOR_ADDRESS,
  UPDATE_VENDOR_COSTS_SUCCESS,
  GET_MANUFACTURERS_SUCCESS
} from '../types';

export const saveVendorProfile =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post('/vendors', formValues);

      if (!res.data) {
        throw new Error('Unable to add vendor, please try again');
      }

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateVendorProfile =
  (vendorId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/vendors/${vendorId}`, formValues);

      dispatch({
        type: UPDATE_VENDOR_PROFILE_SUCCESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateVendorPrices =
  (data) =>
  async (dispatch, _, { pnetApi }) => {
    try {
      const res = await pnetApi.post(`/api/inventory/vendor-prices`, data);

      dispatch({
        type: UPDATE_VENDOR_COSTS_SUCCESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      throw e;
    }
  };

export const getVendorProfile =
  (vendorId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/vendors/${vendorId}`);

      dispatch({
        type: GET_VENDOR_PROFILE_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getManufacturers =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get('/vendors/manufacturers');

      dispatch({
        type: GET_MANUFACTURERS_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const createVendorAddress =
  (vendorId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/vendors/${vendorId}/address`, formValues);

      dispatch({
        type: CREATE_VENDOR_ADDRESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const updateVendorAddress =
  (vendorId, addressId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/vendors/${vendorId}/address/${addressId}`,
        formValues
      );

      dispatch({
        type: UPDATE_VENDOR_ADDRESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const deleteVendorAddress =
  (id) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/vendors/address/${id}`);

      dispatch({
        type: DELETE_VENDOR_ADDRESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const saveVendorContact =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/vendors/contact`, formValues);

      if (!res.data) {
        throw new Error('Unable to add contact');
      }

      dispatch({
        type: SAVE_VENDOR_CONTACT_SUCCESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const updateVendorContact =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/vendors/contact/${formValues.contactId}`,
        formValues
      );

      if (!res.data) {
        throw new Error('Unable to update contact');
      }

      dispatch({
        type: UPDATE_VENDOR_CONTACT_SUCCESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const makeVendorContactPrimary =
  (contactId, vendorId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/vendors/contact/${contactId}/make-primary`, {
        vendorId
      });

      if (!res.data) {
        throw new Error('Unable to update contact');
      }

      dispatch({
        type: MERGE_VENDOR_CONTACT_SUCCESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const removeVendorContact =
  (contactId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/vendors/contact/${contactId}`);

      if (!res.data) {
        throw new Error('Unable to delete contact');
      }

      dispatch({
        type: REMOVE_CONTACT_SUCCESS,
        payload: { contactId }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const saveContactEmail =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post('/vendors/contact/email', formValues);

      if (!res.data) {
        throw new Error('Unable to add email');
      }

      dispatch({
        type: SAVE_CONTACT_EMAIL_SUCCESS,
        payload: {
          email: {
            contactId: formValues.contactId,
            ...res.data
          }
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateContactEmail =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/vendors/contact/email/${formValues.contactId}`,
        formValues
      );

      if (!res.data) {
        throw new Error('Unable to update email');
      }

      dispatch({
        type: UPDATE_VENDOR_CONTACT_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeContactemail =
  (id, emailId, contactId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/vendors/contact/email/${contactId}`, {
        data: { id, emailId }
      });

      if (!res.data) {
        throw new Error('Unable to delete email');
      }

      dispatch({
        type: REMOVE_CONTACT_EMAIL_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const saveContactPhone =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post('/vendors/contact/phone', formValues);

      if (!res.data) {
        throw new Error('Unable to add phone');
      }

      dispatch({
        type: SAVE_CONTACT_PHONE_SUCCESS,
        payload: {
          contactId: formValues.contactId,
          ...res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateContactPhone =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/vendors/contact/phone/${formValues.contactId}`,
        formValues
      );

      if (!res.data) {
        throw new Error('Unable to update phone');
      }

      dispatch({
        type: UPDATE_VENDOR_CONTACT_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeContactphone =
  (id, phoneId, contactId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/vendors/contact/phone/${contactId}`, {
        data: { id, phoneId }
      });

      if (!res.data) {
        throw new Error('Unable to delete phone');
      }

      dispatch({
        type: REMOVE_CONTACT_PHONE_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const mergeVendorContacts =
  (vendorId, fromIds, toId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/vendors/contact/merge/${vendorId}`, {
        fromIds,
        toId
      });

      if (!res.data) {
        throw new Error('Unable to merge contacts');
      }

      dispatch({
        type: MERGE_VENDOR_CONTACT_SUCCESS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const saveVendorInstructions =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/vendors/instruction`, formValues);

      if (!res.data) {
        throw new Error('Unable to update instructions');
      }

      dispatch({
        type: SAVE_VENDOR_INSTRUCTIONS_SUCCESS,
        payload: formValues
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetVendorProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_VENDOR_PROFILE
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
