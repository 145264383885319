import produce from 'immer';
import {
  GET_OPEN_PURCHASE_ORDERS,
  TOGGLE_LOADING_OPEN_PURCHASE_ORDERS,
  GET__CLOSED_PURCHASE_ORDERS,
  TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS
} from '@actions/types';

export const initialState = {
  closed: { data: [], loading: false },
  open: {
    data: [],
    loading: false
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_OPEN_PURCHASE_ORDERS:
      draft.open.data = action.payload;
      draft.open.loading = false;
      return draft;

    case TOGGLE_LOADING_OPEN_PURCHASE_ORDERS:
      draft.open.loading = action.payload;
      return draft;

    case GET__CLOSED_PURCHASE_ORDERS:
      draft.closed.data = action.payload;
      draft.closed.loading = false;
      return draft;

    case TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS:
      draft.closed.loading = action.payload;
      return draft;

    default:
      return draft;
  }
}, initialState);
