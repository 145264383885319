import castAsNumber from './castAsNumber';

const getExtPrice = (
  { unitQuantity = 1, ...rest },
  val = 'expectedUnitPrice'
) => {
  return castAsNumber(rest[val]) * castAsNumber(unitQuantity);
};

export default getExtPrice;
