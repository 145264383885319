import { usePagination as rtUsePagination } from 'react-table';

const usePagination = (config, options, paginate, buffer = 350) => {
  if (!paginate) {
    return { config, options };
  }

  // Get pageSize based on height of window;
  const rowHeight = 35;
  const pageSize = Math.floor((window.innerHeight - buffer) / rowHeight);

  const resolvedConfig = {
    ...config,
    initialState: {
      ...(config.initialState || {}),
      pageSize,
      pageIndex: 0
    }
  };

  const resolvedOptions = [...options, rtUsePagination];

  return { config: resolvedConfig, options: resolvedOptions };
};

export default usePagination;
