import { useState } from 'react';

const useEditableTable = (config, editable) => {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [editingCell, setEditingCell] = useState(null);

  if (!editable) {
    return { config };
  }

  return {
    config: {
      ...config,
      autoResetPage: !skipPageReset,
      setSkipPageReset,
      editingCell,
      setEditingCell
    }
  };
};

export default useEditableTable;
