import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputGroup, InputGroupAddon, Button, ButtonGroup } from 'reactstrap';
import TextInput from './TextInput';
import DateInput from './DateInput';
import PhoneInput from './PhoneInput';
import DateRangeInput from './DateRangeInput';

const StyledButtonGroup = styled(ButtonGroup)`
  button:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const TextInputWithButtons = ({
  buttons,
  inputVariant = 'TextInput',
  ...props
}) => {
  const variants = { TextInput, DateInput, PhoneInput, DateRangeInput };
  const Input = variants[inputVariant];
  return (
    <InputGroup>
      <Input {...props} />
      <InputGroupAddon addonType='append'>
        <StyledButtonGroup>
          {buttons.map((item, idx) => (
            <Button key={idx} {...item}>
              {item.text}
            </Button>
          ))}
        </StyledButtonGroup>
      </InputGroupAddon>
    </InputGroup>
  );
};

TextInputWithButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  inputVariant: PropTypes.string
};

export default TextInputWithButtons;
