// globals
export const GET_GLOBAL_SETTINGS_REQUEST = 'GET_GLOBAL_SETTINGS_REQUEST';
export const GET_GLOBAL_SETTINGS_SUCCESS = 'GET_GLOBAL_SETTINGS_SUCCESS';
export const GET_GLOBAL_SETTINGS_FAILURE = 'GET_GLOBAL_SETTINGS_FAILURE';
export const GET_SHIPPING_LOCATIONS = 'GET_SHIPPING_LOCATIONS';
export const GET_POSTING_PERIODS = 'GET_POSTING_PERIODS';
export const ADD_POSTING_PERIOD = 'ADD_POSTING_PERIOD';
export const UPDATE_POSTING_PERIOD = 'UPDATE_POSTING_PERIOD';
export const SET_TOAST = 'SET_TOAST';
export const SET_CONFIRM = 'SET_CONFIRM';
export const ADD_LOCATION_SECTIONS = 'ADD_LOCATION_SECTIONS';
export const ADD_LOCATION_SECTION = 'ADD_LOCATION_SECTION';
export const UPDATE_LOCATION_SECTION = 'UPDATE_LOCATION_SECTION';
export const REMOVE_LOCATION_SECTION = 'REMOVE_LOCATION_SECTION';
export const ADD_LOCATION_BIN = 'ADD_LOCATION_BIN';
export const UPDATE_LOCATION_BIN = 'UPDATE_LOCATION_BIN';
export const REMOVE_LOCATION_BIN = 'REMOVE_LOCATION_BIN';
export const GET_VENDORS = 'GET_VENDORS';
export const GET_SHIPMENTS = 'GET_SHIPMENTS';
export const GET_SCHEDULED_SHIPMENTS = 'GET_SCHEDULED_SHIPMENTS';
export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
export const ADD_SHIPMENT = 'ADD_SHIPMENT';
export const REMOVE_SHIPMENT = 'REMOVE_SHIPMENT';
export const TOGGLE_PAGE_ERROR = 'TOGGLE_PAGE_ERROR';
export const GET_LOCATION_TRANSFERS = 'GET_LOCATION_TRANSFERS';
export const GET_SHIPMENT_NOTIFICATIONS = 'GET_SHIPMENT_NOTIFICATIONS';
export const GET_WAREHOUSE_DOORS = 'GET_WAREHOUSE_DOORS';

// itemFulfillments
export const GET_ITEM_FULFILLMENTS = 'GET_ITEM_FULFILLMENTS';
export const GET_ITEM_FULFILLMENT = 'GET_ITEM_FULFILLMENT';
export const UPDATE_ITEM_FULFILLMENT_PROPERTY =
  'UPDATE_ITEM_FULFILLMENT_PROPERTY';

// vendor
export const SAVE_VENDOR_PROFILE_SUCCESS = 'SAVE_VENDOR_PROFILE_SUCCESS';
export const UPDATE_VENDOR_PROFILE_SUCCESS = 'UPDATE_VENDOR_PROFILE_SUCCESS';
export const UPDATE_VENDOR_COSTS_SUCCESS = 'UPDATE_VENDOR_COSTS_SUCCESS';
export const GET_VENDOR_PROFILE_SUCCESS = 'GET_VENDOR_PROFILE_SUCCESS';
export const SAVE_VENDOR_CONTACT_SUCCESS = 'GET_VENDOR_CONTACT_SUCCESS';
export const REMOVE_CONTACT_SUCCESS = 'REMOVE_CONTACT_SUCCESS';
export const UPDATE_VENDOR_CONTACT_SUCCESS = 'UPDATE_VENDOR_CONTACT_SUCCESS';
export const MERGE_VENDOR_CONTACT_SUCCESS = 'MERGE_VENDOR_CONTACT_SUCCESS';
export const SAVE_CONTACT_EMAIL_SUCCESS = 'SAVE_CONTACT_EMAIL_SUCCESS';
export const REMOVE_CONTACT_EMAIL_SUCCESS = 'REMOVE_CONTACT_EMAIL_SUCCESS';
export const SAVE_CONTACT_PHONE_SUCCESS = 'SAVE_CONTACT_PHONE_SUCCESS';
export const REMOVE_CONTACT_PHONE_SUCCESS = 'REMOVE_CONTACT_PHONE_SUCCESS';
export const MERGE_VENDOR_CONTACT_FAILURE = 'MERGE_VENDOR_CONTACT_FAILURE';
export const SAVE_VENDOR_INSTRUCTIONS_SUCCESS =
  'SAVE_VENDOR_INSTRUCTIONS_SUCCESS';
export const RESET_VENDOR_PROFILE = 'RESET_VENDOR_PROFILE';
export const CREATE_VENDOR_ADDRESS = 'CREATE_VENDOR_ADDRESS';
export const UPDATE_VENDOR_ADDRESS = 'UPDATE_VENDOR_ADDRESS';
export const DELETE_VENDOR_ADDRESS = 'DELETE_VENDOR_ADDRESS';
export const GET_MANUFACTURERS_SUCCESS = 'GET_MANUFACTURERS_SUCCESS';

// purchaseOrder
export const SAVE_PO_SUCCESS = 'SAVE_PO_SUCCESS';
export const ADD_PO_ASAP_ITEM = 'ADD_PO_ASAP_ITEM';
export const SAVE_PO_ASAP_ITEMS = 'SAVE_PO_ASAP_ITEMS';
export const GET_PO_SUMMARY = 'GET_PO_SUMMARY';
export const UPDATE_PO_SUMMARY = 'UPDATE_PO_SUMMARY';
export const BULK_RECEIVE_PO_ITEMS = 'BULK_RECEIVE_PO_ITEMS';
export const RESET_PO = 'RESET_PO';

// transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const TOGGLE_LOADING_TRANSACTIONS = 'TOGGLE_LOADING_TRANSACTIONS';

// glEntries
export const GET_GL_ENTRIES = 'GET_GL_ENTRIES';
export const ADD_GL_ENTRY = 'ADD_GL_ENTRY';
export const TOGGLE_LOADING_GL_ENTRIES = 'TOGGLE_LOADING_GL_ENTRIES';
export const SET_ERROR_GL_ENTRIES = 'SET_ERROR_GL_ENTRIES';
export const REMOVE_GL_ENTRIES = 'REMOVE_GL_ENTRIES';

// itemReceipt
export const GET_ITEM_RECEIPT_SUMMARY = 'ADD_ITEM_RECEIPT';
export const UPDATE_ITEM_RECEIPT_SUMMARY = 'UPDATE_ITEM_RECEIPT_SUMMARY';
export const RESET_ITEM_RECEIPT = 'RESET_ITEM_RECEIPT';
export const UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY =
  'UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY';

// bill
export const GET_BILL_SUMMARY = 'GET_BILL_SUMMARY';
export const UPDATE_BILL_SUMMARY = 'UPDATE_BILL_SUMMARY';
export const GET_BILL_EXPENSES = 'GET_BILL_EXPENSES';
export const ADD_BILL_EXPENSE = 'ADD_EXPENSE';
export const UPDATE_BILL_EXPENSE = 'UPDATE_EXPENSE';
export const REMOVE_BILL_EXPENSE = 'REMOVE_BILL_EXPENSE';
export const ALLOCATE_BILL_EXPENSES = 'ALLOCATE_BILL_EXPENSES';
export const RESET_BILL = 'RESET_BILL';

// locationTransfer
export const GET_LOCATION_TRANSFER = 'GET_LOCATION_TRANSFER';
export const GET_LOCATION_TRANSFER_SUMMARY = 'GET_LOCATION_TRANSFER_SUMMARY';
export const UPDATE_LOCATION_TRANSFER = 'UPDATE_LOCATION_TRANSFER';
export const UPDATE_LOCATION_TRANSFER_STATUS =
  'UPDATE_LOCATION_TRANSFER_STATUS';
export const UPDATE_SHIPMENT_STATUS = 'UPDATE_SHIPMENT_STATUS';

// delivery
export const GET_DELIVERY = 'GET_DELIVERY';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';
export const UPDATE_DELIVERY_PROPERTIES = 'UPDATE_DELIVERY_PROPERTIES';

// items
export const BULK_ADD_ITEMS = 'BULK_ADD_ITEMS';
export const BULK_CLEAR_ITEMS = 'BULK_CLEAR_ITEMS';
export const AGGREGATE_ITEMS = 'AGGREGATE_ITEMS';
export const BULK_REMOVE_ITEMS = 'BULK_REMOVE_ITEMS';
export const BULK_REMOVE_ITEMS_BY_ID = 'BULK_REMOVE_ITEMS_BY_ID';
export const BULK_UPDATE_ITEMS = 'BULK_UPDATE_ITEMS';
export const TOGGLE_LOADING_ITEMS = 'TOGGLE_LOADING_ITEMS';

// attachments
export const GET_ATTACHMENTS = 'GET_ATTACHMENTS';
export const ADD_ATTACHMENTS = 'ADD_ATTACHMENTS';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';

// item details
export const GET_ITEM_DETAILS = 'GET_ITEM_DETAILS';
export const UPDATE_ITEM_INFO = 'UPDATE_ITEM_INFO';
export const GET_ITEM_SUPPORTING_DATA = 'GET_ITEM_SUPPORTING_DATA';
export const GET_ITEM_VENDORS = 'GET_ITEM_VENDORS';
export const ADD_ITEM_VENDOR = 'ADD_ITEM_VENDOR';
export const REMOVE_ITEM_VENDOR = 'REMOVE_ITEM_VENDOR';
export const GET_ITEM_LOCATIONS = 'GET_ITEM_LOCATIONS';
export const GET_ITEM_HISTORY = 'GET_ITEM_HISTORY';
export const RESET_ITEM_DETAILS = 'RESET_ITEM_DETAILS';
export const GET_ITEM_SUBLINE_ITEMS = 'GET_ITEM_SUBLINE_ITEMS';
export const GET_ITEM_TRANSACTIONS = 'GET_ITEM_TRANSACTIONS';
export const SET_ITEM_TRANSACTIONS_LOADING = 'SET_ITEM_TRANSACTIONS_LOADING';
export const SET_ITEM_TRANSACTIONS_ERROR = 'SET_ITEM_TRANSACTIONS_ERROR';

// alpine item associations
export const GET_ALPINE_ITEM_INFO = 'GET_ALPINE_ITEM_INFO';
export const GET_ASSOCIATIONS = 'GET_ASSOCIATIONS';
export const ADD_ASSOCIATION = 'ADD_ASSOCIATION';
export const UPDATE_ASSOCIATION = 'UPDATE_ASSOCIATION';
export const REMOVE_ASSOCIATION = 'REMOVE_ASSOCIATION';
export const RESET_ASSOCIATION = 'RESET_ASSOCIATION';

// inventory items
export const GET_INVENTORY_ITEMS = 'GET_INVENTORY_ITEMS';
export const UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';

// subline items
export const GET_SUBLINE_ITEMS = 'GET_SUBLINE_ITEMS';
export const SET_LOADING_SUBLINE_ITEMS = 'SET_LOADING_SUBLINE_ITEMS';
export const UPDATE_SUBLINE_ITEM = 'UPDATE_SUBLINE_ITEM';

// subline item details
export const GET_SUBLINE_ITEM_DETAILS = 'GET_SUBLINE_ITEM_DETAILS';
export const GET_SUBLINE_ITEM_TRANSACTIONS = 'GET_SUBLINE_ITEM_TRANSACTIONS';
export const SET_SUBLINE_ITEM_TRANSACTIONS_LOADING =
  'SET_SUBLINE_ITEM_TRANSACTIONS_LOADING';
export const RESET_SUBLINE_ITEM = 'RESET_SUBLINE_ITEM';

// inventory adjustment
export const GET_INVENTORY_ADJUSTMENTS = 'GET_INVENTORY_ADJUSTMENTS';
export const GET_INVENTORY_ADJUSTMENT_SUMMARY =
  'GET_INVENTORY_ADJUSTMENT_SUMMARY';
export const UPDATE_INVENTORY_ADJUSTMENT = 'UPDATE_INVENTORY_ADJUSTMENT';
export const DELETE_INVENTORY_ADJUSTMENT = 'DELETE_INVENTORY_ADJUSTMENT';
export const GET_INVENTORY_ADJUSTMENT_ITEMS = 'GET_INVENTORY_ADJUSTMENT_ITEMS';
export const ADD_INVENTORY_ADJUSTMENT_ITEMS = 'ADD_INVENTORY_ADJUSTMENT_ITEMS';
export const GET_INVENTORY_ADJUSTMENT_SUPPORTING_DATA =
  'GET_INVENTORY_ADJUSTMENT_SUPPORTING_DATA';

// expenses
export const GET_UNALLOCATED_EXPENSES = 'GET_UNALLOCATED_EXPENSES';
export const TOGGLE_LOADING_UNALLOCATED_EXPENSES =
  'TOGGLE_LOADING_UNALLOCATED_EXPENSES';
export const GET_ALLOCATED_EXPENSES = 'GET_ALLOCATED_EXPENSES';
export const TOGGLE_LOADING_ALLOCATED_EXPENSES =
  'TOGGLE_LOADING_ALLOCATED_EXPENSES';

// purchase orders
export const GET_OPEN_PURCHASE_ORDERS = 'GET_OPEN_PURCHASE_ORDERS';
export const TOGGLE_LOADING_OPEN_PURCHASE_ORDERS =
  'TOGGLE_LOADING_OPEN_PURCHASE_ORDERS';
export const GET__CLOSED_PURCHASE_ORDERS = 'GET__CLOSED_PURCHASE_ORDERS';
export const TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS =
  'TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS';

// history

export const GET_HISTORY = 'GET_HISTORY';
export const SET_HISTORY_LOADING = 'SET_HISTORY_LOADING';
export const SET_HISTORY_ERROR = 'SET_HISTORY_ERROR';
