import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styled from 'styled-components';
import TextInput from './TextInput';

/* eslint-disable react/display-name, react/prop-types */

const StyledInputGroup = styled(InputGroup)`
  .input-group-text {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const TextInputWithUnits = ({ unit, ...rest }) => (
  <StyledInputGroup>
    <TextInput {...rest} />
    <InputGroupAddon addonType='append'>
      <InputGroupText>{unit}</InputGroupText>
    </InputGroupAddon>
  </StyledInputGroup>
);

TextInputWithUnits.propTypes = {
  unit: PropTypes.string.isRequired
};

export default TextInputWithUnits;
