import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_ITEM_RECEIPT_SUMMARY,
  UPDATE_ITEM_RECEIPT_SUMMARY,
  BULK_ADD_ITEMS,
  SET_TOAST,
  BULK_UPDATE_ITEMS,
  TOGGLE_PAGE_ERROR,
  RESET_ITEM_RECEIPT,
  TOGGLE_LOADING_ITEMS,
  GET_TRANSACTIONS,
  GET_GL_ENTRIES,
  GET_ATTACHMENTS,
  UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY
} from '../types';

/* eslint-disable no-unused-vars */

export const getItemReceiptSummary =
  (itemReceiptId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/item-receipts/header/${itemReceiptId}`);

      if (!res.data) {
        throw new Error(`Item Receipt not found with id ${itemReceiptId}`);
      }

      dispatch({
        type: GET_ITEM_RECEIPT_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getItemReceiptItems =
  (itemReceiptId) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: true
      });

      const res = await api.get(`/item-receipts/items/${itemReceiptId}`);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'itemReceipt',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    } finally {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: false
      });
    }
  };

export const updateItemReceiptSummary =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/item-receipts/header/${formValues.itemReceiptId}`,
        formValues
      );

      if (!res.data) {
        throw new Error(
          `Item Receipt not found with id ${formValues.itemReceiptId}`
        );
      }

      dispatch({
        type: UPDATE_ITEM_RECEIPT_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateItemReceiptItems =
  (itemReceiptId, items) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/item-receipts/items/${itemReceiptId}`, {
        items
      });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'itemReceipt',
          items: res.data.items
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const updateItemReceiptItemQuantity =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/item-receipts/items-quantity/${formValues.itemReceiptId}`,
        formValues
      );

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'itemReceipt',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeItemReceiptItems =
  (itemReceiptId, items) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/item-receipts/items`, {
        data: { itemReceiptId, items }
      });

      if (res.data.items.length === 0) {
        dispatch({
          type: UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY,
          payload: {
            key: 'status',
            value: 'Void'
          }
        });
      }

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'itemReceipt',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const voidItemReceipt =
  (itemReceiptId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/item-receipts/${itemReceiptId}`);

      dispatch({
        type: UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY,
        payload: {
          key: 'status',
          value: 'Void'
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const resetItemReceipt = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ITEM_RECEIPT
    });

    dispatch({
      type: BULK_ADD_ITEMS,
      payload: {
        itemsName: 'itemReceipt',
        items: []
      }
    });

    dispatch({
      type: GET_TRANSACTIONS,
      payload: []
    });

    dispatch({
      type: GET_GL_ENTRIES,
      payload: []
    });

    dispatch({
      type: GET_ATTACHMENTS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
