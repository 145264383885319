const handleClientSideSearch = ({ searchStr, searchItems, searchOn }) => {
  const results = searchItems
    .filter((item) =>
      item[searchOn].toLowerCase().includes(searchStr.toLowerCase())
    )
    .slice(0, 10);

  if (results.length) {
    return results;
  }

  // If no direct match, see if any words is search term
  // match the manufacturer name
  const words = searchStr.split(' ').map((word) => word.toLowerCase());

  if (words.length > 1) {
    return searchItems.filter((item) =>
      words.some((word) => item[searchOn].toLowerCase().includes(word))
    );
  }

  return [];
};

export default handleClientSideSearch;
