import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import BaseForm from './BaseForm';
import BaseField from './BaseField';
import { ButtonGroup } from '../StyledComponents';

const FormGenerator = ({
  fields,
  columns,
  handleSubmit,
  handleCancel,
  showButtons = true,
  disabledWhen,
  ...rest
}) => {
  // if no columns passed, then map a single column of field names
  const resolvedColumns = columns || [fields.map(({ name }) => name)];

  return (
    <BaseForm
      {...rest}
      fields={fields}
      handleSubmit={handleSubmit}
      enableReinitialize
      renderForm={(formik) => {
        return (
          <>
            <Row>
              {resolvedColumns.map((col, idx) => (
                <Col key={idx} md={Math.ceil(12 / resolvedColumns.length)}>
                  {col.map((fieldName) => {
                    const {
                      id,
                      Field,
                      label,
                      tooltip,
                      componentName,
                      required
                    } = fields.find(({ name }) => name === fieldName);

                    const FieldComponent = Field || BaseField;

                    return (
                      <FieldComponent
                        key={fieldName}
                        id={id}
                        name={fieldName}
                        label={label}
                        tooltip={tooltip}
                        componentName={componentName}
                        required={required}
                        formik={formik}
                      />
                    );
                  })}
                </Col>
              ))}
            </Row>

            {showButtons && (
              <ButtonGroup>
                {handleCancel && (
                  <Button
                    color='secondary'
                    onClick={handleCancel}
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </Button>
                )}

                <Button
                  color='primary'
                  type='submit'
                  disabled={
                    formik.isSubmitting ||
                    (disabledWhen && disabledWhen(formik))
                  }
                >
                  Submit
                </Button>
              </ButtonGroup>
            )}
          </>
        );
      }}
    />
  );
};

FormGenerator.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired,
      type: PropTypes.string
    })
  ),
  columns: PropTypes.arrayOf(PropTypes.array),
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  showButtons: PropTypes.bool,
  disabledWhen: PropTypes.func
};

export default FormGenerator;
