import { getErrorToast, getErrorMessage, getWarningToast } from '@utils';
import {
  ADD_PO_ASAP_ITEM,
  SAVE_PO_ASAP_ITEMS,
  GET_PO_SUMMARY,
  UPDATE_PO_SUMMARY,
  BULK_ADD_ITEMS,
  SET_TOAST,
  BULK_UPDATE_ITEMS,
  ADD_SHIPMENT,
  UPDATE_SHIPMENT,
  TOGGLE_PAGE_ERROR,
  BULK_REMOVE_ITEMS,
  RESET_PO,
  TOGGLE_LOADING_ITEMS,
  GET_TRANSACTIONS,
  GET_ATTACHMENTS,
  GET_OPEN_PURCHASE_ORDERS,
  TOGGLE_LOADING_OPEN_PURCHASE_ORDERS,
  GET__CLOSED_PURCHASE_ORDERS,
  TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS
} from '../types';
import * as fixtures from './fixtures';

/* eslint-disable no-unused-vars */

export const uploadPOItemsCSV =
  (files, purchaseOrderId) =>
  async (dispatch, _, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: true
      });

      const [file] = files;
      const form = new FormData();
      form.append('upload', file, file.name);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const res = await api.post(
        `/purchase-orders/${purchaseOrderId}/items/csv`,
        form,
        config
      );

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    } finally {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: false
      });
    }
  };

export const getPOSummary =
  (purchaseOrderId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/purchase-orders/header/${purchaseOrderId}`);

      if (!res.data.id) {
        throw new Error(
          `Unable to find purchase order with id ${purchaseOrderId}`
        );
      }

      dispatch({
        type: GET_PO_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getPOItems =
  (purchaseOrderId) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: true
      });

      const res = await api.get(`/purchase-orders/items/${purchaseOrderId}`);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    } finally {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: false
      });
    }
  };

export const searchItemVendorSublist =
  (vendorId, query) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/inventory/sublistitem/search`, {
        vendorId,
        query
      });

      const { items, vendorHasSublistItems } = res.data;

      if (!res.data) {
        throw new Error();
      }

      if (!vendorHasSublistItems) {
        throw new Error('Vendor does not have sublist items loaded');
      }

      return items;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return [];
    }
  };

export const searchPOContainers =
  (searchTerm, purchaseOrderId) =>
  async (dispatch, getState, { api }) => {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(fixtures.containers);
      }, 150)
    );
  };

export const addPO =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/purchase-orders/`, formValues);

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const updatePOSummary =
  (purchaseOrderId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/purchase-orders/${purchaseOrderId}`,
        formValues
      );

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: UPDATE_PO_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addPOAsapItem =
  (id) =>
  async (dispatch, getState, { api }) => {
    dispatch({
      type: ADD_PO_ASAP_ITEM,
      payload: id
    });
  };

export const getVendorAsapItems =
  (vendorId) =>
  async (dispatch, getState, { api }) => {
    const res = await new Promise((resolve) =>
      setTimeout(() => {
        resolve(fixtures.ASAPproducts);
      }, 150)
    );

    dispatch({
      type: SAVE_PO_ASAP_ITEMS,
      payload: res.data
    });
  };

export const addShipmentAndLinkToItems =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const { vendorId } = getState().purchaseOrder.summary;
      const res = await api.post(`/shipment/purchase-order-items/`, {
        ...formValues,
        vendorId
      });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          items: res.data.items
        }
      });

      dispatch({
        type: ADD_SHIPMENT,
        payload: res.data.shipment
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const linkShipmentToItems =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/shipment/purchase-order-items/`, formValues);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeShipmentLink =
  ({ items }) =>
  async (dispatch, getState, { api }) => {
    try {
      if (items.every((item) => !item.shipmentId)) {
        throw new Error(
          'Cannot remove delivery from items because all selected items do not have a delivery'
        );
      }

      const res = await api.delete(`/shipment/purchase-order-items/`, {
        data: items
      });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const unloadShipment =
  (locationId, shipmentId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/shipment/${shipmentId}/unloadtoreceiving`, {
        locationId
      });

      dispatch({
        type: UPDATE_SHIPMENT,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const revertUnloadShipment =
  (shipmentId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(
        `/shipment/${shipmentId}/revertunloadtoreceiving`
      );

      dispatch({
        type: UPDATE_SHIPMENT,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateExpectedUnitPrice =
  (items, vendorSublistItems) =>
  async (dispatch, getState, { api }) => {
    try {
      const {
        purchaseOrder: {
          summary: { id: purchaseOrderId }
        }
      } = getState();

      const [itemsRes, ...sublistRes] = await Promise.all([
        api.put(`/purchase-orders/${purchaseOrderId}/items/`, { items }),
        ...vendorSublistItems.map((item) =>
          api.put(`/inventory/sublistitem/${item.id}`, item)
        )
      ]);

      if (!itemsRes.data) {
        throw new Error('Unable to update all items.');
      }

      if (sublistRes.filter(({ data }) => !data).length) {
        throw new Error('Unable to update all vendors.');
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          items: itemsRes.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deletePO =
  (purchaseOrderId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/purchase-orders/${purchaseOrderId}`);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const removePOItems =
  (purchaseOrderId, vendorSublistId, inventoryItemId, quantity) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(
        `/purchase-orders/${purchaseOrderId}/items`,
        {
          data: { vendorSublistId, inventoryItemId, quantity }
        }
      );

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          ids: res.data.items.map(({ id }) => id)
        }
      });

      if (!res.data.allItemsRemoved) {
        dispatch({
          type: SET_TOAST,
          payload: getWarningToast({
            body: 'Some items could not be removed due to having a serial number, item receipt or delivery associated to them'
          })
        });
      }
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deletePurchaseOrderItemByIds =
  (purchaseOrderItemIds) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id } = getState().purchaseOrder.summary;
      const res = await api.delete(`/purchase-orders/${id}/itemByIds`, {
        data: { purchaseOrderItemIds }
      });

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          ids: res.data.map((d) => d.id)
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removePurchaseOrderItem =
  (purchaseOrderItemId) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id } = getState().purchaseOrder.summary;
      const res = await api.delete(
        `/purchase-orders/${id}/items/${purchaseOrderItemId}`
      );

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName: 'purchaseOrder',
          ids: [res.data.id]
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const togglePOClosed =
  (purchaseOrderId, closed) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/purchase-orders/${purchaseOrderId}/toggle-closed`,
        { closed }
      );

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: UPDATE_PO_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetPO = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PO
    });

    dispatch({
      type: BULK_ADD_ITEMS,
      payload: {
        itemsName: 'purchaseOrder',
        items: []
      }
    });

    dispatch({
      type: GET_TRANSACTIONS,
      payload: []
    });

    dispatch({
      type: GET_ATTACHMENTS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};

export const getOpenPurchaseOrders =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_OPEN_PURCHASE_ORDERS,
        payload: true
      });
      const res = await api.get(`/purchase-orders/open`);

      dispatch({
        type: GET_OPEN_PURCHASE_ORDERS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: TOGGLE_LOADING_OPEN_PURCHASE_ORDERS,
        payload: false
      });
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getClosedPurchaseOrders =
  ({ startDate, endDate }) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS,
        payload: true
      });
      const res = await api.post(`/purchase-orders/closed`, {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString()
      });

      dispatch({
        type: GET__CLOSED_PURCHASE_ORDERS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: TOGGLE_LOADING__CLOSED_PURCHASE_ORDERS,
        payload: false
      });
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetOpenPurchaseOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_OPEN_PURCHASE_ORDERS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};

export const resetClosedPurchaseOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET__CLOSED_PURCHASE_ORDERS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
