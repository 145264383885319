import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';
import Cell from './Cell';
import { StyledInput } from '../StyledComponents';

const StyledHeader = styled(Cell)`
  min-width: 165px;
  font-weight: bold;
`;

const StyledIcon = styled.i`
  margin-left: auto;
  color: ${(props) => (props.$isSorted ? 'inherit' : vars.colorAlpineGray)};
`;

const StyledTitle = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 0.25rem;
`;

const Header = ({ column }) => {
  const {
    id,
    title,
    setFilter,
    canFilter,
    isSorted,
    isSortedDesc,
    getSortByToggleProps,
    filterValue,
    Filter = StyledInput
  } = column;

  const onChange = (e) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <StyledHeader>
      <div className='d-flex' {...getSortByToggleProps()}>
        <StyledTitle>{title || id}</StyledTitle>

        <StyledIcon
          $isSorted={isSorted}
          className={`fa ${isSortedDesc ? 'fa-caret-down' : 'fa-caret-up'}`}
        />
      </div>
      <Filter
        value={filterValue || ''}
        onChange={onChange}
        className='mt-2'
        disabled={!canFilter}
      />
    </StyledHeader>
  );
};

Header.propTypes = {
  column: PropTypes.object.isRequired
};

export default Header;
