import { format, isDate } from 'date-fns';

const formatDate = (date, formatStr = 'MM/dd/yyyy') => {
  if (!date) {
    return '';
  }

  const d = typeof date === 'string' ? new Date(date) : date;
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

  if (!date || !isDate(d)) {
    return '';
  }

  return d ? format(d, formatStr) : '';
};

export default formatDate;
