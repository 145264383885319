import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import vars from '@styles/_export.module.scss';
import WysiwygBase from './WysiwygBase';

const statuses = {
  draft: 'Draft',
  pending: 'Saving...',
  success: 'Saved',
  error: 'Error saving'
};

const Status = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${vars.colorAlpineGray};
  border: 1px solid ${vars.colorAlpineGray};
  border-bottom: 0;
  color: ${(props) => (props.error ? vars.colorAlpineRed : vars.colorWhite)};
  padding: 0.25rem 1rem;
`;

const WysiwygAutoSaver = ({
  initialValue = '',
  handleSave,
  delay = 1000,
  className = '',
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [status, setStatus] = useState(statuses.draft);
  const ranOnce = useRef(false);

  useEffect(() => {
    // Don't call save on the first render
    if (!ranOnce.current) {
      ranOnce.current = true;
      return undefined;
    }

    setStatus(statuses.draft);

    const handler = setTimeout(async () => {
      setStatus(statuses.pending);
      try {
        await handleSave(value);
        setStatus(statuses.success);
      } catch (e) {
        setStatus(statuses.error);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, handleSave]);

  return (
    <div className={className}>
      <Status error={status === statuses.error}>{status}</Status>
      <WysiwygBase {...rest} value={value} onChange={setValue} />
    </div>
  );
};

WysiwygAutoSaver.propTypes = {
  initialValue: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  delay: PropTypes.number,
  className: PropTypes.string
};

export default WysiwygAutoSaver;
