import styled from 'styled-components';

const StyledHeaderCell = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useRowOptions = (options, RowOptionsCell) => {
  if (!RowOptionsCell) {
    return { options };
  }

  const resolvedOptions = [
    ...options,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'row_options',
          Header: StyledHeaderCell,
          Cell: RowOptionsCell,
          Aggregated: RowOptionsCell
        }
      ]);
    }
  ];

  return { options: resolvedOptions };
};

export default useRowOptions;
