import React from 'react';
import PropTypes from 'prop-types';
import BaseForm from './BaseForm';
import BaseField from './BaseField';

const InlineTextInputForm = ({
  field,
  hiddenFields = [],
  handleSubmit,
  buttons,
  formRef,
  displayValidStatus = true,
  ...rest
}) => {
  return (
    <BaseForm
      {...rest}
      fields={[field, ...hiddenFields]}
      handleSubmit={handleSubmit}
      formRef={formRef}
      enableReinitialize
      renderForm={({ values, dirty, isValid, isSubmitting }) => (
        <BaseField
          {...field}
          value={values[field.name]}
          valid={
            displayValidStatus ? !!field.initialValue && !dirty : undefined
          }
          componentName='TextInputWithButtons'
          inputVariant={field.componentName}
          buttons={buttons.map((button) => {
            if (button.type === 'submit') {
              return {
                ...button,
                disabled: !isValid || isSubmitting
              };
            }

            return button;
          })}
        />
      )}
    />
  );
};

InlineTextInputForm.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    componentName: PropTypes.string,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    validator: PropTypes.object
  }).isRequired,
  hiddenFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      validator: PropTypes.object
    })
  ),
  handleSubmit: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  formRef: PropTypes.object,
  displayValidStatus: PropTypes.bool
};

export default InlineTextInputForm;
