import produce from 'immer';
import {
  ADD_PO_ASAP_ITEM,
  GET_PO_SUMMARY,
  SAVE_PO_ASAP_ITEMS,
  UPDATE_PO_SUMMARY,
  BULK_RECEIVE_PO_ITEMS,
  RESET_PO
} from '@actions/types';

export const initialState = {
  summary: {},
  locations: [],
  asapItems: [],
  statuses: [
    {
      id: 1,
      statusName: 'Pending Receipt'
    },
    {
      id: 2,
      statusName: 'Partially Received'
    },
    {
      id: 3,
      statusName: 'Partially Received / Pending Bill'
    },
    {
      id: 4,
      statusName: 'Pending Bill'
    },
    {
      id: 5,
      statusName: 'Fully Billed'
    },
    {
      id: 6,
      statusName: 'Closed'
    }
  ]
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_PO_SUMMARY: {
      draft.summary = action.payload;
      return draft;
    }

    case UPDATE_PO_SUMMARY: {
      draft.summary = {
        ...draft.summary,
        ...action.payload
      };

      return draft;
    }

    case BULK_RECEIVE_PO_ITEMS:
      return draft;

    case ADD_PO_ASAP_ITEM: {
      const item = draft.asapItems.find(({ id }) => id === action.payload);
      draft.items.push(item);
      return draft;
    }

    case SAVE_PO_ASAP_ITEMS:
      draft.asapItems = [];
      action.payload.forEach((item) => {
        const asapItem = {
          ...item,
          id: new Date().getTime() + item.vendorProductId
        };
        draft.asapItems.push(asapItem);
      });
      return draft;

    case RESET_PO:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
