import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import HeightTransitioner from './HeightTransitioner';

const FormModal = ({ isOpen, setIsOpen, children, description, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef({});

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await formRef.current.submitForm();
      if (formRef.current?.isValid) {
        setIsOpen(false);
      }
    } finally {
      setLoading(false);
    }
  }, [formRef, setIsOpen]);

  const handleKeydown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const buttons = useMemo(
    () => [
      {
        color: 'secondary',
        text: 'Cancel',
        onClick: () => setIsOpen(false),
        disabled: loading
      },
      {
        color: 'primary',
        text: 'Submit',
        onClick: handleSubmit,
        disabled: loading
      }
    ],
    [handleSubmit, setIsOpen, loading]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeydown);
    } else {
      document.removeEventListener('keydown', handleKeydown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen, handleKeydown]);

  return (
    <Modal {...rest} isOpen={isOpen} setIsOpen={setIsOpen} buttons={buttons}>
      <HeightTransitioner>
        {description && <p>{description}</p>}
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            ...child.props,
            formRef
          })
        )}
      </HeightTransitioner>
    </Modal>
  );
};

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  description: PropTypes.string
};

export default FormModal;
