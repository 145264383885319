import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';
import Cell from './Cell';
import { getResolvedCellValue } from './utils';

const StyledIcon = styled.i`
  margin-left: auto;
  color: ${vars.colorAlpineGray};
`;

const StyledCell = styled(Cell)`
  cursor: pointer;

  &:active {
    background: ${vars.colorLightBlue};
  }
`;

const ClickableCell = ({
  onClick,
  iconClassName = 'fa-pencil',
  ...tableInstance
}) => {
  const value = getResolvedCellValue(tableInstance);

  return (
    <StyledCell onClick={onClick} tabIndex={-1}>
      <div className='d-flex align-items-center'>
        <span className='pr-2'>{value}</span>
        <StyledIcon className={`fa ${iconClassName}`} />
      </div>
    </StyledCell>
  );
};

ClickableCell.propTypes = {
  cell: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  iconClassName: PropTypes.string
};

export default ClickableCell;
