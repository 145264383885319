import produce from 'immer';
import { startOfDay, endOfDay } from 'date-fns';
import { formatDate } from '@utils';
import {
  GET_GLOBAL_SETTINGS_REQUEST,
  GET_GLOBAL_SETTINGS_SUCCESS,
  GET_GLOBAL_SETTINGS_FAILURE,
  GET_SHIPPING_LOCATIONS,
  GET_POSTING_PERIODS,
  ADD_POSTING_PERIOD,
  UPDATE_POSTING_PERIOD,
  ADD_LOCATION_SECTIONS,
  ADD_LOCATION_SECTION,
  ADD_LOCATION_BIN,
  UPDATE_LOCATION_SECTION,
  UPDATE_LOCATION_BIN,
  REMOVE_LOCATION_SECTION,
  REMOVE_LOCATION_BIN,
  GET_VENDORS,
  GET_SHIPMENTS,
  GET_SCHEDULED_SHIPMENTS,
  UPDATE_SHIPMENT,
  ADD_SHIPMENT,
  REMOVE_SHIPMENT,
  GET_LOCATION_TRANSFERS,
  GET_ITEM_FULFILLMENTS,
  GET_SHIPMENT_NOTIFICATIONS,
  GET_WAREHOUSE_DOORS
} from '@actions/types';
import { UPDATE_SHIPMENT_STATUS } from '../../actions/types';

export const initialState = {
  settingsLoading: false,
  settingsError: '',
  settings: {
    showInsideDelivery: false,
    showGuaranteedDelivery: false,
    showGuaranteedDeliveryInternal: false
  },
  vendors: [],
  shippingLocations: [],
  postingPeriods: [],
  locationSections: [],
  locationBins: [],
  shipments: [],
  scheduledShipments: [],
  locationTransfers: [],
  warehouseDoors: [],
  itemFulfillments: [],
  shipmentNotifications: {
    in_door: 0,
    in_inspection: 0,
    in_transit: 0
  }
};

const resolvePostingPeriod = (period) => ({
  ...period,
  name: formatDate(period.startDate, 'MMMM yyyy'),
  startDate: startOfDay(new Date(period.startDate)),
  endDate: endOfDay(new Date(period.endDate))
});

export default produce((draft, action) => {
  switch (action.type) {
    case GET_GLOBAL_SETTINGS_REQUEST:
      return {
        ...draft,
        settingsLoading: true
      };

    case GET_GLOBAL_SETTINGS_SUCCESS:
      return {
        ...draft,
        settings: { ...action.payload },
        settingsLoading: false
      };

    case GET_GLOBAL_SETTINGS_FAILURE:
      return {
        ...draft,
        settingsError: action.payload,
        settingsLoading: false
      };

    case GET_SHIPPING_LOCATIONS:
      draft.shippingLocations = action.payload;
      return draft;

    case GET_POSTING_PERIODS: {
      const postingPeriods = action.payload.map(resolvePostingPeriod);
      draft.postingPeriods = postingPeriods;
      return draft;
    }

    case ADD_POSTING_PERIOD: {
      draft.postingPeriods = [
        ...draft.postingPeriods,
        resolvePostingPeriod(action.payload)
      ];
      return draft;
    }

    case UPDATE_POSTING_PERIOD: {
      const idx = draft.postingPeriods.findIndex(
        ({ id }) => id === action.payload.id
      );
      draft.postingPeriods[idx] = resolvePostingPeriod(action.payload);
      return draft;
    }

    case ADD_LOCATION_SECTIONS: {
      const { bins, binSections: sections, id: warehouseId } = action.payload;
      draft.locationSections = sections.map((section) => ({
        ...section,
        warehouseId
      }));
      draft.locationBins = bins.map((bin) => ({
        ...bin,
        sectionId: bin.binSectionId,
        warehouseId
      }));
      return draft;
    }

    case ADD_LOCATION_SECTION: {
      draft.locationSections = [...draft.locationSections, action.payload];
      return draft;
    }

    case UPDATE_LOCATION_SECTION: {
      const idx = draft.locationSections.findIndex(
        ({ id }) => id === action.payload.id
      );
      draft.locationSections[idx] = {
        ...draft.locationSections[idx],
        ...action.payload
      };
      return draft;
    }

    case REMOVE_LOCATION_SECTION: {
      draft.locationSections = draft.locationSections.filter(
        ({ id }) => id !== action.payload
      );
      return draft;
    }

    case ADD_LOCATION_BIN: {
      const { warehouseId, bin } = action.payload;
      draft.locationBins = [
        ...draft.locationBins,
        { warehouseId, ...bin, sectionId: bin.binSectionId }
      ];
      return draft;
    }

    case UPDATE_LOCATION_BIN: {
      const bin = action.payload;
      const idx = draft.locationBins.findIndex(({ id }) => id === bin.id);
      draft.locationBins[idx] = {
        ...draft.locationBins[idx],
        ...{
          ...bin,
          sectionId: bin.binSectionId
        }
      };
      return draft;
    }

    case REMOVE_LOCATION_BIN: {
      draft.locationBins = draft.locationBins.filter(
        ({ id }) => id !== action.payload
      );
      return draft;
    }

    case GET_VENDORS:
      draft.vendors = action.payload;
      return draft;

    case GET_LOCATION_TRANSFERS:
      draft.locationTransfers = action.payload;
      return draft;

    case GET_WAREHOUSE_DOORS:
      draft.warehouseDoors = action.payload;
      return draft;

    case GET_ITEM_FULFILLMENTS:
      draft.itemFulfillments = action.payload;
      return draft;

    case GET_SHIPMENTS: {
      draft.shipments = action.payload;
      return draft;
    }

    case GET_SCHEDULED_SHIPMENTS: {
      draft.scheduledShipments = action.payload;
      return draft;
    }

    case ADD_SHIPMENT: {
      draft.shipments = [...draft.shipments, action.payload];
      return draft;
    }
    case UPDATE_SHIPMENT: {
      const idx = draft.shipments.findIndex(
        ({ id }) => id === action.payload.id
      );
      draft.shipments[idx] = {
        ...draft.shipments[idx],
        ...action.payload
      };
      return draft;
    }
    case UPDATE_SHIPMENT_STATUS: {
      const idx = draft.shipments.findIndex(
        ({ id }) => id === action.payload.shipmentId
      );
      draft.shipments[idx].status = action.payload.status;
      draft.shipments[idx].statusCode = action.payload.statusCode;
      return draft;
    }
    case REMOVE_SHIPMENT: {
      draft.shipments = draft.shipments.filter(
        ({ id }) => id !== action.payload.id
      );
      return draft;
    }

    case GET_SHIPMENT_NOTIFICATIONS: {
      draft.shipmentNotifications = action.payload;
      return draft;
    }

    default:
      return draft;
  }
}, initialState);

export const selectShipmentNotifications = (state) =>
  state.globals.shipmentNotifications;
