import { useState, useCallback } from 'react';

const useItemsState = () => {
  const [isAddingItems, setAddingItems] = useState(false);
  const [editingItem, setEditingItem] = useState({ name: null, item: null });
  const [bulkAction, setBulkAction] = useState(null);

  const onAddItems = useCallback(() => setAddingItems(false), [setAddingItems]);

  const onEditItem = useCallback(
    () => setEditingItem({ name: null, item: null }),
    [setEditingItem]
  );

  const onBulkAction = useCallback(() => setBulkAction(null), [setBulkAction]);

  return {
    isAddingItems,
    setAddingItems,
    editingItem,
    setEditingItem,
    bulkAction,
    setBulkAction,
    onAddItems,
    onEditItem,
    onBulkAction
  };
};

export default useItemsState;
