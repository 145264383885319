import React from 'react';
import PropTypes from 'prop-types';
import { StyledCheckbox } from '../StyledComponents';

const Checkbox = ({ value, ...rest }) => {
  return (
    <StyledCheckbox {...rest} type='checkbox' value={value} checked={value} />
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool
};

export default Checkbox;
