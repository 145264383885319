import produce from 'immer';
import {
  GET_ITEM_RECEIPT_SUMMARY,
  UPDATE_ITEM_RECEIPT_SUMMARY,
  RESET_ITEM_RECEIPT,
  UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY
} from '@actions/types';

export const initialState = {
  summary: {
    status: '',
    date: '',
    warehouseId: null,
    postingPeriod: null,
    isBilled: false
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_ITEM_RECEIPT_SUMMARY:
    case UPDATE_ITEM_RECEIPT_SUMMARY: {
      draft.summary = action.payload;
      return draft;
    }
    case UPDATE_ITEM_RECEIPT_SUMMARY_PROPERTY: {
      draft.summary[action.payload.key] = action.payload.value;
      return draft;
    }

    case RESET_ITEM_RECEIPT:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
