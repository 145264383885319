import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';

const Breadcrumb = styled.div`
  color: ${vars.colorWhite};
  margin-right: 0.5rem;

  &:after {
    content: '|';
    margin-left: 0.5rem;
  }

  &:last-child {
    margin-right: 0;

    &:after {
      content: '';
      margin-left: 0;
    }
  }
`;

export const Breadcrumbs = ({ items }) => {
  return (
    <div className='d-flex align-items-center'>
      <Breadcrumb>
        <Link to='/'>
          <span className='fa fa-home'></span>
        </Link>
      </Breadcrumb>

      {items.map((item, idx) => {
        if (item.to) {
          return (
            <Breadcrumb key={idx}>
              {item.label && <span className='mr-1'>{item.label}:</span>}
              <Link to={item.to} target={item.target} rel='noopener noreferrer'>
                {item.text}
              </Link>
            </Breadcrumb>
          );
        }

        return (
          <Breadcrumb key={idx}>
            {item.label && <span className='mr-1'>{item.label}:</span>}
            <span>{item.text}</span>
          </Breadcrumb>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
  )
};

export default Breadcrumbs;
