import produce from 'immer';
import { GET_TRANSACTIONS, TOGGLE_LOADING_TRANSACTIONS } from '@actions/types';

export const initialState = {
  data: [],
  loading: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      draft.data = action.payload;
      return draft;

    case TOGGLE_LOADING_TRANSACTIONS:
      draft.loading = action.payload;
      return draft;

    default:
      return draft;
  }
}, initialState);
