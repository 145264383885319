import React from 'react';
import TooltipCell from './TooltipCell';

const BulkEditTooltipCell = (props) => {
  return (
    <TooltipCell
      {...props}
      tooltip='Inline editing not available. Select row(s) and use bulk edit to update this cell.'
    />
  );
};

export default BulkEditTooltipCell;
