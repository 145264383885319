import produce from 'immer';
import { GET_SUBLINE_ITEMS, SET_LOADING_SUBLINE_ITEMS } from '@actions/types';

export const initialState = {
  sublineItems: [],
  loading: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_SUBLINE_ITEMS: {
      draft.sublineItems = action.payload;
      draft.loading = false;
      return draft;
    }

    case SET_LOADING_SUBLINE_ITEMS: {
      draft.loading = action.payload;
      return draft;
    }

    default:
      return draft;
  }
}, initialState);
