import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Spinner } from 'reactstrap';
import vars from '@styles/_export.module.scss';

const StyledButtonDark = styled(Button)`
  font-weight: bold;
  padding: 0;

  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
  &:active {
    opacity: 0.5;
  }

  i {
    margin-right: 0.25rem;
  }
`;

const StyledButtonLight = styled(StyledButtonDark)`
  color: ${vars.colorWhite};
  &:hover {
    color: ${vars.colorWhite};
  }
  &:disabled {
    color: ${vars.colorWhite};
    opacity: 0.25;
    text-decoration: none;
  }
`;

const ActionLink = ({
  text,
  iconClassName,
  onClick,
  onDarkBg = false,
  className,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false);

  if (!text || !onClick) {
    return null;
  }

  const ButtonComponent = onDarkBg ? StyledButtonLight : StyledButtonDark;

  const handleOnClick = async () => {
    setLoading(true);
    try {
      await onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonComponent
      color='link'
      className={className}
      onClick={handleOnClick}
      {...buttonProps}
    >
      {loading && <Spinner size='sm' className='mr-1' />}
      {iconClassName && !loading && <i className={`fa ${iconClassName}`} />}
      <span>{text}</span>
    </ButtonComponent>
  );
};

ActionLink.propTypes = {
  text: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  onDarkBg: PropTypes.bool
};

export default ActionLink;
