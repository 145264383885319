import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

const DateInput = ({ value, min, max, ...props }) => {
  return (
    <TextInput
      {...props}
      value={value}
      type='time'
      min={min}
      max={max}
      placeholder='yyyy-mm-dd'
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default DateInput;
