import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_BILL_SUMMARY,
  UPDATE_BILL_SUMMARY,
  GET_BILL_EXPENSES,
  ADD_BILL_EXPENSE,
  UPDATE_BILL_EXPENSE,
  REMOVE_BILL_EXPENSE,
  BULK_ADD_ITEMS,
  BULK_REMOVE_ITEMS,
  ALLOCATE_BILL_EXPENSES,
  SET_TOAST,
  BULK_UPDATE_ITEMS,
  TOGGLE_PAGE_ERROR,
  RESET_BILL,
  TOGGLE_LOADING_ITEMS,
  GET_TRANSACTIONS,
  GET_GL_ENTRIES,
  GET_ATTACHMENTS,
  ADD_GL_ENTRY
} from '../types';

// Temp allowing unused vars until server endpoints set up
// so we can see which args should be passed
/* eslint-disable no-unused-vars */

export const addBill =
  (req) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/bills`, req);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const getBillSummary =
  (billId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/bills/header/${billId}`);

      if (!res.data) {
        throw new Error(`Item Receipt not found with id ${billId}`);
      }

      dispatch({
        type: GET_BILL_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getUnbilledItems =
  (req) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/bills/unbilled`, req);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return [];
    }
  };

export const getImporterBillDeliveries =
  (vendorId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/bills/importer/deliveries/${vendorId}`);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return [];
    }
  };

export const addDeliveriesToImporterBill =
  (data) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/bills/importer/deliveries`, data);
      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'importerBill',
          items: res.data
        }
      });
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return [];
    }
  };

export const getBillItems =
  (billId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/bills/items/${billId}`);

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'bill',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeImporterBillDeliveries =
  (ids, billId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/bills/importer/deliveries`, {
        data: { ids, billId }
      });

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName: 'importerBill',
          ids: res.data.map(({ id }) => id),
          idKey: res.data.idKey ?? 'id'
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getAssignedImporterBillDeliveries =
  (billId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/bills/deliveries/${billId}`);

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'importerBill',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const searchBillItems =
  (req) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/bills/unbilled`, req);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return [];
    }
  };

export const addBillItems =
  (req) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/bills/items/${req.billId}`, req);

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: {
          status: res.data.status,
          amount: res.data.amount,
          invoiceAmount: res.data.invoiceAmount
        }
      });

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'bill',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateBillItems =
  (billId, items) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.put(`/bills/items/${billId}`, {
        items
      });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: {
          status: res.data.status,
          amount: res.data.amount,
          invoiceAmount: res.data.invoiceAmount
        }
      });

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'bill',
          items: res.data.items
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const removeBillItems =
  (billId, items) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: true
      });

      const res = await api.delete(`/bills/items/${billId}`, {
        data: { items }
      });

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: {
          status: res.data.status,
          amount: res.data.amount,
          invoiceAmount: res.data.invoiceAmount
        }
      });

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'bill',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    } finally {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: false
      });
    }
  };

export const updateBillSummary =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.put(
        `/bills/header/${formValues.billId}`,
        formValues
      );

      if (!res.data) {
        throw new Error(`Bill not found with id ${formValues.billId}`);
      }

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getBillExpenses =
  (billId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/expenses/bill/${billId}`);

      if (!res.data) {
        throw new Error(`Bill not found with id ${billId}`);
      }

      dispatch({
        type: GET_BILL_EXPENSES,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addBillExpense =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/expenses`, formValues);

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: { status: res.data.billStatus }
      });

      dispatch({
        type: ADD_BILL_EXPENSE,
        payload: res.data.expenses
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateBillExpense =
  (expenseId, formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.put(`/expenses/${expenseId}`, formValues);

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: { status: res.data.billStatus }
      });

      dispatch({
        type: UPDATE_BILL_EXPENSE,
        payload: res.data.expense
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeBillExpense =
  (expenseId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/expenses/${expenseId}`);

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: { status: res.data.billStatus }
      });

      dispatch({
        type: REMOVE_BILL_EXPENSE,
        payload: res.data.expense.id
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const allocateBillExpenses =
  (billId, allocated) =>
  async (dispatch, _getState, { api }) => {
    try {
      let res;

      if (allocated) {
        res = await api.delete(`/expenses/allocate/${billId}`);
      } else {
        res = await api.put(`/expenses/allocate/${billId}`);
      }

      dispatch({
        type: ALLOCATE_BILL_EXPENSES,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deleteBill =
  (billId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/bills/${billId}`);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const createBillPayment =
  (payment) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/payments`, payment);

      dispatch({
        type: ADD_GL_ENTRY,
        payload: res.data
      });

      dispatch({
        type: UPDATE_BILL_SUMMARY,
        payload: {
          status: res.data.billStatus,
          paymentsTotalAmount: res.data.paymentsTotal
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const resetBill = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_BILL
    });

    dispatch({
      type: BULK_ADD_ITEMS,
      payload: {
        itemsName: 'itemReceipt',
        items: []
      }
    });

    dispatch({
      type: GET_TRANSACTIONS,
      payload: []
    });

    dispatch({
      type: GET_GL_ENTRIES,
      payload: []
    });

    dispatch({
      type: GET_ATTACHMENTS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
