import { useState, useMemo, useEffect } from 'react';
import SelectRowCell from '../SelectRowCell';
import SelectAllRowsCell from '../SelectAllRowsCell';

/* eslint-disable react/display-name, react/prop-types */

const useRowSelection = (config, options, selectable) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [indeterminateGroups, setIndeterminateGroups] = useState([]);

  const { onRowSelection, ...restConfig } = config;

  useEffect(() => {
    if (selectable && onRowSelection) {
      onRowSelection(selectedRows);
    }
  }, [selectable, onRowSelection, selectedRows]);

  if (!selectable) {
    return { config: restConfig, options };
  }

  const resolvedConfig = {
    ...restConfig,
    setSelectedRows,
    setIndeterminateGroups,
    useControlledState: (state) =>
      // An incorrect warning fires in the console that these useMemo dependencies
      // are not needed, but in fact they are.
      /* eslint-disable react-hooks/exhaustive-deps */
      useMemo(
        () => ({
          ...state,
          selectedRows,
          indeterminateGroups
        }),
        [state, selectedRows, indeterminateGroups]
      )
  };

  const resolvedOptions = [
    ...options,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'row_selection',
          Header: SelectAllRowsCell,
          Cell: SelectRowCell,
          Aggregated: SelectRowCell
        },
        ...columns
      ]);
    }
  ];

  return { config: resolvedConfig, options: resolvedOptions };
};

export default useRowSelection;
