import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@utils';
import TableCell from './Cell';

const DateCell = ({ cell: { value } }) => {
  return (
    <TableCell>
      <span>{formatDate(value)}</span>
    </TableCell>
  );
};

DateCell.propTypes = {
  cell: PropTypes.object
};

export default DateCell;
