import styled, { css } from 'styled-components';
import vars from '@styles/_export.module.scss';
import { Input, Navbar, InputGroup } from 'reactstrap';

export const StyledNavbar = styled(Navbar)`
  position: sticky;
  top: 0;
  z-index: 9;
`;

export const CollapseIcon = styled.i.attrs((props) => ({
  className: `fa fa-chevron-right ${props.className}`
}))`
  margin: 0 0.25rem;
  font-size: 1rem;
  width: 1rem;
  transform: rotate(${(props) => (props.isOpen ? '90deg' : '0deg')});
  transition: transform 0.3s ease-in-out;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const styledInputFlushMixin = css`
  padding: 0;
  margin: 0;
  height: 1.3rem;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
    background: transparent;
  }
`;

const styledInputDisableReadonlyMixin = css`
  &[readonly] {
    background-color: transparent;
  }
`;

export const StyledInput = styled(Input)`
  border-radius: ${(props) => (props.$flush ? '0px' : '5px')};
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  ${(props) => props.$flush && styledInputFlushMixin}
  ${(props) => props.$disableReadOnlyStyle && styledInputDisableReadonlyMixin}
`;

export const StyledSelect = styled(StyledInput)`
  &.is-invalid,
  &:invalid {
    background-position: right calc(0.375em + 0.75rem) center;
  }
`;

export const InfoBlock = styled.div`
  margin-bottom: 0.75rem;
  span {
    display: ${(props) => (props.$stacked ? 'block' : 'inline')};
  }
  span:first-child,
  label {
    font-weight: bold;
    margin-right: 0.25rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const styledCheckboxCenteredMixin = css`
  position: static;
  display: block;
  margin: 0 auto;
  padding: 0;
`;

export const StyledCheckbox = styled(Input)`
  left: 0;
  margin-left: 0;

  ${(props) => props.$centered && styledCheckboxCenteredMixin}
`;

export const StyledTableRow = styled.tr.attrs(
  ({ isThead, isTbody, isSelected, $isExpanded, ...rest }) => {
    const expandedBgColor = vars.colorLightBlue;
    let oddBgColor = expandedBgColor;
    let evenBgColor = expandedBgColor;
    if (!$isExpanded) {
      oddBgColor = isThead ? vars.colorWhite : vars.colorAlpineGrayLight;
      evenBgColor = isThead ? vars.colorAlpineGrayLight : vars.colorWhite;
    }

    if (isSelected) {
      oddBgColor = vars.colorAlpineGrayMedium;
      evenBgColor = vars.colorAlpineGrayMedium;
    }

    return {
      oddBgColor,
      evenBgColor,
      borderTop: $isExpanded
        ? `1px double ${vars.colorLighterBlue}`
        : undefined,
      borderBottom: $isExpanded
        ? `1px solid ${vars.colorLighterBlue}`
        : undefined,
      ...rest
    };
  }
)`
  td,
  th {
    border-top: ${(props) => props.borderTop};
    border-bottom: ${(props) => props.borderBottom};
  }

  &:nth-of-type(even) {
    td,
    th {
      background-color: ${(props) => props.evenBgColor};
    }
  }

  &:nth-of-type(odd) {
    td,
    th {
      background-color: ${(props) => props.oddBgColor};
    }
  }
`;

export const StyledTableCell = styled.td.attrs((props) => ({
  ...props,
  $borderTop: props.$editingCell
    ? `1px double ${vars.colorAlpineGreenDark}`
    : `1px solid ${vars.colorMediumGray}`,
  $borderBottom: props.$editingCell
    ? `1px solid ${vars.colorAlpineGreenDark}`
    : `1px solid ${vars.colorMediumGray}`,
  $borderLeft: props.$editingCell
    ? `1px double ${vars.colorAlpineGreenDark}`
    : `1px solid ${vars.colorMediumGray}`,
  $borderRight: props.$editingCell
    ? `1px solid ${vars.colorAlpineGreenDark}`
    : `1px solid ${vars.colorMediumGray}`,
  $backgroundColor: props.$editingCell
    ? `${vars.colorWhite} !important`
    : undefined
}))`
  border-top: ${(props) => props.$borderTop};
  border-bottom: ${(props) => props.$borderBottom};
  border-left: ${(props) => props.$borderLeft};
  border-right: ${(props) => props.$borderRight};
  background-color: ${(props) => props.$backgroundColor};

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: 0;
  }
`;

export const StyledTableCellHeader = styled.th`
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  border: 1px solid ${vars.colorMediumGray};
  border-top: 0;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    left: 0;
    right: 0;
    bottom: -1px;
    background-color: ${vars.colorMediumGray};
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
`;

export const StickyTableCell = styled(StyledTableCell)`
  position: sticky;
  left: ${(props) => (props.$stickyRight ? 'auto' : props.$stickyOffset)};
  right: ${(props) => (props.$stickyRight ? props.$stickyOffset : 'auto')};
  z-index: 1;
  min-width: 50px;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    top: 0;
    right: ${(props) => (props.$stickyRight ? 'auto' : '-1px')};
    left: ${(props) => (props.$stickyRight ? '-1px' : 'auto')};
    bottom: 0;
    background-color: ${vars.colorMediumGray};
  }
`;

export const StickyTableCellHeader = styled(StyledTableCellHeader)`
  position: sticky;
  top: 0;
  left: ${(props) => (props.$stickyRight ? 'auto' : props.$stickyOffset)};
  right: ${(props) => (props.$stickyRight ? props.$stickyOffset : 'auto')};
  z-index: ${(props) => (props.$stickyRight ? 1 : 2)};
  min-width: 50px;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    top: 0;
    right: ${(props) => (props.$stickyRight ? 'auto' : '-1px')};
    left: ${(props) => (props.$stickyRight ? '-1px' : 'auto')};
    bottom: 0;
    background-color: ${vars.colorMediumGray};
  }
`;

export const PrintLayout = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 800px;

  @media print {
    clear: both;
    background-color: ${vars.colorAlpineGrayLight};
    page-break-after: always;

    th {
      position: static;
      border: 1px solid ${vars.colorGray} !important;
    }

    td {
      position: static;
      border: 1px solid ${vars.colorGray} !important;
    }
  }
`;

export const TableHeading = styled.h2`
  font-weight: bold;
  font-size: ${vars.headingSize5};
`;

export const TableOptionsBarWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const PopoverScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9997;
`;

export const StyledSearchResult = styled.div`
  display: flex;
  padding: 0.5rem;

  span {
    flex: 1;
    text-align: center;
    padding: 0 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledSearchHeader = styled(StyledSearchResult)`
  background-color: ${vars.colorAlpineGrayLight};

  span {
    font-weight: bold;
    font-size: ${vars.fontSizeSmall};
  }
`;

export const SummaryTotal = styled(InputGroup)`
  margin-top: auto;
  margin-bottom: 1rem;

  .input-group-text {
    font-weight: bold;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;
