import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  Modal as BootstrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

const StyledButton = styled(Button)`
  margin-left: 0.75rem;

  &::last-child {
    margin-left: 0;
  }
`;

const StyledH5 = styled.h5`
  line-height: 1.5;
  margin-bottom: 0;
`;

const Message = styled.div`
  margin-top: 0.5rem;
`;

const Modal = ({
  heading,
  isOpen,
  setIsOpen,
  buttons,
  className,
  children,
  message,
  ...rest
}) => {
  const toggle = () => setIsOpen((cur) => !cur);

  return (
    <BootstrapModal
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      {...rest}
    >
      <ModalHeader toggle={toggle} tag='div'>
        <StyledH5>{heading}</StyledH5>
        {message && message.length && <Message>{message}</Message>}
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      {buttons?.length && (
        <ModalFooter>
          {buttons.map(({ text, color = 'primary', onClick, disabled }) => (
            <StyledButton
              key={text}
              color={color}
              onClick={onClick}
              disabled={disabled}
            >
              {text}
            </StyledButton>
          ))}
        </ModalFooter>
      )}
    </BootstrapModal>
  );
};

Modal.propTypes = {
  heading: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node
};

export default Modal;
