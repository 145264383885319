const openGmailComposeWindow = (params) => {
  const to = encodeURIComponent(params.to);
  const subject = encodeURIComponent(params.subject);
  const body = encodeURIComponent(params.body);

  window.open(
    `https://mail.google.com/mail/?ui=2&view=cm&fs=1&tf=1&shva=1&to=${to}&su=${subject}&body=${body}`,
    'Compose%20Gmail',
    'status=no,directories=no,location=no,resizable=no,menubar=no,width=600,height=600,toolbar=no'
  );
};

export default openGmailComposeWindow;
