import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  bulkAddItems,
  bulkUpdateItems,
  bulkRemoveItems,
  removeItemReceiptItems,
  updateItemReceiptItems,
  deletePurchaseOrderItemByIds,
  updateBillItems,
  removeBillItems,
  removePOItems
} from '@actions';
import useConfirm from './useConfirm';

const useItemsActions = (itemsName, itemsTableRef, documentId) => {
  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();

  const actions = useMemo(() => {
    const baseActions = {
      bulkAddItems: (...args) => dispatch(bulkAddItems(itemsName)(...args)),
      bulkUpdateItems: (...args) =>
        dispatch(bulkUpdateItems(itemsName)(...args)),
      bulkRemoveItems: (...args) =>
        dispatch(bulkRemoveItems(itemsName)(...args))
    };

    const map = {
      purchaseOrder: {
        ...baseActions,
        bulkRemoveItems: async (items) => {
          const { vendorSublistId, inventoryItemId } = items[0];

          const quantity =
            itemsTableRef.current.data.filter(
              (item) =>
                item.vendorSublistId === vendorSublistId &&
                item.inventoryItemId === inventoryItemId
            ).length - items.length;

          await dispatch(
            removePOItems(
              documentId,
              vendorSublistId,
              inventoryItemId,
              quantity
            )
          );
        }
      },
      itemReceipt: {
        ...baseActions,
        bulkUpdateItems: ({ items }) => {
          const resolvedItems = items.map((item) => ({
            sublineItemId: item.id,
            ...item
          }));
          return dispatch(updateItemReceiptItems(documentId, resolvedItems));
        },
        bulkRemoveItems: (items) =>
          dispatch(
            removeItemReceiptItems(
              documentId,
              items.map(({ id }) => id)
            )
          )
      },
      bill: {
        ...baseActions,
        bulkUpdateItems: ({ items }) => {
          const resolvedItems = items.map((item) => ({
            sublineItemId: item.id,
            ...item
          }));
          return dispatch(updateBillItems(documentId, resolvedItems));
        },
        bulkRemoveItems: (items) => dispatch(removeBillItems(documentId, items))
      },
      importerBill: {
        ...baseActions,
        bulkUpdateItems: ({ items }) => {
          const resolvedItems = items.map((item) => ({
            sublineItemId: item.id,
            ...item
          }));
          return dispatch(updateBillItems(documentId, resolvedItems));
        },
        bulkRemoveItems: (items) => dispatch(removeBillItems(documentId, items))
      },
      locationTransfer: {
        ...baseActions
      },
      itemFulfillment: {
        ...baseActions
      },
      delivery: {
        ...baseActions
      },
      purchaseOrderItems: {
        ...baseActions
      }
    };

    return map[itemsName];
  }, [dispatch, itemsName, documentId, itemsTableRef]);

  const handleRemoveItems = useCallback(
    async (ids, needsConfirmation = true) => {
      const confirmed = needsConfirmation
        ? await isConfirmed('Are you sure you want to remove these items?')
        : true;

      if (!confirmed) {
        return;
      }

      const { setSelectedRows, data } = itemsTableRef.current;

      await actions.bulkRemoveItems(
        ids.map((id) => {
          const { sublineItemId, vendorSublistId, inventoryItemId } = data.find(
            ({ id: dataId }) => dataId === id
          );
          return { id, sublineItemId, vendorSublistId, inventoryItemId };
        })
      );
      setSelectedRows((cur) => {
        return cur.filter((id) => !ids.includes(id));
      });
    },

    [actions, itemsTableRef, isConfirmed]
  );

  const bulkDeletePurchaseOrderItemByIds = useCallback(
    async (ids, needsConfirmation = true) => {
      const confirmed = needsConfirmation
        ? await isConfirmed('Are you sure you want to remove these items?')
        : true;

      if (!confirmed) {
        return;
      }
      const { setSelectedRows } = itemsTableRef.current;
      await dispatch(deletePurchaseOrderItemByIds(ids));
      setSelectedRows((cur) => {
        return cur.filter((id) => !ids.includes(id));
      });
    },

    [dispatch, itemsTableRef, isConfirmed]
  );

  const submitAddItemQuantity = useCallback(
    async (...args) => actions.bulkAddItems(...args),
    [actions]
  );

  const submitRemoveItemQuantity = useCallback(
    async (ids) => handleRemoveItems(ids, false),
    [handleRemoveItems]
  );

  const submitBulkRemoveItems = useCallback(async () => {
    const {
      state: { selectedRows }
    } = itemsTableRef.current;

    await handleRemoveItems(selectedRows);
  }, [handleRemoveItems, itemsTableRef]);

  const submitDeletePurchaseOrderItemByIds = useCallback(async () => {
    const {
      state: { selectedRows }
    } = itemsTableRef.current;
    await bulkDeletePurchaseOrderItemByIds(selectedRows);
  }, [bulkDeletePurchaseOrderItemByIds, itemsTableRef]);

  const submitRemoveRowItems = useCallback(
    async (row) => {
      const ids = (row.isGrouped ? row.subRows : [row]).map(
        ({ values }) => values.id
      );

      await handleRemoveItems(ids);
    },
    [handleRemoveItems]
  );

  const submitUpdateItem = useCallback(
    ({ item, values }) =>
      actions.bulkUpdateItems({
        items: [{ ...item, ...values }]
      }),
    [actions]
  );

  const submitBulkUpdateItems = useCallback(
    async (values) => {
      const {
        state: { selectedRows },
        data
      } = itemsTableRef.current;

      // Remove empty fields from update request since user may
      // only be updating some values
      const resolvedValues = Object.entries(values).reduce(
        (acc, [key, val]) => {
          if (val) {
            return {
              ...acc,
              [key]: val
            };
          }

          return acc;
        },
        {}
      );

      const items = data
        .filter(({ id }) => selectedRows.includes(id))
        .map((item) => ({ ...item, ...resolvedValues }));

      await actions.bulkUpdateItems({ items });
    },
    [actions, itemsTableRef]
  );

  return {
    ...actions,
    submitAddItemQuantity,
    submitRemoveItemQuantity,
    submitBulkRemoveItems,
    submitRemoveRowItems,
    submitBulkUpdateItems,
    submitUpdateItem,
    submitDeletePurchaseOrderItemByIds
  };
};

export default useItemsActions;
