import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_TRANSACTIONS,
  SET_TOAST,
  TOGGLE_LOADING_TRANSACTIONS
} from '../types';

/* eslint-disable no-unused-vars */

export const getTransactions =
  ({ type, documentId }) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_TRANSACTIONS,
        payload: true
      });

      const res = await api.get(
        `/transactions/record-type/${type}/record-id/${documentId}`
      );

      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    } finally {
      dispatch({
        type: TOGGLE_LOADING_TRANSACTIONS,
        payload: false
      });
    }
  };

export const searchTransactionsByDate =
  ({ startDate, endDate, types }) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/transactions/`, {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(
          new Date(endDate).setHours(24, 0, 0, 0)
        ).toISOString(),
        types
      });

      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetTransactions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTIONS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
