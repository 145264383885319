import produce from 'immer';
import {
  GET_UNALLOCATED_EXPENSES,
  TOGGLE_LOADING_UNALLOCATED_EXPENSES,
  GET_ALLOCATED_EXPENSES,
  TOGGLE_LOADING_ALLOCATED_EXPENSES
} from '@actions/types';

export const initialState = {
  allocated: { data: [], loading: false },
  unallocated: {
    data: [],
    loading: false
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_UNALLOCATED_EXPENSES:
      draft.unallocated.data = action.payload;
      draft.unallocated.loading = false;
      return draft;

    case TOGGLE_LOADING_UNALLOCATED_EXPENSES:
      draft.unallocated.loading = action.payload;
      return draft;

    case GET_ALLOCATED_EXPENSES:
      draft.allocated.data = action.payload;
      draft.allocated.loading = false;
      return draft;

    case TOGGLE_LOADING_ALLOCATED_EXPENSES:
      draft.allocated.loading = action.payload;
      return draft;

    default:
      return draft;
  }
}, initialState);
