import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_INVENTORY_ADJUSTMENTS,
  GET_INVENTORY_ADJUSTMENT_SUPPORTING_DATA,
  GET_INVENTORY_ADJUSTMENT_SUMMARY,
  UPDATE_INVENTORY_ADJUSTMENT,
  DELETE_INVENTORY_ADJUSTMENT,
  GET_INVENTORY_ADJUSTMENT_ITEMS,
  ADD_INVENTORY_ADJUSTMENT_ITEMS,
  SET_TOAST,
  TOGGLE_PAGE_ERROR
} from '../types';

export const getInventoryAdjustmentsByDate =
  ({ startDate, endDate }) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.post(`/date`, {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString()
      });

      dispatch({
        type: GET_INVENTORY_ADJUSTMENTS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getInventoryAdjustmentSupportingData =
  () =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.get(`/new`);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: GET_INVENTORY_ADJUSTMENT_SUPPORTING_DATA,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getInventoryAdjustmentSummary =
  (inventoryAdjustmentId) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.get(`/${inventoryAdjustmentId}`);

      dispatch({
        type: GET_INVENTORY_ADJUSTMENT_SUMMARY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const addInventoryAdjustment =
  (req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.post(`/`, req);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateInventoryAdjustment =
  (inventoryAdjustmentId, req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.put(
        `/${inventoryAdjustmentId}`,
        req
      );

      dispatch({
        type: UPDATE_INVENTORY_ADJUSTMENT,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deleteInventoryAdjustment =
  (inventoryAdjustmentId) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      await inventoryAdjustmentApi.delete(`/${inventoryAdjustmentId}`);

      dispatch({
        type: DELETE_INVENTORY_ADJUSTMENT,
        payload: inventoryAdjustmentId
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getInventoryAdjustmentItems =
  (inventoryAdjustmentId) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.get(
        `/${inventoryAdjustmentId}/items`
      );

      dispatch({
        type: GET_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addInventoryAdjustmentInventoryIncreaseItems =
  (inventoryAdjustmentId, req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.post(
        `/increase-inventory/create-items`,
        {
          ...req,
          inventoryAdjustmentId
        }
      );

      const items = await inventoryAdjustmentApi.get(res.data.resourceLocation);

      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: items.data
      });

      return items.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const addInventoryAdjustmentInventoryUpdateItems =
  (inventoryAdjustmentId, req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.post(
        `/update-inventory/create-items`,
        {
          ...req,
          inventoryAdjustmentId
        }
      );

      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const addInventoryAdjustmentInventoryDecreaseItems =
  (inventoryAdjustmentId, req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.post(
        `/decrease-inventory/create-items`,
        {
          ...req,
          inventoryAdjustmentId
        }
      );

      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateInventoryAdjustmentInventoryIncreaseItem =
  (inventoryAdjustmentId, req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.put(
        `/increase-inventory/update-item`,
        { ...req, inventoryAdjustmentId }
      );

      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateInventoryAdjustmentInventoryUpdateItem =
  (inventoryAdjustmentId, req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.put(
        `/update-inventory/update-item`,
        { ...req, inventoryAdjustmentId }
      );

      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deleteInventoryAdjustmentInventoryUpdateModifyItem =
  (req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.delete(
        `/update-inventory/remove-item`,
        {
          data: req
        }
      );

      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deleteInventoryAdjustmentInventoryIncreaseItem =
  (req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.delete(
        `/increase-inventory/remove-item`,
        {
          data: req
        }
      );
      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const deleteInventoryAdjustmentInventoryDecreaseItem =
  (req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.delete(
        `/decrease-inventory/remove-item`,
        {
          data: req
        }
      );
      dispatch({
        type: ADD_INVENTORY_ADJUSTMENT_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const searchInventoryAdjustmentSublineItems =
  (req) =>
  async (dispatch, _, { inventoryAdjustmentApi }) => {
    try {
      const res = await inventoryAdjustmentApi.post(
        `/subline-item/search`,
        req
      );

      if (!res.data) {
        throw new Error(res.message);
      }

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return [];
    }
  };
