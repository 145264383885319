import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Toast as BootstrapToast, ToastBody, ToastHeader } from 'reactstrap';
import vars from '@styles/_export.module.scss';

const ToastWrapper = styled.div`
  position: fixed;
  top: 0;
  padding-top: 1rem;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, ${(props) => (props.isOpen ? 0 : '-120%')});
  transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  width: 350px;
  max-width: 100%;
`;

const StyledToastHeader = styled(ToastHeader)`
  font-size: ${vars.headingSize4};
  line-height: 1;
  padding: 0.5rem 0.75rem;
`;

const StyledToastBody = styled(ToastBody)`
  background-color: ${vars.colorWhite};
  border-radius: 0 0 0.25rem 0.25rem;
  font-size: ${vars.fontSizeNormal};
`;

const ToastLayout = ({ header, body, isOpen, setIsOpen, icon }) => {
  return (
    <ToastWrapper isOpen={isOpen}>
      <BootstrapToast isOpen={true}>
        <StyledToastHeader icon={icon} toggle={() => setIsOpen(false)}>
          {header}
        </StyledToastHeader>
        <StyledToastBody>
          {!Array.isArray(body)
            ? body
            : body.map((line) => <p key={new Date().getTime()}>{line}</p>)}
        </StyledToastBody>
      </BootstrapToast>
    </ToastWrapper>
  );
};

ToastLayout.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  icon: PropTypes.string
};

export default ToastLayout;
