import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'reactstrap';

/* eslint-disable react/display-name, react/prop-types */

const RowOptionButton = ({
  iconClassName,
  loading = false,
  disabled = false,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      color='link'
      className='d-block mx-auto'
      disabled={disabled || loading}
    >
      {loading ? (
        <Spinner size='sm' className='ml-1' />
      ) : (
        <i className={`fa ${iconClassName}`} />
      )}
    </Button>
  );
};

RowOptionButton.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RowOptionButton;
