import produce from 'immer';
import { SET_TOAST, SET_CONFIRM, TOGGLE_PAGE_ERROR } from '@actions/types';

export const initialState = {
  toast: {},
  confirm: {},
  pageError: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_TOAST:
      draft.toast = action.payload;
      return draft;

    case SET_CONFIRM:
      draft.confirm = {
        ...draft.confirm,
        ...action.payload
      };
      return draft;

    case TOGGLE_PAGE_ERROR:
      draft.pageError = action.payload;
      return draft;

    default:
      return draft;
  }
}, initialState);
