import React from 'react';
import PropTypes from 'prop-types';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import { handleSelectRow } from './utils/rowSelectionHandlers';

const SelectAllRowsCell = ({
  nonGroupedFlatRows,
  state: { selectedRows: selected },
  setSelectedRows,
  rowSelectionDisabled = false
}) => {
  return (
    <div>
      <IndeterminateCheckbox
        allItems={nonGroupedFlatRows.map(({ values: { id } }) => id)}
        checkedItems={selected}
        onChange={() =>
          handleSelectRow(
            {
              isGrouped: true,
              subRows: nonGroupedFlatRows
            },
            setSelectedRows
          )
        }
        disabled={rowSelectionDisabled}
      />
    </div>
  );
};

SelectAllRowsCell.propTypes = {
  nonGroupedFlatRows: PropTypes.arrayOf(PropTypes.object),
  state: PropTypes.object.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  rowSelectionDisabled: PropTypes.bool
};

export default SelectAllRowsCell;
