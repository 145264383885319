import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';

const StyledIcon = styled.i`
  margin-left: auto;
  color: ${vars.colorAlpineGray};
`;

const EditableCellValue = ({ value, resolveValue }) => {
  const resolvedValue = resolveValue ? resolveValue(value) : value;

  return (
    <div className='d-flex align-items-center'>
      <span className='pr-2'>{resolvedValue}</span>
      <StyledIcon className='fa fa-pencil' />
    </div>
  );
};

EditableCellValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  resolveValue: PropTypes.func
};

export default EditableCellValue;
