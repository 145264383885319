/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Authenticated, useAuthState } from '@alpinehomeair/alpine-admin';
import NotFound from './components/Layout/NotFound';
import RestoreScroll from './components/Layout/RestoreScroll';

const Home = loadable(() => import('./pages/Home'));
const Deliveries = loadable(() => import('./pages/Deliveries'));
const DeliveryDetails = loadable(() => import('./pages/DeliveryDetails'));
const DeliveryOrderPrint = loadable(() =>
  import('./pages/DeliveryDetails/Print')
);
const ScheduledDeliveries = loadable(() =>
  import('./pages/ScheduledDeliveries')
);
const ItemFulfillments = loadable(() => import('./pages/ItemFulfillments'));
const ItemFulfillmentDetails = loadable(() =>
  import('./pages/ItemFulfillmentDetails')
);
const VendorProfileDetails = loadable(() =>
  import('./pages/VendorProfileDetails')
);
const VendorPurchaseOrder = loadable(() =>
  import('./pages/VendorPurchaseOrder')
);
const VendorPurchaseOrderPrint = loadable(() =>
  import('./pages/VendorPurchaseOrder/Print')
);
const InventoryTag = loadable(() => import('./pages/InventoryTag'));
const PurchaseOrderItemReceipt = loadable(() =>
  import('./pages/PurchaseOrderItemReceipt')
);
const Bill = loadable(() => import('./pages/Bill'));
const ImporterBill = loadable(() => import('./pages/ImporterBill'));
const LocationTransfers = loadable(() => import('./pages/LocationTransfers'));
const LocationTransferDetails = loadable(() =>
  import('./pages/LocationTransferDetails')
);
const Location = loadable(() => import('./pages/Location'));
const LocationSection = loadable(() => import('./pages/LocationSection'));
const LocationBin = loadable(() => import('./pages/LocationBin'));
const PostingPeriods = loadable(() => import('./pages/PostingPeriods'));
const ItemDetails = loadable(() => import('./pages/ItemDetails'));
const AlpineItemAssociation = loadable(() =>
  import('./pages/AlpineItemAssociation')
);
const GLEntries = loadable(() => import('./pages/GLEntries'));
const InventoryItems = loadable(() => import('./pages/InventoryItems'));
const SublineItems = loadable(() => import('./pages/SublineItems'));
const SublineItemDetails = loadable(() => import('./pages/SublineItemDetails'));
const Vendors = loadable(() => import('./pages/Vendors'));
const Locations = loadable(() => import('./pages/Locations'));
const InventoryAdjustments = loadable(() =>
  import('./pages/InventoryAdjustments')
);
const InventoryAdjustmentDetails = loadable(() =>
  import('./pages/InventoryAdjustmentDetails')
);
const PurchaseOrders = loadable(() => import('./pages/PurchaseOrders'));
const ItemReceipts = loadable(() => import('./pages/ItemReceipts'));
const PurchaseOrderItems = loadable(() => import('./pages/PurchaseOrderItems'));
const Bills = loadable(() => import('./pages/Bills'));
const Expenses = loadable(() => import('./pages/Expenses'));

const AuthWait = ({ children }) => {
  const { authenticated } = useAuthState();
  return authenticated ? children : null;
};

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => {
        if (route.authenticated) {
          return (
            <Authenticated>
              <AuthWait>
                <RestoreScroll {...props}>
                  <route.component {...props} routes={route.routes} />
                </RestoreScroll>
              </AuthWait>
            </Authenticated>
          );
        }
        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={NotFound} />
    </Switch>
  );
}

const ROUTES = [
  {
    path: '/',
    key: 'APP',
    component: RenderRoutes,
    authenticated: true,
    routes: [
      {
        path: '/',
        key: 'ROOT',
        component: Home,
        exact: true,
        name: 'Home'
      },
      {
        path: '/item-fulfillments',
        key: 'APP_PAGE',
        component: ItemFulfillments,
        exact: true,
        name: 'Item Fulfillments'
      },
      {
        path: '/inventory-tag/:tagId',
        key: 'APP_PAGE',
        component: InventoryTag,
        exact: true,
        name: 'Inventory Tag'
      },
      {
        path: '/item-fulfillment/:fulfillmentId',
        key: 'APP_PAGE',
        component: ItemFulfillmentDetails,
        exact: true,
        name: 'Item Fulfillment Details'
      },
      {
        path: '/vendor/:vendorId',
        key: 'APP_PAGE',
        component: VendorProfileDetails,
        exact: true,
        name: 'Vendor Profile Page'
      },
      {
        path: '/vendor/:vendorId/purchase-order/:purchaseOrderId',
        key: 'APP_PAGE',
        component: VendorPurchaseOrder,
        exact: true,
        name: 'Vendor Purchase Order Page'
      },
      {
        path: '/vendor/:vendorId/purchase-order/:purchaseOrderId/print',
        key: 'APP_PAGE',
        component: VendorPurchaseOrderPrint,
        exact: true,
        name: 'Vendor Purchase Order Printable Page'
      },
      {
        path: '/vendor/:vendorId/purchase-order/:purchaseOrderId/item-receipt/:itemReceiptId',
        key: 'APP_PAGE',
        component: PurchaseOrderItemReceipt,
        exact: true,
        name: 'Vendor Item Receipt Page'
      },
      {
        path: '/vendor/:vendorId/bill/:billId',
        key: 'APP_PAGE',
        component: Bill,
        exact: true,
        name: 'Bill Page'
      },
      {
        path: '/vendor/:vendorId/importer-bill/:billId',
        key: 'APP_PAGE',
        component: ImporterBill,
        exact: true,
        name: 'Importer Bill Page'
      },
      {
        path: '/location-transfers',
        key: 'APP_PAGE',
        component: LocationTransfers,
        exact: true,
        name: 'Location Transfers List Page'
      },
      {
        path: '/location-transfer/:locationTransferId',
        key: 'APP_PAGE',
        component: LocationTransferDetails,
        exact: true,
        name: 'Location Transfer Details Page'
      },
      {
        path: '/deliveries',
        key: 'APP_PAGE',
        component: Deliveries,
        exact: true,
        name: 'Deliveries'
      },
      {
        path: '/deliveries/:shipmentId',
        key: 'APP_PAGE',
        component: DeliveryDetails,
        exact: true,
        name: 'Delivery Details'
      },
      {
        path: '/delivery-order/:shipmentId/print',
        key: 'APP_PAGE',
        component: DeliveryOrderPrint,
        exact: true,
        name: 'Delivery Order Printable Page'
      },
      {
        path: '/scheduled-deliveries',
        key: 'APP_PAGE',
        component: ScheduledDeliveries,
        exact: true,
        name: 'Scheduled Deliveries'
      },
      {
        path: '/location/:warehouseId',
        key: 'APP_PAGE',
        component: Location,
        exact: true,
        name: 'Location Sections'
      },
      {
        path: '/location/:warehouseId/section/:sectionId',
        key: 'APP_PAGE',
        component: LocationSection,
        exact: true,
        name: 'Location Section'
      },
      {
        path: '/location/:warehouseId/section/:sectionId/bin/:binId',
        key: 'APP_PAGE',
        component: LocationBin,
        exact: true,
        name: 'Location Bin'
      },
      {
        path: '/posting-periods',
        key: 'APP_PAGE',
        component: PostingPeriods,
        exact: true,
        name: 'Posting Periods'
      },
      {
        path: '/item/:itemId',
        key: 'APP_PAGE',
        component: ItemDetails,
        exact: true,
        name: 'Item Details'
      },
      {
        path: '/alpine-item-association/:alpineItemId',
        key: 'APP_PAGE',
        component: AlpineItemAssociation,
        exact: true,
        name: 'Alpine Item Assocation'
      },
      {
        path: '/gl-entries',
        key: 'APP_PAGE',
        component: GLEntries,
        exact: true,
        name: 'GLEntries'
      },
      {
        path: '/inventory-items',
        key: 'APP_PAGE',
        component: InventoryItems,
        exact: true,
        name: 'InventoryItems'
      },
      {
        path: '/subline-items',
        key: 'APP_PAGE',
        component: SublineItems,
        exact: true,
        name: 'SublineItems'
      },
      {
        path: '/subline-item/:sublineItemId',
        key: 'APP_PAGE',
        component: SublineItemDetails,
        exact: true,
        name: 'SublineItemDetails'
      },
      {
        path: '/vendors',
        key: 'APP_PAGE',
        component: Vendors,
        exact: true,
        name: 'Vendors'
      },
      {
        path: '/locations',
        key: 'APP_PAGE',
        component: Locations,
        exact: true,
        name: 'Locations'
      },
      {
        path: '/inventory-adjustments',
        key: 'APP_PAGE',
        component: InventoryAdjustments,
        exact: true,
        name: 'InventoryAdjustments'
      },
      {
        path: '/inventory-adjustment/:inventoryAdjustmentId',
        key: 'APP_PAGE',
        component: InventoryAdjustmentDetails,
        exact: true,
        name: 'InventoryAdjustmentDetails'
      },
      {
        path: '/purchase-orders',
        key: 'APP_PAGE',
        component: PurchaseOrders,
        exact: true,
        name: 'PurchaseOrders'
      },
      {
        path: '/item-receipts',
        key: 'APP_PAGE',
        component: ItemReceipts,
        exact: true,
        name: 'ItemReceipts'
      },
      {
        path: '/purchase-order-items',
        key: 'APP_PAGE',
        component: PurchaseOrderItems,
        exact: true,
        name: 'PurchaseOrderItems'
      },
      {
        path: '/bills',
        key: 'APP_PAGE',
        component: Bills,
        exact: true,
        name: 'Bills'
      },
      {
        path: '/expenses',
        key: 'APP_PAGE',
        component: Expenses,
        exact: true,
        name: 'Expenses'
      }
    ]
  }
];

RenderRoutes.propTypes = {
  routes: PropTypes.array
};

export default ROUTES;
