import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

const StyledContainer = styled(Container)`
  padding: 1rem;
`;

const NotFound = () => {
  return (
    <StyledContainer fluid>
      <h1>Page Not Found</h1>
      <Link to='/' className='mt-1'>
        Return Home
      </Link>
    </StyledContainer>
  );
};

export default NotFound;
