import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMountedRef, useConfirm } from '@hooks';
import RowOptionButton from './RowOptionButton';

const RemoveRowOptionsButton = ({
  row,
  handleSubmit,
  confirmDialog,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { isConfirmed } = useConfirm();
  const isMounted = useMountedRef();

  const onClick = useCallback(async () => {
    const confirmed = confirmDialog ? await isConfirmed(confirmDialog) : true;

    if (confirmed) {
      setLoading(true);
      await handleSubmit(row);
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [handleSubmit, confirmDialog, row, setLoading, isConfirmed, isMounted]);

  return (
    <RowOptionButton
      onClick={onClick}
      iconClassName='fa-trash'
      title='remove row'
      loading={loading}
      {...rest}
    />
  );
};

RemoveRowOptionsButton.propTypes = {
  row: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  confirmDialog: PropTypes.string
};

export default RemoveRowOptionsButton;
