import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { InfoBlock } from '../StyledComponents';
import Tooltip from '../Tooltip';

const InfoBlockGenerator = ({ infoBlocks, columns }) => {
  // if no columns passed, then map a single column of field names
  const resolvedColumns = columns || [infoBlocks.map(({ name }) => name)];

  return (
    <Row>
      {resolvedColumns.map((col, idx) => (
        <Col key={idx} md={Math.floor(12 / resolvedColumns.length)}>
          {col.map((fieldName) => {
            const infoBlock = infoBlocks.find(
              (item) => item.name === fieldName
            );

            const {
              label,
              value,
              resolveValue,
              InfoBlock: Block,
              tooltip
            } = infoBlock;

            if (Block) {
              return <Block key={fieldName} {...infoBlock} />;
            }

            const showColon = label.substr(-1) !== '?';

            return (
              <InfoBlock key={fieldName}>
                <label id={fieldName}>
                  {label}
                  {tooltip && (
                    <Tooltip
                      id={fieldName}
                      text={tooltip}
                      className='ml-1'
                      placement='top'
                    />
                  )}
                  {showColon && ':'}
                </label>
                <span aria-labelledby={fieldName}>
                  {resolveValue ? resolveValue(value) : value}
                </span>
              </InfoBlock>
            );
          })}
        </Col>
      ))}
    </Row>
  );
};

InfoBlockGenerator.propTypes = {
  infoBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.array)
};

export default InfoBlockGenerator;
