import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShippingLocations } from '@actions';

const useShippingLocations = (options = {}) => {
  const { includeDataLoaded = false, ...restOptions } = options;
  const filter = {
    active: true,
    ...restOptions
  };

  const dispatch = useDispatch();
  const { shippingLocations } = useSelector((state) => state.globals);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const getLocations = async () => {
      await dispatch(getShippingLocations());
      setDataLoaded(true);
    };

    if (
      shippingLocations.length === 0 ||
      (includeDataLoaded && dataLoaded === false)
    ) {
      getLocations();
    }
  }, [shippingLocations, includeDataLoaded, dataLoaded, dispatch]);

  if (includeDataLoaded) {
    return {
      dataLoaded,
      shippingLocations
    };
  }

  const filters = Object.entries(filter);
  return shippingLocations.filter((location) =>
    filters.every(([key, val]) => location[key] === val)
  );
};

export default useShippingLocations;
