import produce from 'immer';
import {
  GET_ATTACHMENTS,
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT
} from '@actions/types';

const resolveAttachment = (attachment) => ({
  id: attachment.ID,
  name: attachment.OriginalName,
  src: attachment.FilePath,
  extension: attachment.Extension,
  recordType: attachment.RecordType,
  recordId: attachment.RecordID
});

export const initialState = {
  data: []
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_ATTACHMENTS:
    case ADD_ATTACHMENTS: {
      draft.data = action.payload.map(resolveAttachment);
      return draft;
    }

    case REMOVE_ATTACHMENT:
      draft.data = draft.data.filter(({ id }) => id !== action.payload);
      return draft;

    default:
      return draft;
  }
}, initialState);
