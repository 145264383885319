import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useGroupBy,
  useExpanded,
  useFilters,
  useSortBy
} from 'react-table';
import PaginationControls from './PaginationControls';
import {
  useRowSelection,
  useEditableTable,
  useRowOptions,
  usePagination
} from './utils';
import TableLayout from './TableLayout';

/* eslint-disable react/display-name, react/prop-types */

const Table = ({
  config,
  options = [],
  OptionsBar,
  RowOptions,
  selectable = false,
  editable = false,
  paginate = false,
  showFooter = false,
  setRef,
  id,
  loading = false,
  error,
  paginationHeightBuffer
}) => {
  let resolvedConfig = {
    autoResetFilters: false,
    autoResetExpanded: false,
    autoResetSortBy: false,
    ...config
  };

  // Note: these options need to be in a particular order for React-Table.
  // If passing options as a prop, make sure to include the following
  // hooks in addition to whatever hook options you're passing.
  let resolvedOptions =
    options.length > 0
      ? options
      : [useFilters, useGroupBy, useSortBy, useExpanded];

  const rowSelection = useRowSelection(
    resolvedConfig,
    resolvedOptions,
    selectable
  );
  resolvedConfig = rowSelection.config;
  resolvedOptions = rowSelection.options;

  const editableTable = useEditableTable(resolvedConfig, editable);
  resolvedConfig = editableTable.config;

  const pagination = usePagination(
    resolvedConfig,
    resolvedOptions,
    paginate,
    paginationHeightBuffer
  );
  resolvedConfig = pagination.config;
  resolvedOptions = pagination.options;

  const rowOptions = useRowOptions(resolvedOptions, RowOptions);
  resolvedOptions = rowOptions.options;

  const tableInstance = useTable(resolvedConfig, ...resolvedOptions);

  useEffect(() => {
    if (setRef) {
      setRef({ current: tableInstance });
    }
  }, [tableInstance, setRef]);

  const { rows, page } = tableInstance;

  const rowArr = paginate ? page : rows;
  return (
    <>
      {OptionsBar && <OptionsBar {...tableInstance} />}

      <TableLayout
        {...tableInstance}
        rows={rowArr}
        showFooter={showFooter}
        tableId={id}
        loading={loading}
        error={error}
      />

      {paginate && <PaginationControls {...tableInstance} />}
    </>
  );
};

Table.propTypes = {
  config: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.func),
  selectable: PropTypes.bool,
  editable: PropTypes.bool,
  paginate: PropTypes.bool,
  OptionsBar: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  RowOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  showFooter: PropTypes.bool,
  setRef: PropTypes.func,
  id: PropTypes.string,
  loading: PropTypes.bool,
  paginationHeightBuffer: PropTypes.number
};

export default Table;
