import produce from 'immer';
import {
  GET_INVENTORY_ADJUSTMENTS,
  GET_INVENTORY_ADJUSTMENT_SUMMARY,
  UPDATE_INVENTORY_ADJUSTMENT,
  DELETE_INVENTORY_ADJUSTMENT,
  GET_INVENTORY_ADJUSTMENT_ITEMS,
  ADD_INVENTORY_ADJUSTMENT_ITEMS,
  GET_INVENTORY_ADJUSTMENT_SUPPORTING_DATA
} from '@actions/types';

export const initialState = {
  adjustments: [], // This is only needed in the prototype
  summary: {},
  items: [],
  reasons: [],
  actions: []
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_INVENTORY_ADJUSTMENT_SUPPORTING_DATA: {
      const { reasons, actions } = action.payload;
      draft.reasons = reasons;
      draft.actions = actions;
      return draft;
    }

    case GET_INVENTORY_ADJUSTMENTS:
      draft.adjustments = action.payload;
      return draft;

    case GET_INVENTORY_ADJUSTMENT_SUMMARY:
    case UPDATE_INVENTORY_ADJUSTMENT: {
      draft.summary = action.payload;
      return draft;
    }

    case DELETE_INVENTORY_ADJUSTMENT: {
      draft.adjustments = draft.adjustments.filter(
        ({ id }) => id !== action.payload
      );
      return draft;
    }

    case GET_INVENTORY_ADJUSTMENT_ITEMS:
    case ADD_INVENTORY_ADJUSTMENT_ITEMS: {
      draft.items = action.payload;
      return draft;
    }

    default:
      return draft;
  }
}, initialState);
