import React from 'react';
import PropTypes from 'prop-types';
import vars from '@styles/_export.module.scss';
import Cell from './Cell';
import Tooltip from '../Tooltip';

const TooltipCell = ({ cell, column, row, tooltip, placement = 'left' }) => {
  const id = `${column.id}-${row.id}`;

  return (
    <Cell>
      <div className='d-flex align-items-center'>
        <span className='pr-2'>{cell.value}</span>
        <Tooltip
          id={id}
          placement={placement}
          text={tooltip}
          color={vars.colorAlpineGray}
        />
      </div>
    </Cell>
  );
};

TooltipCell.propTypes = {
  cell: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  tooltip: PropTypes.string.isRequired,
  placement: PropTypes.string
};

export default TooltipCell;
