export const getSuccessToast = (params = {}) => ({
  header: 'Success',
  body: 'Action completed successfully.',
  isOpen: true,
  icon: 'success',
  ...params
});

export const getErrorToast = (params = {}) => ({
  header: 'Error',
  body: 'An error occurred, please try again.',
  isOpen: true,
  icon: 'danger',
  timeout: 0,
  ...params
});

export const getWarningToast = (params = {}) => ({
  header: 'Warning',
  body: 'Something happened.',
  isOpen: true,
  icon: 'warning',
  timeout: 0,
  ...params
});
