const resolveLink = (type, id, params = {}) => {
  switch (type) {
    case 'vendor':
      return `/vendor/${id}`;

    case 'purchaseOrder':
      return `/vendor/${params.vendorId}/purchase-order/${id}`;

    case 'bill':
      return `/vendor/${params.vendorId}/bill/${id}`;

    case 'importer-bill':
      return `/vendor/${params.vendorId}/importer-bill/${id}`;

    case 'itemReceipt':
      return `/vendor/${params.vendorId}/purchase-order/${params.purchaseOrderId}/item-receipt/${id}`;

    case 'location':
      return `/location/${id}`;

    case 'section':
      return `/location/${params.warehouseId}/section/${id}`;

    case 'bin':
      return `/location/${params.warehouseId}/section/${params.sectionId}/bin/${id}`;

    case 'item':
      return `/item/${id}`;

    case 'sublineItem':
      return `/subline-item/${id}`;

    case 'inventoryAdjustment':
      return `/inventory-adjustment/${id}`;

    case 'locationTransfer':
      return `/location-transfer/${id}`;

    case 'itemFulfillment':
      return `/item-fulfillment/${id}`;

    case 'pNetCustomerPurchaseOrder':
      return `/purchaseorder/${id}`;

    case 'pNetCustomer':
      return `/customer/${id}`;

    case 'delivery':
      return `/deliveries/${id}`;

    case 'activeCustomerPOs':
      return `/fulfillment/templates/activeOrders.cfm?search=${params.POIDs}`;

    case 'purchaseOrders':
      return `/purchase-orders`;

    case 'inventoryTag':
    case 'returnItemReceipt':
      return `/rma/${id}`;

    default:
      return '';
  }
};

export default resolveLink;
