import produce from 'immer';
import {
  GET_HISTORY,
  SET_HISTORY_LOADING,
  SET_HISTORY_ERROR
} from '@actions/types';

export const initialState = {
  data: [],
  loading: false,
  error: null
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_HISTORY:
      draft.data = action.payload;
      draft.loading = false;
      return draft;

    case SET_HISTORY_LOADING:
      draft.error = null;
      draft.loading = action.payload;
      return draft;

    case SET_HISTORY_ERROR:
      draft.error = action.payload;
      draft.loading = false;
      return draft;

    default:
      return draft;
  }
}, initialState);

export const selectHistory = (state) => state.history;
