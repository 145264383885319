import { formatDate } from '@utils';

export const getFirstAggregate = (values) => values[0];

export const getUniquesAggregate = (values) => {
  const uniques = [
    ...new Set(values.filter((value) => value !== null && value !== ''))
  ];

  if (uniques.length > 3) {
    return [...uniques.slice(0, 3), '...'].join(', ');
  }

  return uniques.join(', ');
};

export const getTruthyCountAggregate = (values) =>
  values.filter(Boolean).length;

export const getEarliestDateAggregate = (values) => {
  const date = [...new Set(values)].filter(Boolean).sort((a, b) => a - b)[0];

  return date ? formatDate(date) : '';
};
