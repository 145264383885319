import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Collapse, NavLink } from 'reactstrap';
import vars from '@styles/_export.module.scss';
import { ActionLink, ActionLinkGroup } from '../ActionLink';
import { CollapseIcon } from '../StyledComponents';

const ContentPanelWrapper = styled.div`
  margin: 0 auto;
  padding-top: 20px;
`;

const ContentPanelHeaderWrapper = styled.div`
  background-color: ${vars.colorPnetBlue};
  border-color: ${vars.colorAlpineGray};
  color: ${vars.colorWhite};
  font-size: ${vars.headingSize5};
  font-weight: bold;
  border-radius: ${(props) =>
    props.isOpen ? '0.25rem 0.25rem 0 0' : '0.25rem'};
  margin: ${(props) => (props.isOpen ? '-1px -1px 0 -1px' : '-1px')};
  a {
    color: ${vars.colorWhite};
    padding: 0;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.75rem;
`;

const Title = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContentPanel = ({
  title,
  isCollapsible = true,
  initialIsOpen = true,
  children,
  actionLink = {},
  actionLinkGroup = {},
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const handleClick = () => setIsOpen(!isOpen);

  const buildCardBody = () => {
    if (isCollapsible) {
      return (
        <Collapse isOpen={isOpen} data-test='CollapsibleContentPanelComponent'>
          <CardBody>{children}</CardBody>
        </Collapse>
      );
    }

    return <CardBody>{children}</CardBody>;
  };

  const buildHeader = () => {
    if (isCollapsible) {
      return (
        <NavLink
          data-test='CollapseLink'
          href='#'
          onClick={() => handleClick()}
        >
          <CollapseIcon isOpen={isOpen} /> <Title>{title}</Title>
        </NavLink>
      );
    }

    return <Title>{title}</Title>;
  };

  return (
    <ContentPanelWrapper
      className={className}
      data-test='ContentPanelComponent'
    >
      <Card>
        <ContentPanelHeaderWrapper isOpen={isOpen}>
          <StyledCardHeader>
            {buildHeader()}
            <ActionLink {...actionLink} className='ml-auto' onDarkBg />
            <ActionLinkGroup
              {...actionLinkGroup}
              className='ml-auto'
              onDarkBg
            />
          </StyledCardHeader>
        </ContentPanelHeaderWrapper>
        {buildCardBody()}
      </Card>
    </ContentPanelWrapper>
  );
};

ContentPanel.propTypes = {
  isCollapsible: PropTypes.bool,
  initialIsOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  actionLink: PropTypes.object,
  actionLinkGroup: PropTypes.object,
  className: PropTypes.string
};

export default ContentPanel;
