import produce from 'immer';

import {
  GET_VENDOR_PROFILE_SUCCESS,
  UPDATE_VENDOR_PROFILE_SUCCESS,
  SAVE_VENDOR_CONTACT_SUCCESS,
  UPDATE_VENDOR_CONTACT_SUCCESS,
  SAVE_CONTACT_EMAIL_SUCCESS,
  REMOVE_CONTACT_EMAIL_SUCCESS,
  SAVE_CONTACT_PHONE_SUCCESS,
  REMOVE_CONTACT_PHONE_SUCCESS,
  MERGE_VENDOR_CONTACT_SUCCESS,
  SAVE_VENDOR_INSTRUCTIONS_SUCCESS,
  REMOVE_CONTACT_SUCCESS,
  RESET_VENDOR_PROFILE,
  CREATE_VENDOR_ADDRESS,
  UPDATE_VENDOR_ADDRESS,
  DELETE_VENDOR_ADDRESS,
  GET_MANUFACTURERS_SUCCESS
} from '@actions/types';

export const initialState = {
  vendorProfile: {
    id: null,
    name: '',
    paymentTerms: '',
    netsuiteId: null,
    active: false,
    type: null,
    vendorAccountId: null,
    rebate: null,
    manufacturerId: null
  },
  vendorTypes: [
    {
      id: 1,
      typeName: 'Manufacturer'
    },
    {
      id: 2,
      typeName: 'Carrier'
    },
    {
      id: 4,
      typeName: 'Importer'
    }
  ],
  vendorLocations: [
    {
      id: 1,
      typeName: 'Domestic'
    },
    {
      id: 0,
      typeName: 'Overseas'
    }
  ],
  vendorContacts: [],
  vendorInstructionTypes: [
    {
      id: 1,
      typeName: 'Purchase Order Entry Instructions'
    },
    {
      id: 2,
      typeName: 'ETA Update Instructions'
    },
    {
      id: 3,
      typeName: 'Warranty Filing Instructions'
    },
    {
      id: 4,
      typeName: 'Special Order Instructions'
    }
  ],
  vendorInstructions: [],
  vendorAddresses: [],
  manufacturers: []
};

const sortByPriority = (arr = []) => {
  const resolvedArr = Array.isArray(arr) ? arr : [];
  return resolvedArr.sort((a, b) => a.priority - b.priority);
};

const sortContactEmailPhone = ({ emails = [], phones = [], ...rest }) => ({
  ...rest,
  emails: sortByPriority(emails),
  phones: sortByPriority(phones)
});

const sorContacts = (contacts = []) =>
  contacts.map(sortContactEmailPhone).sort((a, b) => b.primary - a.primary);

export default produce((draft, action) => {
  switch (action.type) {
    case GET_VENDOR_PROFILE_SUCCESS:
    case UPDATE_VENDOR_PROFILE_SUCCESS: {
      const {
        contacts = [],
        instructions = [],
        addresses = [],
        ...rest
      } = action.payload;

      draft.vendorProfile = {
        ...draft.vendorProfile,
        ...rest
      };

      draft.vendorContacts = contacts ? sorContacts(contacts) : [];
      draft.vendorInstructions = instructions || [];
      draft.vendorAddresses = addresses || [];

      return draft;
    }

    case GET_MANUFACTURERS_SUCCESS: {
      draft.manufacturers = action.payload;
      return draft;
    }

    case SAVE_VENDOR_CONTACT_SUCCESS:
      draft.vendorContacts.push(action.payload);
      return draft;

    case UPDATE_VENDOR_CONTACT_SUCCESS: {
      const { id, emails, phones, ...rest } = action.payload;
      const idx = draft.vendorContacts.findIndex(
        (contact) => contact.id === id
      );

      draft.vendorContacts[idx] = {
        id,
        emails: emails || [],
        phones: phones || [],
        ...rest
      };
      return draft;
    }

    case REMOVE_CONTACT_SUCCESS:
      draft.vendorContacts = draft.vendorContacts.filter(
        ({ id }) => id !== action.payload.contactId
      );
      return draft;

    case SAVE_CONTACT_EMAIL_SUCCESS: {
      const { email } = action.payload;

      const contact = draft.vendorContacts.find(
        ({ id }) => id === email.contactId
      );

      contact.emails = contact.emails || [];
      const emailIdx = contact.emails.findIndex(
        ({ id: emailId }) => emailId === email.id
      );
      if (emailIdx > -1) {
        contact.emails[emailIdx] = email;
      } else {
        contact.emails.push(email);
      }

      contact.emails = sortByPriority(contact.emails);

      return draft;
    }

    case REMOVE_CONTACT_EMAIL_SUCCESS: {
      const { id, contactId } = action.payload;
      const contact = draft.vendorContacts.find(
        (vendorContact) => vendorContact.id === contactId
      );
      contact.emails = contact.emails.filter((email) => email.id !== id);
      return draft;
    }

    case SAVE_CONTACT_PHONE_SUCCESS: {
      const phone = action.payload;

      const contact = draft.vendorContacts.find(
        ({ id }) => id === phone.contactId
      );
      contact.phones = contact.phones || [];
      const phoneIdx = contact.phones.findIndex(
        ({ id: phoneId }) => phoneId === phone.id
      );
      if (phoneIdx > -1) {
        contact.phones[phoneIdx] = phone;
      } else {
        contact.phones.push(phone);
      }

      contact.phones = sortByPriority(contact.phones);

      return draft;
    }

    case REMOVE_CONTACT_PHONE_SUCCESS: {
      const { id, contactId } = action.payload;
      const contact = draft.vendorContacts.find(
        (vendorContact) => vendorContact.id === contactId
      );
      contact.phones = contact.phones.filter((phone) => phone.id !== id);
      return draft;
    }

    case MERGE_VENDOR_CONTACT_SUCCESS: {
      draft.vendorContacts = sorContacts(action.payload);
      return draft;
    }

    case SAVE_VENDOR_INSTRUCTIONS_SUCCESS:
      draft.vendorInstructions.push(action.payload);
      return draft;

    case CREATE_VENDOR_ADDRESS:
      draft.vendorAddresses.push(action.payload);
      return draft;

    case UPDATE_VENDOR_ADDRESS: {
      const idx = draft.vendorAddresses.findIndex(
        ({ id }) => id === action.payload.id
      );
      draft.vendorAddresses[idx] = action.payload;
      return draft;
    }

    case DELETE_VENDOR_ADDRESS:
      draft.vendorAddresses = draft.vendorAddresses.filter(
        ({ id }) => id !== action.payload
      );
      return draft;

    case RESET_VENDOR_PROFILE:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
