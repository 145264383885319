const getPostingPeriodFromDate = (expenseType) => {
  switch (expenseType) {
    case 'domestic_shipping':
    case 'international_shipping':
    case 'fuel_surcharge':
      return 'Weight';
    case 'international_duties':
      return 'Value';
    default:
      return '';
  }
};

export default getPostingPeriodFromDate;
