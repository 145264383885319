const getErrorMessage = (e = {}) => {
  const { errors, data } = e.response?.data || {};

  if (typeof data === 'string') {
    return data;
  }

  if (Array.isArray(errors)) {
    return errors.map(({ message }) => message).join('; ');
  }

  return e.message;
};

export default getErrorMessage;
