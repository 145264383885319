import React from 'react';
import PropTypes from 'prop-types';
import { useDimensions } from '@hooks';

const HeightTransitioner = ({
  easing = 'cubic-bezier(0.33, 1, 0.68, 1)',
  duration = 300,
  className,
  children
}) => {
  const [el] = useDimensions();

  return (
    <div
      className={className}
      style={{
        height: el?.current?.scrollHeight,
        transition: `height ${duration}ms ${easing}`
      }}
    >
      <div ref={el}>{children}</div>
    </div>
  );
};

HeightTransitioner.propTypes = {
  easing: PropTypes.string,
  duration: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node
};

export default HeightTransitioner;
