const vendorSublist = [
  {
    id: '1',
    inventoryItemSku: '453068963',
    model: 'DPSG-3S',
    catalogId: 12345,
    unitType: 'Case',
    unitQty: 1,
    expectedUnitPrice: 13.25
  },
  {
    id: '2',
    inventoryItemSku: '453068963',
    model: 'DPSG-3S',
    catalogId: 54321,
    unitType: 'Pallet',
    unitQty: 10,
    expectedUnitPrice: 12.99
  }
];

const generateItems = (amount, vendorProductId, unitType, params) => {
  const prod = vendorSublist.find(({ id }) => id === vendorProductId);
  return Array(amount)
    .fill()
    .map((item, idx) => ({
      ...params,
      ...prod,
      vendorProductId,
      id: Number.parseInt(`${vendorProductId}${unitType}${idx}`, 10),
      serialNumber: Number.parseInt(`${vendorProductId}${unitType}${idx}`, 10)
    }));
};

const generatePOItems = () => {
  const params = {
    expectedReceiptDate: new Date().getTime(),
    purchaseOrderId: 1,
    received: false,
    billed: false
  };

  return vendorSublist.reduce((acc, cur) => {
    return [...acc, ...generateItems(5, cur.id, cur.unitType, params)];
  }, []);
};

export const items = generatePOItems();

export const vendorSublistItems = {
  data: vendorSublist
};

export const locations = {
  data: [
    {
      id: 1,
      address1: '123 Main St',
      address2: 'Unit 1',
      city: 'Louisville',
      state: 'KY',
      country: 'US',
      postalCode: 40018,
      phone: '(123) 456 - 7890'
    }
  ]
};

export const ASAPproducts = {
  data: []
};

export const purchaseOrderSummary = {
  // some of this summary stuff is inherited from the Vendor
  id: 1,
  vendorId: 1,
  statusId: 1,
  vendorAccountId: '',
  warehouseId: 1,
  receiveByDate: '2021-04-01',
  shipToAddress1: '123 Main St',
  shipToAddress2: 'Unit 1',
  shipToCity: 'Louisville',
  shipToCountry: 'US',
  shipToNotes: '',
  shipToPhone: '(123) 456 - 7890',
  shipToPostalCode: 40018,
  shipToState: 'KY',
  terms: ''
};

export const containers = [
  {
    id: 1111111,
    purchaseOrderId: 1,
    containerNumber: 7923498,
    portName: 'New York',
    portArrivalDate: new Date('03/15/2021'),
    locationArrivalDate: new Date('03/20/2021'),
    status: 'active'
  },
  {
    id: 2222222,
    purchaseOrderId: 1,
    containerNumber: 4627298,
    portName: 'Boston',
    portArrivalDate: new Date('03/18/2021'),
    locationArrivalDate: new Date('03/22/2021'),
    status: 'active'
  },
  {
    id: 3333333,
    purchaseOrderId: 1,
    containerNumber: 8872933,
    portName: 'New York',
    portArrivalDate: new Date('03/15/2021'),
    locationArrivalDate: new Date('03/20/2021'),
    status: 'active'
  }
];
