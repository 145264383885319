import { formatMoney } from '@utils';

export const item = {
  sku: '196',
  manufacturer: 'Aprilaire',
  netsuiteVendor: 'Vendor 1',
  model: '600',
  shortDescription: '24v Large Bypass Model with Automatic Control',
  itemType: 'Box',
  purchasingStatus:
    'Blacklisted - Cannot be purchased by customer or from vendor',
  smallPack: true,
  serialized: false,
  shipFreight: false,
  pickListPartNumber: 'B82-276',
  upc: 'something',
  unitCost: 148.83,
  weight: 10,
  width: 17.25,
  height: 11.75,
  itemLength: 18.25,
  createdBy: 'Jared Loson',
  created: '2021-01-01',
  id: 1,
  packQuantity: 1,
  unitOfMeasure: 1,
  mfgBoxQuantity: 1,
  mfgBoxWeight: 1,
  mfgBoxHeight: 1,
  mfgBoxWidth: 1,
  mfgBoxLength: 1,
  shipInMfgBoxSmallPack: true,
  shipInMfgBoxFreight: true,
  shipVerticalRotation: true,
  shippable: true,
  skid: '',
  nafta: '',
  freightClass: 77.5,
  nmfc: '114145-02',
  customsDescription: '24v Large Bypass Model with Automatic Control',
  countryOfMfr: 'USA'
};

export const freightClasses = [
  '50.0',
  '60.0',
  '65.0',
  '70.0',
  '77.5',
  '85.0',
  '92.5',
  '100.0',
  '110.0',
  '125.0',
  '150.0',
  '175.0',
  '200.0',
  '250.0',
  '300.0'
];

export const manufacturers = [
  ' Friedrich',
  '100374 - Double Bond Heating & Air',
  '100493 - ECI HVAC',
  '106972 - Mid-State Heating And Air',
  '107147 - Tiger Woods Contracting',
  '107169 - NRG Heating and Cooling'
];

export const purchasingStatuses = [
  'Actively Purchased',
  'Blacklisted - Cannot be purchased by customer or from vendor',
  'Special Order - Final Sale',
  'Special Order - Restock Fee',
  'Special Order - Standard Return'
];

export const itemTypes = [
  'Box',
  'Extended Warranty',
  'Installation Service',
  'International Fee',
  'Kit/Bundle Item',
  'Pack Inventory Item',
  'Packaging',
  'Pallet',
  'Payment Item',
  'Replacement Part',
  'Replica Inventory Item',
  'Shipping Item',
  'SND Inventory Item',
  'Standard Inventory Item',
  'Supply Package Item',
  'Test/Ops/Dupe Item'
];

export const unitTypes = ['Case', 'Pallet'];

export const itemVendors = [
  {
    id: 1,
    vendor: 'Goodman Distribution',
    inventoryItemSku: '196',
    preferred: true,
    catalogId: '123ABC',
    model: 'XYZ987',
    expectedUnitPrice: 1140.5,
    unitType: 'Case',
    unitQty: 1,
    active: true
  }
];

export const vendors = ['Vendor 1', 'Vendor 2', 'Vendor 3'];

export const itemLocations = [
  {
    id: 1,
    location: 'Louisville',
    locationType: 'Warehouse',
    priority: 1,
    salePrice: 1000.4,
    lastPurchasePrice: 990.3,
    qtyOnHand: 40,
    qtyOnOrder: 10,
    qtyCommitted: 25,
    qtyBackordered: 0,
    qtyAvailable: 15,
    active: true
  },
  {
    id: 3,
    location: 'Goodman Sacramento',
    locationType: 'Drop Shipper',
    priority: 6,
    salePrice: 1010.4,
    lastPurchasePrice: 990.3,
    qtyOnHand: 40,
    qtyOnOrder: 10,
    qtyCommitted: 25,
    qtyBackordered: 0,
    qtyAvailable: 15,
    active: true
  }
];

export const baseHistory = [
  {
    id: 123,
    date: '2021-04-21T13:17:33.826Z',
    typeId: 'purchaseOrder',
    type: 'Purchase Order',
    name: 'Goodman',
    quantity: 10,
    expectedUnitPrice: 1000.4,
    vendorId: 1
  },
  {
    id: 234,
    date: '2021-04-21T13:17:33.826Z',
    typeId: 'itemReceipt',
    type: 'Item Receipt',
    name: 'Goodman',
    quantity: 10,
    unitCost: 1100.4,
    vendorId: 1,
    purchaseOrderId: 1
  },
  {
    id: 345,
    date: '2021-04-21T13:17:33.826Z',
    typeId: 'bill',
    type: 'Bill',
    name: 'Goodman',
    quantity: 10,
    unitCost: 1150.4,
    vendorId: 1
  }
];

export const history = baseHistory.map((historyItem) => ({
  ...historyItem,
  recordId: historyItem.id,
  recordType: historyItem.type,
  message: `Added to ${historyItem.type}`,
  context: `${historyItem.quantity} units at ${formatMoney(
    historyItem.expectedUnitPrice || historyItem.unitCost
  )}`,
  user: 'Jared Loson'
}));

export const netsuiteVendors = ['Vendor 1', 'Vendor 2', 'Vendor 3'];

export const getResolvedInitialState = (initialState = {}) => ({
  itemDetails: {
    ...initialState,
    info: item,
    freightClasses,
    manufacturers,
    purchasingStatuses,
    itemTypes,
    unitTypes,
    itemVendors,
    itemLocations,
    history,
    netsuiteVendors
  }
});
