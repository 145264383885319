import React, { memo } from 'react';
import { parseISO, isWithinInterval } from 'date-fns';
import { StyledInput, StyledSelect } from '../StyledComponents';
import DateRangeInput from '../Forms/DateRangeInput';

/* eslint-disable react/display-name, react/prop-types */

export const BooleanFilter = memo((props) => (
  <StyledSelect type='select' {...props}>
    <option value=''>Select...</option>
    <option value='true'>True</option>
    <option value='false'>False</option>
  </StyledSelect>
));

export const NumericBooleanFilter = memo((props) => (
  <StyledSelect type='select' {...props}>
    <option value=''>Select...</option>
    <option value='1'>True</option>
    <option value='0'>False</option>
  </StyledSelect>
));

export const YesNoFilter = memo((props) => (
  <StyledSelect type='select' {...props}>
    <option value=''>Select...</option>
    <option value='true'>Yes</option>
    <option value='false'>No</option>
  </StyledSelect>
));

export const DateFilter = memo((props) => {
  return <StyledInput type='date' {...props} />;
});

export const dateFilterFn = (rows, [columnId], filterValue) => {
  const dateTime = new Date(parseISO(filterValue)).getTime();
  return rows.filter(({ values }) => values[columnId] === dateTime);
};

export const DateRangeFilter = memo((props) => <DateRangeInput {...props} />);

export const dateRangeFilterFn = (rows, [columnId], filterValue) => {
  const [start, end] = filterValue.split(' - ') || [];

  const startDate = start ? new Date(start).getTime() : null;
  const endDate = end ? new Date(end).getTime() : null;

  if (!startDate || !endDate) {
    return rows;
  }

  return rows.filter(({ values }) =>
    isWithinInterval(new Date(parseISO(values[columnId])), {
      start: startDate,
      end: endDate
    })
  );
};

export const exactMatchFilterFn = (rows, [columnId], filterValue) => {
  let value = filterValue;

  if (!Number.isNaN(filterValue)) {
    value = Number(filterValue);
  }
  return rows.filter(({ values }) => values[columnId] === value);
};

export const SelectFilter = ({ options, ...props }) => {
  const resolvedOptions = options.map((option) => {
    if (typeof option === 'string') {
      return {
        value: option,
        label: option
      };
    }

    return option;
  });

  return (
    <StyledSelect type='select' {...props}>
      <option value=''>Select...</option>
      {resolvedOptions.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </StyledSelect>
  );
};
