import React from 'react';
import PropTypes from 'prop-types';
import { InfoBlock } from '../StyledComponents';

const LinkBlock = ({ label, name, value, href }) => {
  const showColon = label.substr(-1) !== '?';
  return (
    <InfoBlock key={name}>
      <label id={name}>
        {label}
        {showColon && ':'}
      </label>
      <a href={href} target='_top'>
        {value}
      </a>
    </InfoBlock>
  );
};

LinkBlock.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

export default LinkBlock;
