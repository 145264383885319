import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { resolveLink } from '@utils';
import TableCell from './Cell';

const LinkCell = ({
  typeId,
  cell: { value },
  linkParams,
  openInNewTab = false,
  isPNetLink = false,
  state = null
}) => {
  const href = resolveLink(typeId, linkParams?.id || value, linkParams);

  const options = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {};

  let config = {
    pathname: href
  };

  if (state) {
    config = { ...config, state };
  }

  if (href) {
    return (
      <TableCell>
        {!isPNetLink ? (
          <Link to={config} {...options}>
            {value}
          </Link>
        ) : (
          <a
            href={`${process.env.PNET_URL}/partnernet${href}`}
            {...options}
            target='_top'
          >
            {value}
          </a>
        )}
      </TableCell>
    );
  }

  return <TableCell>{value}</TableCell>;
};

LinkCell.propTypes = {
  typeId: PropTypes.string,
  cell: PropTypes.object,
  linkParams: PropTypes.object,
  openInNewTab: PropTypes.bool,
  isPNetLink: PropTypes.bool,
  state: PropTypes.object
};

export default LinkCell;
