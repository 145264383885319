import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_SUBLINE_ITEM_DETAILS,
  GET_SUBLINE_ITEM_TRANSACTIONS,
  SET_SUBLINE_ITEM_TRANSACTIONS_LOADING,
  GET_HISTORY,
  SET_HISTORY_LOADING,
  SET_HISTORY_ERROR,
  SET_TOAST,
  TOGGLE_PAGE_ERROR,
  RESET_SUBLINE_ITEM,
  GET_ATTACHMENTS
} from '../types';

/* eslint-disable no-unused-vars */

export const getSublineItemDetails =
  (sublineItemId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/inventory/subline-item/${sublineItemId}`);

      if (!res.data) {
        throw new Error('Subline Item does not exist.');
      }

      dispatch({
        type: GET_SUBLINE_ITEM_DETAILS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getSublineItemHistory =
  (sublineItemId) =>
  async (dispatch, _getState, { pnetApi }) => {
    try {
      dispatch({
        type: SET_HISTORY_LOADING,
        payload: true
      });

      const res = await pnetApi.get(
        `/api/logger/subline-item/${sublineItemId}`
      );

      dispatch({
        type: GET_HISTORY,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_HISTORY_ERROR,
        payload: getErrorMessage(e)
      });
    }
  };

export const getSublineItemTransactions =
  (sublineItemId) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: SET_SUBLINE_ITEM_TRANSACTIONS_LOADING,
        payload: true
      });

      const res = await api.get(`/subline-items/${sublineItemId}/transactions`);

      dispatch({
        type: GET_SUBLINE_ITEM_TRANSACTIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetSublineItemDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SUBLINE_ITEM
    });

    dispatch({
      type: GET_ATTACHMENTS,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
