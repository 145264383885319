import React from 'react';
import { FormGroup, FormFeedback, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useField } from 'formik';
import TextInput from './TextInput';
import Select from './Select';
import Checkbox from './Checkbox';
import FormLabel from './FormLabel';
import TextInputWithButtons from './TextInputWithButtons';
import DateInput from './DateInput';
import TimeInput from './TimeInput';
import Textarea from './Textarea';
import PhoneInput from './PhoneInput';
import Switch from './Switch';
import Tooltip from '../Tooltip';
import TextInputWithUnits from './TextInputWithUnits';
import DateRangeInput from './DateRangeInput';

const StyledFormGroup = styled(FormGroup)`
  position: relative;
  ${(props) => props.$flush && { margin: 0 }}
`;

const StyledSpinner = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

// A helper function to remove any props that shouldn't be spread out
// on the form component as it will give a React error
const getValidAttrs = (attrs) => {
  const { initialValue, validator, ...validAttrs } = attrs;
  return validAttrs;
};

const BaseField = ({
  name,
  id,
  label,
  tooltip,
  componentName,
  Component,
  required,
  className,
  fieldRef,
  loading,
  $flush,
  $centered,
  ...rest
}) => {
  const Fields = {
    TextInput,
    Select,
    Checkbox,
    TextInputWithButtons,
    DateInput,
    TimeInput,
    Textarea,
    PhoneInput,
    Switch,
    TextInputWithUnits,
    DateRangeInput
  };
  const Field = Component || Fields[componentName] || TextInput;

  const [field, meta] = useField({ name });

  return (
    <StyledFormGroup
      className={className}
      check={componentName === 'Checkbox' && !$centered}
      $flush={$flush}
    >
      {label && (
        <FormLabel for={id || name} check={componentName === 'Checkbox'}>
          <div>
            {label}
            {required && <span className='text-danger'>*</span>}
          </div>
          {tooltip && (
            <Tooltip
              id={`${name}-tooltip`}
              text={tooltip}
              className='ml-1'
              placement='top'
            />
          )}
        </FormLabel>
      )}
      <Field
        {...field}
        {...getValidAttrs(rest)}
        id={id || name}
        name={name}
        required={required}
        invalid={meta.error && meta.touched}
        innerRef={fieldRef}
        $flush={$flush}
        $centered={$centered}
      />

      {loading && (
        <StyledSpinner>
          <Spinner size='sm' />
        </StyledSpinner>
      )}
      <FormFeedback
        style={{ display: meta.error && meta.touched ? 'block' : undefined }}
      >
        {meta.error}
      </FormFeedback>
    </StyledFormGroup>
  );
};

BaseField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  componentName: PropTypes.string,
  Component: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  $flush: PropTypes.bool,
  $centered: PropTypes.bool,
  fieldRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default BaseField;
