import React from 'react';
import PropTypes from 'prop-types';

export const Address = ({
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  className
}) => {
  return (
    <address className={className}>
      <div>
        {address1} {address2}
      </div>
      <div>{`${city} ${state} ${postalCode} ${country}`}</div>
    </address>
  );
};

Address.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  country: PropTypes.string,
  className: PropTypes.string
};

export default Address;
