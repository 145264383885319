import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostingPeriods } from '@actions';

const usePostingPeriods = (options = {}) => {
  const { includeClosed = false } = options;

  const dispatch = useDispatch();
  const { postingPeriods } = useSelector((state) => state.globals);
  const alreadyRan = useRef();

  useEffect(() => {
    if (postingPeriods.length === 0 && !alreadyRan.current) {
      alreadyRan.current = true;
      dispatch(getPostingPeriods());
    }
  }, [postingPeriods, dispatch]);

  return includeClosed
    ? postingPeriods
    : postingPeriods.filter(({ open }) => open);
};

export default usePostingPeriods;
