import { Label } from 'reactstrap';
import styled from 'styled-components';

const FormLabel = styled(Label)`
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;

export default FormLabel;
