import React from 'react';
import PropTypes from 'prop-types';
import IndeterminateCheckbox from '../IndeterminateCheckbox';
import {
  handleSelectRow,
  handleIndeterminateGroupsChange
} from './utils/rowSelectionHandlers';

const SelectRowCell = ({
  row,
  state: { selectedRows: selected },
  setSelectedRows,
  setIndeterminateGroups,
  rowSelectionDisabled = false
}) => {
  const allItems = row.isGrouped
    ? row.subRows.map(({ values: { id } }) => id)
    : [row.values.id];

  const onIndeterminate = row.isGrouped
    ? (bool) =>
        handleIndeterminateGroupsChange(row.id, bool, setIndeterminateGroups)
    : undefined;

  return (
    <div>
      <IndeterminateCheckbox
        allItems={allItems}
        checkedItems={selected}
        onChange={() => handleSelectRow(row, setSelectedRows)}
        onIndeterminate={onIndeterminate}
        disabled={rowSelectionDisabled}
      />
    </div>
  );
};

SelectRowCell.propTypes = {
  row: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  setIndeterminateGroups: PropTypes.func.isRequired,
  rowSelectionDisabled: PropTypes.bool
};

export default SelectRowCell;
