import produce from 'immer';
import { GET_INVENTORY_ITEMS, UPDATE_INVENTORY_ITEM } from '@actions/types';

export const initialState = {
  items: []
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_INVENTORY_ITEMS: {
      draft.items = action.payload;
      return draft;
    }

    case UPDATE_INVENTORY_ITEM: {
      const { id } = action.payload;
      const idx = draft.items.findIndex((item) => item.id === id);
      draft.items[idx] = action.payload;
      return draft;
    }

    default:
      return draft;
  }
}, initialState);
