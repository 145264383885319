import { getErrorToast, getErrorMessage } from '@utils';
import {
  ADD_LOCATION_SECTION,
  UPDATE_LOCATION_SECTION,
  REMOVE_LOCATION_SECTION,
  ADD_LOCATION_BIN,
  UPDATE_LOCATION_BIN,
  REMOVE_LOCATION_BIN,
  BULK_ADD_ITEMS,
  SET_TOAST
} from '../types';

/* eslint-disable no-unused-vars, import/prefer-default-export */

export const addLocationSection =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/bin-section`, formValues);

      dispatch({
        type: ADD_LOCATION_SECTION,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateLocationSection =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/bin-section`, formValues);

      dispatch({
        type: UPDATE_LOCATION_SECTION,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeLocationSection =
  (sectionId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/bin-section/${sectionId}`);

      dispatch({
        type: REMOVE_LOCATION_SECTION,
        payload: res.data.id
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addLocationBin =
  (warehouseId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/bin`, { ...formValues, warehouseId });

      dispatch({
        type: ADD_LOCATION_BIN,
        payload: { warehouseId, bin: res.data }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateLocationBin =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/bin`, formValues);

      dispatch({
        type: UPDATE_LOCATION_BIN,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeLocationBin =
  (binId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/bin/${binId}`);

      dispatch({
        type: REMOVE_LOCATION_BIN,
        payload: res.data.id
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getLocationBinItems =
  (binId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/bin/${binId}`);

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'locationBin',
          items: res.data.items
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
