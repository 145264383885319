import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_GL_ENTRIES,
  SET_TOAST,
  TOGGLE_LOADING_GL_ENTRIES,
  SET_ERROR_GL_ENTRIES
} from '../types';

/* eslint-disable no-unused-vars */

export const getGLEntries =
  ({ type, documentId }) =>
  async (dispatch, _getState, { pnetApi }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_GL_ENTRIES,
        payload: true
      });

      const res = await pnetApi.get(
        `/api/ledger/ledger-transactions/${type}/${documentId}`
      );
      dispatch({
        type: GET_GL_ENTRIES,
        payload: res.data
      });
    } catch (e) {
      const message = getErrorMessage(e);
      dispatch({
        type: SET_ERROR_GL_ENTRIES,
        payload: message
      });
    }
  };

export const searchGLEntriesByDate =
  ({ startDate, endDate }) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_GL_ENTRIES,
        payload: true
      });

      const res = await api.post(`/ledger/ledger-transaction/dates`, {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString()
      });

      dispatch({
        type: GET_GL_ENTRIES,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    } finally {
      dispatch({
        type: TOGGLE_LOADING_GL_ENTRIES,
        payload: false
      });
    }
  };

export const resetGlEntries = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_GL_ENTRIES,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
