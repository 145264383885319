const mapArrToObj = (arr, key, val) =>
  arr.reduce(
    (acc, cur) => ({
      ...acc,
      [cur[key]]: cur[val]
    }),
    {}
  );

export default mapArrToObj;
