import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Spinner
} from 'reactstrap';
import vars from '@styles/_export.module.scss';
import ActionLink from './ActionLink';

const StyledDropdownToggleDark = styled(DropdownToggle)`
  font-weight: bold;
  padding: 0;
  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    opacity: 0.5;
  }
  &:active {
    opacity: 0.5;
  }
`;

const StyledDropdownToggleLight = styled(StyledDropdownToggleDark)`
  color: ${vars.colorWhite};
  &:hover {
    color: ${vars.colorWhite};
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 0.5;
    background-color: inherit;
    color: inherit;
  }
`;

const ActionLinkGroup = ({
  items = [],
  collapseAt = 2,
  toggleText = 'Options',
  toggleIconClassName,
  onDarkBg = false,
  onDropdownOpen,
  onDropdownClose,
  className = '',
  disabled = false,
  menuProps = {},
  dropdownProps = {}
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anyItemLoading, setAnyItemLoading] = useState(null);

  const ToggleComponent = onDarkBg
    ? StyledDropdownToggleLight
    : StyledDropdownToggleDark;

  useEffect(() => {
    if (dropdownOpen && onDropdownOpen) {
      onDropdownOpen();
    } else if (!dropdownOpen && onDropdownClose) {
      onDropdownClose();
    }
  }, [dropdownOpen, onDropdownOpen, onDropdownClose]);

  useEffect(() => {
    const someLoading = items.some((item) => item.loading);
    setAnyItemLoading(someLoading);
  }, [items, setAnyItemLoading]);

  if (items.length === 0) {
    return null;
  }

  if (items.length >= collapseAt) {
    return (
      <Dropdown
        isOpen={dropdownOpen || anyItemLoading}
        toggle={() => setDropdownOpen((cur) => !cur)}
        className={className}
        {...dropdownProps}
      >
        <ToggleComponent
          caret={!toggleIconClassName}
          color='link'
          disabled={disabled}
        >
          {toggleIconClassName ? (
            <i className={`fa ${toggleIconClassName}`} />
          ) : (
            toggleText
          )}
        </ToggleComponent>

        <DropdownMenu {...menuProps}>
          {items.map(
            (
              {
                text,
                onClick,
                disabled: itemDisabled = false,
                loading = false
              },
              idx
            ) => (
              <StyledDropdownItem
                key={idx}
                onClick={onClick}
                disabled={itemDisabled || anyItemLoading}
              >
                {text}
                {loading && <Spinner size='sm' className='ml-1' />}
              </StyledDropdownItem>
            )
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  return (
    <div className={className}>
      {items.map(({ loading, disabled: itemDisabled, ...rest }, idx) => (
        <ActionLink
          key={idx}
          {...rest}
          onDarkBg={onDarkBg}
          className={idx < items.length ? 'ml-2' : 'ml-0'}
          loading={loading}
          disabled={disabled || itemDisabled || loading}
        />
      ))}
    </div>
  );
};

ActionLinkGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      loading: PropTypes.bool
    })
  ),
  collapseAt: PropTypes.number,
  toggleText: PropTypes.string,
  toggleIconClassName: PropTypes.string,
  onDropdownOpen: PropTypes.func,
  onDropdownClose: PropTypes.func,
  className: PropTypes.string,
  onDarkBg: PropTypes.bool,
  disabled: PropTypes.bool,
  menuProps: PropTypes.object,
  dropdownProps: PropTypes.object
};

export default ActionLinkGroup;
