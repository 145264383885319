import produce from 'immer';
import { castAsNumber } from '@utils';
import {
  ADD_ASSOCIATION,
  UPDATE_ASSOCIATION,
  REMOVE_ASSOCIATION,
  GET_ALPINE_ITEM_INFO,
  RESET_ASSOCIATION
} from '@actions/types';

export const initialState = {
  alpineItemInfo: {},
  data: []
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_ALPINE_ITEM_INFO: {
      draft.alpineItemInfo = action.payload;
      return draft;
    }

    case ADD_ASSOCIATION: {
      draft.data = [...draft.data, action.payload];
      return draft;
    }

    case UPDATE_ASSOCIATION: {
      const { id } = action.payload;
      const idx = draft.data.findIndex((item) => item.id === id);
      draft.data[idx] = action.payload;
      return draft;
    }

    case REMOVE_ASSOCIATION:
      draft.data = draft.data.filter(
        ({ id }) => id !== castAsNumber(action.payload)
      );
      return draft;

    case RESET_ASSOCIATION:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
