import produce from 'immer';
import {
  GET_SUBLINE_ITEM_DETAILS,
  GET_SUBLINE_ITEM_TRANSACTIONS,
  RESET_SUBLINE_ITEM,
  UPDATE_SUBLINE_ITEM
} from '@actions/types';

export const initialState = {
  info: {},
  transactions: [],
  transactionsLoading: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_SUBLINE_ITEM_DETAILS:
    case UPDATE_SUBLINE_ITEM:
      draft.info = action.payload;
      return draft;

    case GET_SUBLINE_ITEM_TRANSACTIONS:
      draft.transactions = action.payload;
      draft.transactionsLoading = false;
      return draft;

    case RESET_SUBLINE_ITEM:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
