import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScheduledShipments } from '@actions';

const useScheduledShipments = (filter = {}) => {
  const dispatch = useDispatch();
  const { scheduledShipments } = useSelector((state) => state.globals);

  useEffect(() => {
    dispatch(getScheduledShipments());
  }, [dispatch]);

  const filters = Object.entries(filter);

  return scheduledShipments.filter((shipment) =>
    filters.every(([key, val]) => shipment[key] === val)
  );
};

export default useScheduledShipments;
