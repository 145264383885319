import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { togglePageError } from '@actions';
import { useToasts } from '@hooks';
import LayoutInner from './LayoutInner';
import NavBar from './NavBar';

/* eslint-disable react-hooks/exhaustive-deps */

const Layout = ({
  children,
  loading = false,
  resetPageError,
  navbarOptions,
  customNavbar
}) => {
  const { pageError } = useSelector((state) => state.notifications);

  const { toast } = useToasts();

  useEffect(() => {
    return () => {
      resetPageError();
      toast.setIsOpen(false);
    };
  }, [resetPageError]);

  return (
    <div className='d-flex flex-column'>
      {customNavbar && customNavbar()}

      {!customNavbar && navbarOptions?.breadcrumbItems?.length && (
        <NavBar
          breadcrumbItems={navbarOptions.breadcrumbItems}
          color={navbarOptions.color}
        />
      )}
      <div className='flex-1'>
        <LayoutInner isLoading={loading} hasError={pageError}>
          {children}
        </LayoutInner>
      </div>
    </div>
  );
};

const actions = (dispatch) => {
  return {
    resetPageError: () => dispatch(togglePageError(false))
  };
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  resetPageError: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  navbarOptions: PropTypes.shape({
    breadcrumbItems: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.string,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      })
    ),
    color: PropTypes.string
  }),
  customNavbar: PropTypes.func
};

export default connect(() => ({}), actions)(Layout);
