/* istanbul ignore file */
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import { hydrate, render } from 'react-dom';
import { Admin } from '@alpinehomeair/alpine-admin';

import App from './App';
import configureStore, {
  setAPI,
  setPnetAPI,
  setInventoryAdjustmentApi
} from './configureStore';
import './index.scss';
import authProvider from './authProvider';
import inMemoryJWT from './utils/inMemoryJWTManager';

const renderMethod = module.hot ? render : hydrate;
const inventoryAdjustmentApi = axios.create({
  withCredentials: true,
  baseURL: process.env.IMS_INVENTORY_ADJUSTMENT_API_URL
});

inventoryAdjustmentApi.interceptors.response.use(
  async (response) => {
    const { status, headers } = response;
    if (status === 202) {
      let pollingResponse = await inventoryAdjustmentApi.get(headers.location);
      while (
        pollingResponse.data.status !== 'Succeeded' &&
        pollingResponse.data.status !== 'Failed'
      ) {
        /* eslint-disable */
        pollingResponse = await inventoryAdjustmentApi.get(headers.location);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        /* eslint-enable */
      }

      if (pollingResponse.data.status === 'Failed') {
        throw new Error('Operation failed!');
      } else {
        return pollingResponse;
      }
    }
    return response;
  },
  (error) => Promise.reject(error)
);

inventoryAdjustmentApi.interceptors.response.use(
  (config) => {
    if (config.data.error) {
      throw new Error(config.data.error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

setInventoryAdjustmentApi(inventoryAdjustmentApi);

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.IMS_API_URL
});

api.interceptors.response.use(
  async (response) => {
    const { status, headers } = response;
    if (status === 202) {
      let pollingResponse = await api.get(headers.location);
      while (
        pollingResponse.data.status !== 'Succeeded' &&
        pollingResponse.data.status !== 'Failed'
      ) {
        /* eslint-disable */
        pollingResponse = await api.get(headers.location);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        /* eslint-enable */
      }

      if (pollingResponse.data.status === 'Failed') {
        throw new Error('Operation failed!');
      } else {
        return pollingResponse;
      }
    }
    return response;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (config) => {
    if (config.data.error) {
      throw new Error(config.data.error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

setAPI(api);

const pnetApi = axios.create({
  withCredentials: true,
  baseURL: process.env.PNET_URL
});

pnetApi.interceptors.response.use(
  (config) => {
    if (config.data.error) {
      throw new Error(config.data.error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

setPnetAPI(pnetApi);

const store = configureStore();

const isHandlerEnabled = (config = {}) => {
  return !('handlerEnabled' in config) || config.handlerEnabled;
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const jwtToken = inMemoryJWT.getToken();
    if (jwtToken && jwtToken !== '') {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer: ${jwtToken}`;
    }
  }

  return request;
};

api.interceptors.request.use((request) => requestHandler(request));
pnetApi.interceptors.request.use((request) => requestHandler(request));

renderMethod(
  <Admin authProvider={authProvider}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Admin>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
