import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import vars from '@styles/_export.module.scss';
import {
  StyledTableRow,
  StyledTableCell,
  StickyTableCell,
  StyledTableCellHeader,
  StickyTableCellHeader
} from '../StyledComponents';

/* eslint-disable react/display-name, react/prop-types */

const StyledTable = styled.table`
  width: 100%;
  position: relative;
  table-layout: inherit;
`;

const StyledWrapper = styled.div`
  overflow: auto;
  padding-bottom: 0;
  border: 1px solid ${vars.colorMediumGray};

  @media print {
    border: 0;
    border-bottom: 1px solid ${vars.colorGray};
  }
`;

const NullRows = styled.div`
  position: sticky;
  left: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  color: ${vars.colorGray};
`;

const ErrorMessage = styled.div`
  position: sticky;
  left: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  color: ${vars.colorAlpineRed};
`;

const TableLayout = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  showFooter,
  prepareRow,
  state,
  editingCell = false,
  rows,
  tableId,
  loading = false,
  error = null
}) => {
  return (
    <StyledWrapper>
      <StyledTable
        {...getTableProps()}
        cellSpacing='0'
        cellPadding='0'
        id={tableId}
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => {
              const { key, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                // Apply the header row props
                <StyledTableRow isThead key={key} {...headerGroupProps}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      const { key: columnKey, ...headerProps } =
                        column.getHeaderProps();
                      const CellWrapper =
                        column.sticky ||
                        column.id === 'row_selection' ||
                        column.id === 'row_options'
                          ? StickyTableCellHeader
                          : StyledTableCellHeader;
                      return (
                        // Apply the header cell props
                        <CellWrapper
                          key={columnKey}
                          {...headerProps}
                          $stickyRight={
                            column.sticky === 'right' ||
                            column.id === 'row_options'
                          }
                          $stickyOffset={column.stickyOffset || 0}
                          id={tableId ? `${tableId}-${column.id}` : column.id}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </CellWrapper>
                      );
                    })
                  }
                </StyledTableRow>
              );
            })
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, isExpanded, ...rowProps } = row.getRowProps();
            const isSelected =
              state?.selectedRows?.includes(row.values.id) ||
              (row.isGrouped &&
                row.subRows.every((subRow) =>
                  state?.selectedRows?.includes(subRow.values.id)
                ));
            return (
              <StyledTableRow
                isTbody
                key={key}
                {...rowProps}
                isSelected={isSelected}
                $isExpanded={row.isGrouped && row.isExpanded}
              >
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();
                  const CellWrapper =
                    cell.column.sticky ||
                    cell.column.id === 'row_selection' ||
                    cell.column.id === 'row_options'
                      ? StickyTableCell
                      : StyledTableCell;
                  return (
                    <CellWrapper
                      key={cellKey}
                      {...cellProps}
                      $editingCell={editingCell === cellKey}
                      $stickyRight={
                        cell.column.sticky === 'right' ||
                        cell.column.id === 'row_options'
                      }
                      $stickyOffset={cell.column.stickyOffset || 0}
                      aria-labelledby={
                        tableId
                          ? `${tableId}-${cell.column.id}`
                          : cell.column.id
                      }
                    >
                      {cell.isAggregated
                        ? cell.render('Aggregated')
                        : cell.render('Cell')}
                    </CellWrapper>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </tbody>
        {showFooter && (
          <tfoot>
            {
              // Loop over the header rows
              footerGroups.map((footerGroup) => {
                const { key, ...footerGroupProps } =
                  footerGroup.getFooterGroupProps();
                return (
                  // Apply the header row props
                  <StyledTableRow isTfoot key={key} {...footerGroupProps}>
                    {
                      // Loop over the headers in each row
                      footerGroup.headers.map((column) => {
                        const { key: columnKey, ...footerProps } =
                          column.getFooterProps();
                        const CellWrapper =
                          column.sticky ||
                          column.id === 'row_selection' ||
                          column.id === 'row_options'
                            ? StickyTableCellHeader
                            : StyledTableCellHeader;
                        return (
                          // Apply the header cell props
                          <CellWrapper
                            key={columnKey}
                            {...footerProps}
                            $stickyRight={
                              column.sticky === 'right' ||
                              column.id === 'row_options'
                            }
                            $stickyOffset={column.stickyOffset || 0}
                          >
                            {
                              // Render the header
                              column.render('Footer')
                            }
                          </CellWrapper>
                        );
                      })
                    }
                  </StyledTableRow>
                );
              })
            }
          </tfoot>
        )}
      </StyledTable>
      {loading && (
        <NullRows>
          <Spinner color='secondary' size='sm' /> Loading
        </NullRows>
      )}
      {!loading && !error && rows.length === 0 && <NullRows>No Rows</NullRows>}
      {!loading && error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledWrapper>
  );
};

TableLayout.propTypes = {
  tableInstace: PropTypes.object,
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  footerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFooter: PropTypes.bool.isRequired,
  prepareRow: PropTypes.func.isRequired,
  editingCell: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

export default TableLayout;
