import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { StyledInput } from '../StyledComponents';

const StyledInputGroup = styled(InputGroup)`
  margin-top: auto;

  .input-group-text {
    font-weight: bold;
    font-size: ${vars.fontSizeSmall};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const PaginationControls = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize: initialPageSize }
}) => {
  const [pageSize, setLocalPageSize] = useState(initialPageSize);
  const inputRef = useRef();

  useEffect(() => {
    setLocalPageSize(initialPageSize);
  }, [initialPageSize]);

  const handleSetPageSize = () => {
    setPageSize(parseInt(pageSize, 10));
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      inputRef.current.blur();
    }
  };

  if (pageCount < 2) {
    return null;
  }

  return (
    <div className='d-flex flex-column align-items-center mt-3'>
      <div className='mb-1'>
        Page {pageIndex + 1} of {Math.max(pageCount, 1)}
      </div>
      <div className='d-flex align-items-center'>
        <Button
          color='primary'
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          <i className='fa fa-chevron-left' />
        </Button>

        <StyledInputGroup className='mx-2'>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>Per Page:</InputGroupText>
          </InputGroupAddon>
          <StyledInput
            innerRef={inputRef}
            value={pageSize}
            onChange={(e) => setLocalPageSize(e.target.value)}
            onBlur={handleSetPageSize}
            onKeyDown={onKeyDown}
            style={{ width: 50 }}
          />
        </StyledInputGroup>

        <Button color='primary' disabled={!canNextPage} onClick={nextPage}>
          <i className='fa fa-chevron-right' />
        </Button>
      </div>
    </div>
  );
};

PaginationControls.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  state: PropTypes.object
};

export default PaginationControls;
