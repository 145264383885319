import React from 'react';
import PropTypes from 'prop-types';
import { format, isDate } from 'date-fns';
import TextInput from './TextInput';

const DateInput = ({ value, min, max, ...props }) => {
  const formatDate = (date) => {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

    if (!date || !isDate(d)) {
      return '';
    }

    return format(d, 'yyyy-MM-dd');
  };

  return (
    <TextInput
      {...props}
      value={value}
      type='date'
      min={formatDate(min)}
      max={formatDate(max)}
      placeholder='yyyy-mm-dd'
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default DateInput;
