import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

const StyledIcon = styled.i`
  margin-top: -2px;
  margin-left: auto;
  color: ${(props) => props.$color};
`;

const Tooltip = ({
  id,
  text,
  placement = 'left',
  className = '',
  color = 'inherit'
}) => {
  return (
    <>
      <StyledIcon
        className={`fa fa-info-circle ${className}`}
        id={id}
        $color={color}
      />

      <UncontrolledTooltip placement={placement} target={id}>
        {text}
      </UncontrolledTooltip>
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
};

export default Tooltip;
