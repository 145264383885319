import produce from 'immer';
import {
  GET_BILL_SUMMARY,
  UPDATE_BILL_SUMMARY,
  GET_BILL_EXPENSES,
  ADD_BILL_EXPENSE,
  UPDATE_BILL_EXPENSE,
  REMOVE_BILL_EXPENSE,
  ALLOCATE_BILL_EXPENSES,
  RESET_BILL
} from '@actions/types';

export const initialState = {
  id: null,
  summary: {},
  expenseTypes: [
    { name: 'Domestic Shipping/Fees/Taxes', code: 'domestic_shipping' },
    { name: 'International Shipping', code: 'international_shipping' },
    { name: 'International Duties', code: 'international_duties' },
    { name: 'Fuel Surcharge', code: 'fuel_surcharge' }
  ],
  allocationMethods: ['Weight', 'Value'],
  expenses: []
};

const isAllocated = (expenses) =>
  expenses.length && expenses.every((expense) => expense.allocated);

export default produce((draft, action) => {
  switch (action.type) {
    case GET_BILL_SUMMARY: {
      draft.id = action.payload.id;
      draft.summary = {
        ...draft.summary,
        ...action.payload
      };
      return draft;
    }

    case GET_BILL_EXPENSES:
    case ALLOCATE_BILL_EXPENSES: {
      draft.expenses = action.payload;
      draft.summary.allocated = isAllocated(action.payload);
      return draft;
    }

    case UPDATE_BILL_SUMMARY: {
      draft.summary = {
        ...draft.summary,
        ...action.payload
      };
      return draft;
    }

    case ADD_BILL_EXPENSE:
      draft.expenses = [...draft.expenses, action.payload];
      draft.summary.allocated = isAllocated(draft.expenses);
      return draft;

    case UPDATE_BILL_EXPENSE: {
      const expense = action.payload;
      const idx = draft.expenses.findIndex(({ id }) => id === expense.id);
      draft.expenses[idx] = expense;
      draft.summary.allocated = isAllocated(draft.expenses);
      return draft;
    }

    case REMOVE_BILL_EXPENSE:
      draft.expenses = draft.expenses.filter(({ id }) => id !== action.payload);
      draft.summary.allocated = isAllocated(draft.expenses);
      return draft;

    case RESET_BILL:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
