import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';

const Thumb = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100px;
  height: 120px;
  margin: 0 0 0.5rem 0.5rem;
  padding: 0.25rem;

  .btn-group {
    opacity: ${(props) => (props.$persistButtons ? 1 : 0)};
  }

  &:hover .btn-group {
    opacity: 1;
  }
`;

const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ddd;
  height: 100px;
`;

const ThumbName = styled.div`
  height: 20px;
  font-size: ${vars.fontSizeSmall};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  width: 100%;
  height: 100%;
  color: ${vars.colorAlpineGray};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  transition: opacity 0.15s ease-in-out;
`;

const getIcon = (extension) => {
  switch (extension) {
    case 'txt':
      return 'fa-file-text';
    case 'mp3':
      return 'fa-microphone';
    case 'pdf':
      return 'fa-file-pdf-o';
    case 'csv':
      return 'fa-table';
    default:
      return 'fa-file';
  }
};

const Thumbnail = ({
  id,
  name,
  src,
  extension,
  handleDownload,
  handleRemove,
  persistButtons = false
}) => {
  return (
    <Thumb key={name} $persistButtons={persistButtons} title={name}>
      <StyledButtonGroup>
        {handleDownload && (
          <Button
            size='sm'
            color='primary'
            onClick={() => handleDownload({ src, name, extension })}
          >
            <span className='fa fa-download' />
          </Button>
        )}
        {handleRemove && (
          <Button
            size='sm'
            color='primary'
            onClick={() => handleRemove(id || name)}
          >
            <span className='fa fa-close' />
          </Button>
        )}
      </StyledButtonGroup>

      <ThumbInner>
        <Icon>
          <span className={`fa ${getIcon(extension)}`} />
        </Icon>
      </ThumbInner>
      <ThumbName>{name}</ThumbName>
    </Thumb>
  );
};

Thumbnail.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  handleDownload: PropTypes.func,
  handleRemove: PropTypes.func,
  persistButtons: PropTypes.bool
};

export default Thumbnail;
