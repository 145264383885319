import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import vars from '@styles/_export.module.scss';
import Thumbnail from '../Thumbnail';

const Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: ${vars.colorAlpineGrayLight};
  border: ${(props) => (props.accepted ? '3px solid' : '1px dashed')};
  border-color: ${(props) =>
    props.accepted ? vars.colorAlpineBlueLight : vars.colorMediumGray2};
  opacity: ${(props) => (props.rejected ? 0.25 : 1)};

  &:active,
  &:focus {
    outline: none;
    border-color: inherit;
  }

  p {
    margin: 0;
  }
`;

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const defaultAccept =
  'text/csv,application/vnd.ms-excel,text/plain,text/txt,image/jpeg,image/jpg,image/gif,image/bmp,image/png,image/tiff,image/tif,application/pdf,audio/mpeg,audio/mp3,application/octet-stream,audio/wav,audio/wave,audio/x-wav,audio/x-wave,audio/vnd.wave';

const FileSelector = ({
  files,
  setFiles,
  maxFiles,
  accept = defaultAccept
}) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      maxFiles: maxFiles || 0,
      onDrop: (acceptedFiles) => {
        setFiles([
          ...files,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        ]);
      }
    });

  const handleRemove = (fileName) =>
    setFiles((cur) => cur.filter(({ name }) => name !== fileName));

  useEffect(
    () => () => {
      // Dropzone library recommends doing this to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section>
      <Dropzone
        accepted={isDragAccept}
        rejected={isDragReject}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>Drag files here, or click to select files</p>
      </Dropzone>
      <ThumbsContainer>
        {files.map((file) => {
          return (
            <Thumbnail
              key={file.name}
              name={file.name}
              extension={file.name.split('.').pop()}
              src={file.preview}
              handleRemove={handleRemove}
              persistButtons
            />
          );
        })}
      </ThumbsContainer>
    </section>
  );
};

FileSelector.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFiles: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number
};

export default FileSelector;
