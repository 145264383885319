/* istanbul ignore file */
import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

let api;
let pnetApi;
let inventoryAdjustmentApi;
let middle;

function setAPI(arg) {
  api = arg;
}

function setInventoryAdjustmentApi(arg) {
  inventoryAdjustmentApi = arg;
}

function setPnetAPI(arg) {
  pnetApi = arg;
}

/* eslint-disable no-undef, no-underscore-dangle, prettier/prettier */
function createMiddleware() {
  if (process.env.NODE_ENV === 'production') {
    middle = [
      // Add other middleware on this line...

      // thunk middleware can also accept an extra argument to be passed to each thunk action
      // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
      thunk.withExtraArgument({ api, pnetApi, inventoryAdjustmentApi })
    ];
  } else {
    middle = [
      // Add other middleware on this line...

      // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
      reduxImmutableStateInvariant(),

      // thunk middleware can also accept an extra argument to be passed to each thunk action
      // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
      thunk.withExtraArgument({ api, pnetApi, inventoryAdjustmentApi })
    ];
  }
  return middle;
}

function configureStoreProd(initialState) {
  createMiddleware();

  return createStore(
    rootReducer,
    { ...initialState },
    compose(applyMiddleware(...middle))
  );
}

function configureStoreDev(initialState) {
  createMiddleware();

  // add support for Redux dev tools
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    { ...initialState },
    composeEnhancers(applyMiddleware(...middle))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const isDev = process.env.NODE_ENV !== 'production';
const configureStore = !isDev ? configureStoreProd : configureStoreDev;

export { setAPI, setPnetAPI, setInventoryAdjustmentApi, createMiddleware };
export default configureStore;
