export const handleSelectParentRow = (row, setSelectedRows) => {
  setSelectedRows((cur) => {
    const subRowIds = row.subRows.map(({ values: { id } }) => id);
    const allSelected = subRowIds.every((rowId) => cur.includes(rowId));

    if (allSelected) {
      return cur.filter((rowId) => !subRowIds.includes(rowId));
    }

    const toAdd = subRowIds.filter((rowId) => !cur.includes(rowId));
    return [...cur, ...toAdd];
  });
};

export const handleSelectSubRow = (row, setSelectedRows) => {
  setSelectedRows((cur) => {
    if (cur.includes(row.values.id)) {
      return cur.filter((rowId) => rowId !== row.values.id);
    }

    return [...cur, row.values.id];
  });
};

export const handleSelectRow = (row, setSelectedRows) => {
  if (row.isGrouped) {
    handleSelectParentRow(row, setSelectedRows);
  } else {
    handleSelectSubRow(row, setSelectedRows);
  }
};

export const handleIndeterminateGroupsChange = (
  id,
  bool,
  setIndeterminateGroups
) => {
  setIndeterminateGroups((cur) => {
    if (cur.includes(id) && !bool) {
      return cur.filter((ids) => !ids.includes(id));
    }

    if (!cur.includes(id) && bool) {
      return [...cur, id];
    }

    return cur;
  });
};
