import React from 'react';
import PropTypes from 'prop-types';
import BaseField from '../BaseField';
import * as stateOptions from './states';

const StateField = ({ formik, label, countryFieldName, ...rest }) => {
  const options = stateOptions[formik.values[countryFieldName]];

  return (
    <BaseField
      {...rest}
      label={label}
      componentName={options ? 'Select' : 'TextInput'}
      options={
        options
          ? [{ value: '', label: 'Select State/Province' }, ...options]
          : null
      }
    />
  );
};

StateField.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  countryFieldName: PropTypes.string.isRequired
};

export default StateField;
