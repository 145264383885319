import { SET_TOAST, SET_CONFIRM, TOGGLE_PAGE_ERROR } from '../types';

export const setToast = (toast) => (dispatch) => {
  dispatch({
    type: SET_TOAST,
    payload: toast
  });
};

export const setConfirm = (confirm) => (dispatch) => {
  dispatch({
    type: SET_CONFIRM,
    payload: confirm
  });
};

export const togglePageError = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_PAGE_ERROR, payload });
};
