const getTableFiltersWithToggles = (stateFilters, toggleNames) => {
  const toggles = [];
  const resolvedToggleNames = Array.isArray(toggleNames)
    ? toggleNames
    : [toggleNames];

  resolvedToggleNames.forEach((name) => {
    toggles.push({
      id: name,
      value: stateFilters.find(({ id }) => id === name)?.value
    });
  });

  const filters = stateFilters.filter(
    (filter) => !toggles.map(({ id }) => id).includes(filter.id)
  );

  return {
    toggles,
    filters
  };
};

export default getTableFiltersWithToggles;
