import { combineReducers } from 'redux';
import globals from './globals/reducer';
import vendor from './vendor/reducer';
import purchaseOrder from './purchaseOrder/reducer';
import transactions from './transactions/reducer';
import itemReceipt from './itemReceipt/reducer';
import bill from './bill/reducer';
import locationTransfer from './locationTransfer/reducer';
import items from './items/reducer';
import attachments from './attachments/reducer';
import itemDetails from './itemDetails/reducer';
import glEntries from './glEntries/reducer';
import alpineItemAssociations from './alpineItemAssociations/reducer';
import inventoryItems from './inventoryItems/reducer';
import sublineItems from './sublineItems/reducer';
import sublineItemDetails from './sublineItemDetails/reducer';
import inventoryAdjustment from './inventoryAdjustment/reducer';
import notifications from './notifications/reducer';
import itemFulfillment from './itemFulfillment/reducer';
import delivery from './delivery/reducer';
import expenses from './expenses/reducer';
import purchaseOrders from './purchaseOrders/reducer';
import history from './history/reducer';

const allReducers = combineReducers({
  globals,
  vendor,
  purchaseOrder,
  transactions,
  itemReceipt,
  bill,
  locationTransfer,
  items,
  attachments,
  itemDetails,
  glEntries,
  alpineItemAssociations,
  inventoryItems,
  sublineItemDetails,
  inventoryAdjustment,
  notifications,
  itemFulfillment,
  delivery,
  expenses,
  purchaseOrders,
  sublineItems,
  history
});

function rootReducer(state, action) {
  return allReducers(state, action);
}

export default rootReducer;
