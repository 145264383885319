import { getErrorToast, getErrorMessage } from '@utils';
import {
  SET_TOAST,
  GET_SUBLINE_ITEMS,
  SET_LOADING_SUBLINE_ITEMS,
  BULK_ADD_ITEMS,
  TOGGLE_LOADING_ITEMS,
  BULK_REMOVE_ITEMS,
  ADD_SHIPMENT,
  UPDATE_SUBLINE_ITEM
} from '../types';

/* eslint-disable no-unused-vars */

export const searchSublineItems =
  (query) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: SET_LOADING_SUBLINE_ITEMS,
        payload: true
      });

      const res = await api.get(
        `/subline-items/?q=${encodeURIComponent(query)}`
      );

      dispatch({
        type: GET_SUBLINE_ITEMS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const updateSublineItemSerialNumber =
  (formValues, sublineItemId) =>
  async (dispatch, _getState, { pnetApi }) => {
    try {
      const res = await pnetApi.put(`/api/subline-items/serial-number`, {
        sublineItemId,
        serialNumber: formValues.serialNumber
      });

      dispatch({
        type: UPDATE_SUBLINE_ITEM,
        payload: { ...formValues, sublineItemId }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const searchSublineItemsForFulfillmentSwap =
  (query) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: SET_LOADING_SUBLINE_ITEMS,
        payload: true
      });

      const res = await api.get(
        `/subline-items/searchForFulfillmentSwap?q=${encodeURIComponent(query)}`
      );

      dispatch({
        type: GET_SUBLINE_ITEMS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };

export const getPurchaseOrdersItems =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: true
      });

      const res = await api.get(`/subline-items/purchase-order`);

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'purchaseOrderItems',
          items: res.data,
          replaceExisting: true
        }
      });

      dispatch({
        type: TOGGLE_LOADING_ITEMS,
        payload: false
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addShipmentAndLinkToSublineItems =
  (formValues, vendorId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/shipment/purchase-order-items/`, {
        ...formValues,
        vendorId
      });

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName: 'purchaseOrderItems',
          ids: res.data.items.map(({ sublineItemId }) => sublineItemId)
        }
      });

      dispatch({
        type: ADD_SHIPMENT,
        payload: res.data.shipment
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const linkShipmentToSublineItems =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.put(`/shipment/purchase-order-items/`, formValues);

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName: 'purchaseOrderItems',
          ids: res.data.items.map(({ sublineItemId }) => sublineItemId)
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
