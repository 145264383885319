import produce from 'immer';
import {
  BULK_ADD_ITEMS,
  BULK_REMOVE_ITEMS,
  BULK_UPDATE_ITEMS,
  AGGREGATE_ITEMS,
  BULK_CLEAR_ITEMS,
  TOGGLE_LOADING_ITEMS
} from '@actions/types';

export const initialState = {
  purchaseOrderItems: [],
  purchaseOrder: [],
  itemReceipt: [],
  bill: [],
  importerBill: [],
  locationTransfer: [],
  aggregateItems: [],
  delivery: [],
  itemFulfillment: [],
  locationBin: [],
  loading: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case BULK_ADD_ITEMS: {
      const { itemsName, items, replaceExisting = true } = action.payload;
      if (replaceExisting) {
        draft[itemsName] = items;
      } else {
        draft[itemsName] = [...draft[itemsName], ...items];
      }

      return draft;
    }

    case BULK_REMOVE_ITEMS: {
      const { itemsName, ids, idKey = 'sublineItemId' } = action.payload;
      draft[itemsName] = draft[itemsName].filter(
        ({ [idKey]: id }) => !ids.includes(id)
      );
      return draft;
    }

    case BULK_UPDATE_ITEMS: {
      const { itemsName, items } = action.payload;

      items.forEach((item) => {
        const idx = draft[itemsName].findIndex(({ id }) => id === item.id);
        draft[itemsName][idx] = item;
      });

      return draft;
    }

    case AGGREGATE_ITEMS: {
      const { itemsName, items } = action.payload;
      draft[itemsName] = [...draft[itemsName], ...items];
      return draft;
    }

    case BULK_CLEAR_ITEMS: {
      const { itemsName } = action.payload;
      draft[itemsName] = [];
      return draft;
    }

    case TOGGLE_LOADING_ITEMS:
      draft.loading = action.payload;
      return draft;

    default:
      return draft;
  }
}, initialState);
