import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import { CollapseIcon } from '../StyledComponents';

const ExpanderCell = ({ row, children }) => {
  const { canExpand, isExpanded, getToggleRowExpandedProps } = row;

  if (canExpand) {
    return (
      <Cell
        {...getToggleRowExpandedProps()}
        className='text-nowrap'
        style={{ paddingLeft: `${row.depth * 1.5}rem` }}
      >
        <CollapseIcon isOpen={isExpanded} />
        {children}
      </Cell>
    );
  }

  return <Cell style={{ paddingLeft: '3rem' }}>{children}</Cell>;
};

ExpanderCell.propTypes = {
  children: PropTypes.node,
  row: PropTypes.object.isRequired
};
export default ExpanderCell;
