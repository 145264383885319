import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';

const Switch = ({ value, ...rest }) => {
  return <CustomInput {...rest} type='switch' value={value} checked={value} />;
};

Switch.propTypes = {
  value: PropTypes.bool
};

export default Switch;
