import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FormGenerator from '../Forms/FormGenerator';

/* eslint-disable react/display-name, react/prop-types */

const EditableCellForm = ({
  name,
  value,
  resolveInitialValue,
  Field,
  validator,
  handleSubmit,
  handleCancel
}) => {
  const formRef = useRef();
  const inputRef = useRef();

  const onBlur = () => {
    handleCancel();
  };

  const onKeyDown = (e) => {
    // Esc
    if (e.keyCode === 27) {
      handleCancel();
    }

    // Enter
    if (e.keyCode === 13) {
      formRef.current.submitForm();
    }

    // Tab
    if (e.keyCode === 9) {
      e.preventDefault();
      formRef.current.submitForm();
    }
  };

  useEffect(() => {
    // Need timeout for focus and text selection to work,
    // likely a race condition with Formik
    setTimeout(() => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.focus();

      if (inputRef.current.type === 'text') {
        inputRef.current.select();
      }
    }, 25);
  }, []);

  const ResolvedField = ({ formik, ...rest }) => (
    <Field
      {...rest}
      fieldRef={inputRef}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      $flush
      loading={formik.isSubmitting}
      formik={formik}
    />
  );

  const resolvedInitialValue =
    resolveInitialValue && typeof value !== 'undefined'
      ? resolveInitialValue(value)
      : value;

  return (
    <FormGenerator
      fields={[
        {
          name,
          initialValue: resolvedInitialValue || '',
          Field: ResolvedField,
          validator
        }
      ]}
      handleSubmit={handleSubmit}
      showButtons={false}
      formRef={formRef}
      disableSubmitOnEnter
    />
  );
};

EditableCellForm.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  resolvedInitialValue: PropTypes.func,
  Field: PropTypes.func.isRequired,
  validator: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default EditableCellForm;
