const getPaymentStatus = (totalCost, totalPaid) => {
  const totalOwed = totalCost - totalPaid;
  let status = 'Unknown';
  if (totalOwed === totalCost) status = 'Not Paid';
  if (totalOwed > 0 && totalPaid > 0) status = 'Partially Paid';
  if (totalOwed === 0) status = 'Fully Paid';
  if (totalOwed < 0) status = 'Overpaid';
  return status;
};

export default getPaymentStatus;
