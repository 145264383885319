import React, { memo } from 'react';
import FormLabel from '../Forms/FormLabel';
import Switch from '../Forms/Switch';
import { ActionLink, ActionLinkGroup } from '../ActionLink';

/* eslint-disable react/display-name, react/prop-types */

export const ClearOption = memo(({ setAllFilters, state, className }) => (
  <ActionLink
    text='Clear Filters'
    onClick={() => setAllFilters([])}
    disabled={state.filters.length === 0}
    className={className}
  />
));

export const ClearOptionWithExtraToggle = memo(
  ({ setAllFilters, state, className, toggleName = 'active' }) => {
    const isToggled =
      state.filters.findIndex(({ id }) => id === toggleName) > -1;

    return (
      <ActionLink
        text='Clear Filters'
        onClick={() =>
          setAllFilters(isToggled ? [{ id: toggleName, value: true }] : [])
        }
        disabled={
          state.filters.length === 0 ||
          (state.filters.length === 1 && isToggled)
        }
        className={className}
      />
    );
  }
);

export const ExpandOption = memo(
  ({ isAllRowsExpanded, toggleAllRowsExpanded, className }) => (
    <ActionLink
      text={isAllRowsExpanded ? 'Collapse Rows' : 'Expand Rows'}
      onClick={() => toggleAllRowsExpanded(!isAllRowsExpanded)}
      className={className}
    />
  )
);

export const SelectedRowsOptions = memo(
  ({ state: { selectedRows = 0 }, actionItems, className }) => (
    <ActionLinkGroup
      toggleText={`${selectedRows.length} Selected`}
      items={actionItems}
      disabled={selectedRows.length === 0}
      className={className}
    />
  )
);

export const ToggleOption = memo(({ state, setFilter, label, toggleName }) => (
  <div className='d-inline-flex ml-auto align-items-center'>
    <FormLabel for='active-vendors' check className='mr-2'>
      {label}
    </FormLabel>
    <Switch
      id='active-vendors'
      value={state.filters.findIndex(({ id }) => id === toggleName) > -1}
      onChange={(e) => setFilter(toggleName, e.target.checked)}
    />
  </div>
));
