const getResolvedCellValue = ({
  cell: { value },
  column: { resolveValue }
}) => {
  if (resolveValue) {
    return resolveValue(value);
  }

  return typeof value === 'boolean' ? `${value}` : value;
};

export default getResolvedCellValue;
