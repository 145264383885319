import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast, ConfirmModal } from '@components';
import ROUTES, { RenderRoutes } from './routes';

import './App.css';

const App = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      window.top.postMessage({ reactLocation: location.pathname }, '*');
    });
  }, [history]);

  return (
    <div className='App'>
      <RenderRoutes routes={ROUTES} />

      <Toast />

      <ConfirmModal />
    </div>
  );
};

export default App;
