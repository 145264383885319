import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_INVENTORY_ITEMS,
  UPDATE_INVENTORY_ITEM,
  SET_TOAST
} from '../types';

/* eslint-disable no-unused-vars */

export const getInventoryItems =
  ({ query, includeSND }) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/inventory/item/search`, {
        query,
        includeSND
      });

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: GET_INVENTORY_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateInventoryItem =
  (itemId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/inventory/item/${itemId}`, formValues);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: UPDATE_INVENTORY_ITEM,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addInventoryItem =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/inventory/item/`, formValues);

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return null;
    }
  };
