import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from './Modal';

const ConfirmModal = ({ size = 'sm', ...rest }) => {
  const {
    confirm: {
      prompt = '',
      isOpen = false,
      proceed,
      cancel,
      buttonLabels = { proceed: 'Ok', cancel: 'Cancel' }
    }
  } = useSelector((state) => state.notifications);

  const buttons = [
    {
      text: buttonLabels.proceed,
      color: 'primary',
      onClick: proceed
    },
    {
      text: buttonLabels.cancel,
      color: 'secondary',
      onClick: cancel
    }
  ];

  const setIsOpen = useCallback(() => {
    if (cancel) {
      cancel();
    }
  }, [cancel]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (proceed && isOpen && e.key === 'Enter') {
        proceed();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [proceed, isOpen]);

  return (
    <Modal
      {...rest}
      heading='Confirm'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttons={buttons}
      size={size}
      data-testid='ConfirmModal'
    >
      <p>{prompt}</p>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  size: PropTypes.string
};

export default ConfirmModal;
