import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '@styles/_export.module.scss';
import { StyledInput, PopoverScreen } from '../StyledComponents';

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled(StyledInput)`
  position: relative;
  z-index: 9998;
`;

const ItemList = styled.ul`
  position: absolute;
  bottom: -5px;
  width: 100%;
  margin: 0;
  padding: 0;
  transform: translateY(100%);
  background-color: ${vars.colorWhite};
  border: 1px solid ${vars.colorLightGray};
  border-radius: 5px;
  overflow: hidden;
  background-clip: padding-box;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.$isOpen ? 'auto' : 'none')};
  z-index: ${(props) => (props.$isOpen ? 9999 : 0)};
  max-height: 50vh;
  overflow: auto;
`;

const ItemWrapper = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid ${vars.colorLightGray};

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${vars.colorLighterGray};
  }

  &:active {
    opacity: 0.5;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const NullItemWrapper = styled.li`
  margin: 0;
  padding: 0.5rem;
  list-style: none;
  text-align: center;
`;

const HeaderWrapper = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 2px solid ${vars.colorLightGray};
`;

const TextInputWithDropdown = ({ items, Item, Header, onCancel, ...rest }) => {
  const [focused, setFocused] = useState(false);

  const handleCancel = useCallback(() => {
    setFocused(false);
    if (onCancel) {
      onCancel();
    }
  }, [onCancel, setFocused]);

  return (
    <Wrapper onFocus={() => setFocused(true)} tabIndex={-1}>
      <Input type='text' {...rest} />
      {focused && <PopoverScreen onClick={handleCancel} role='button' />}
      <ItemList role='menu' $isOpen={focused && items}>
        {Header && (
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
        )}
        {items &&
          items.map((item, idx) => (
            <ItemWrapper key={idx}>
              <Item item={item} />
            </ItemWrapper>
          ))}
        {items && items.length === 0 && (
          <NullItemWrapper>No Results</NullItemWrapper>
        )}
      </ItemList>
    </Wrapper>
  );
};

TextInputWithDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      contents: PropTypes.func,
      onClick: PropTypes.func
    })
  ),
  Item: PropTypes.func.isRequired,
  Header: PropTypes.func,
  onCancel: PropTypes.func
};

export default TextInputWithDropdown;
