import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationSections } from '@actions';

const useLocationSections = (id) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const { locationSections, locationBins } = useSelector(
    (state) => state.globals
  );
  const sections = locationSections.filter(
    ({ warehouseId }) => warehouseId === id
  );
  const bins = locationBins.filter(({ warehouseId }) => warehouseId === id);

  useEffect(() => {
    const getSections = async () => {
      await dispatch(getLocationSections(id));
      setDataLoaded(true);
    };

    if (id && !dataLoaded) {
      getSections();
    }
  }, [id, dispatch, dataLoaded]);

  return { sections, bins, dataLoaded };
};

export default useLocationSections;
