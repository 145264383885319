import produce from 'immer';
import {
  GET_GL_ENTRIES,
  ADD_GL_ENTRY,
  TOGGLE_LOADING_GL_ENTRIES,
  SET_ERROR_GL_ENTRIES,
  REMOVE_GL_ENTRIES
} from '@actions/types';

export const initialState = {
  data: [],
  loading: false,
  error: null
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_GL_ENTRIES:
      draft.data = action.payload;
      draft.loading = false;
      return draft;

    case TOGGLE_LOADING_GL_ENTRIES:
      draft.error = null;
      draft.loading = action.payload;
      return draft;

    case SET_ERROR_GL_ENTRIES:
      draft.error = action.payload;
      draft.loading = false;
      return draft;

    case REMOVE_GL_ENTRIES:
      draft.data = draft.data.filter(
        ({ recordId }) => !action.payload.some((item) => item.id === recordId)
      );
      return draft;

    case ADD_GL_ENTRY:
      draft.data = [...draft.data, action.payload];
      return draft;

    default:
      return draft;
  }
}, initialState);
