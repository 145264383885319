const downloadFile = (uri, fileName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = uri;
  downloadLink.download = fileName;
  downloadLink.target = '_blank';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default downloadFile;
