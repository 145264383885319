import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '@actions';
import { getSuccessToast, getErrorToast } from '@utils';

const useToasts = () => {
  const dispatch = useDispatch();
  const { toast } = useSelector((state) => state.notifications);

  const setIsOpen = (val) => {
    dispatch(
      setToast({
        ...toast,
        isOpen: val
      })
    );
  };

  const handleSetToast = useCallback(
    (...args) => dispatch(setToast(...args)),
    [dispatch]
  );

  return {
    toast: { ...toast, setIsOpen },
    setToast: handleSetToast,
    getSuccessToast,
    getErrorToast
  };
};

export default useToasts;
