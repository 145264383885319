import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import vars from '@styles/_export.module.scss';

const StyledQuill = styled(ReactQuill)`
  > div:first-child {
    background-color: ${vars.colorAlpineGrayLight};
  }
  > div:last-child {
    > div {
      max-height: ${(props) => (props.applyMaxHeight ? '50vh' : 'none')};
    }
  }
`;

const WysiwygBase = ({
  value,
  onChange,
  placeholder,
  applyMaxHeight = true,
  className = ''
}) => {
  return (
    <StyledQuill
      className={className}
      theme='snow'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      applyMaxHeight={applyMaxHeight}
    />
  );
};

WysiwygBase.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  applyMaxHeight: PropTypes.bool,
  className: PropTypes.string
};

export default WysiwygBase;
