import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVendors } from '@actions';

const useVendors = (filter = {}) => {
  const dispatch = useDispatch();
  const { vendors } = useSelector((state) => state.globals);
  const alreadyRan = useRef();

  useEffect(() => {
    if (vendors.length === 0 && !alreadyRan.current) {
      alreadyRan.current = true;
      dispatch(getVendors());
    }
  }, [dispatch, vendors.length]);

  const filters = Object.entries(filter);

  return vendors.filter((vendor) =>
    filters.every(([key, val]) => vendor[key] === val)
  );
};

export default useVendors;
