import { getErrorToast, getErrorMessage, getWarningToast } from '@utils';
import {
  BULK_ADD_ITEMS,
  BULK_UPDATE_ITEMS,
  BULK_REMOVE_ITEMS,
  SET_TOAST,
  REMOVE_GL_ENTRIES,
  UPDATE_ITEM_FULFILLMENT_PROPERTY,
  UPDATE_DELIVERY_PROPERTIES
} from '../types';

const urlMap = {
  purchaseOrderItems: 'purchase-orders',
  purchaseOrder: 'purchase-orders',
  locationTransfer: 'location-transfer',
  itemFulfillment: 'item-fulfillment',
  delivery: 'shipment'
};

export const bulkAddItems =
  (itemsName) =>
  (documentId, qty, values) =>
  async (dispatch, getState, { api }) => {
    if (qty === 0) {
      return;
    }

    try {
      let req;
      if (itemsName === 'purchaseOrder') {
        req = {
          items: [
            {
              qty,
              ...values
            }
          ]
        };
      }
      if (itemsName === 'locationTransfer') {
        const { statusCode, status } = getState().locationTransfer.summary;
        if (statusCode !== 'created') {
          throw new Error(
            `Items cannot be added to the location transfer when it is in ${status} status`
          );
        }
        req = {
          qty,
          warehouseId: getState().locationTransfer.summary.originWarehouseId,
          ...values
        };
      }

      const uri = `/${urlMap[itemsName]}/items/${documentId}`;

      const res = await api.post(uri, req);

      if (!res.data || !res.data.length) {
        throw new Error(
          `Items could not be added to the ${
            itemsName === 'locationTransfer' ? 'Location Transfer' : 'PO'
          }`
        );
      }

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName,
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const bulkRemoveItems =
  (itemsName) =>
  (ids) =>
  async (dispatch, getState, { api }) => {
    try {
      let url = `/${urlMap[itemsName]}/items/`;
      let req = {
        data: ids
      };

      if (itemsName === 'itemFulfillment') {
        const {
          warehouseId,
          poid,
          id: fulfillmentId
        } = getState().itemFulfillment.summary;
        req = {
          data: {
            sublineItems: ids,
            warehouseId,
            fulfillmentId,
            salesOrderNumber: poid
          }
        };

        dispatch({
          type: REMOVE_GL_ENTRIES,
          payload: ids
        });
      }

      if (itemsName === 'delivery') {
        const { shipmentId, typeCode } = getState().delivery.summary;
        url = `/${urlMap[itemsName]}/${shipmentId}/items/`;
        req = {
          data: {
            ids: ids.ids,
            shouldRemoveDelivery: ids.shouldRemoveDelivery,
            shipmentTypeCode: typeCode
          }
        };
      }

      const res = await api.delete(url, req);

      if (!res.data) {
        throw new Error();
      }

      if (res.data?.voidParentRecord) {
        dispatch({
          type: UPDATE_ITEM_FULFILLMENT_PROPERTY,
          payload: {
            key: 'status',
            value: 'Void'
          }
        });
      }

      dispatch({
        type: BULK_REMOVE_ITEMS,
        payload: {
          itemsName,
          ids: res.data.items.map(({ id }) => id),
          idKey: res.data.idKey ?? 'sublineItemId'
        }
      });

      if (!res.data.allItemsRemoved) {
        dispatch({
          type: SET_TOAST,
          payload: getWarningToast({
            body: "Some items couldn't be removed because they have already been received or billed. To proceed, first remove them from related item receipts and bills."
          })
        });
      }

      if (itemsName === 'delivery' && res.data.shipmentRemoved) {
        dispatch({
          type: UPDATE_DELIVERY_PROPERTIES,
          payload: { status: 'Deleted', statusCode: 'deleted' }
        });
      }

      if (itemsName === 'delivery' && !res.data.shipmentRemoved) {
        dispatch({
          type: UPDATE_DELIVERY_PROPERTIES,
          payload: { status: res.data.status, statusCode: res.data.statusCode }
        });
      }
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const bulkUpdateItems =
  (itemsName) =>
  (req) =>
  async (dispatch, getState, { api }) => {
    try {
      let url = '';

      if (itemsName === 'purchaseOrder') {
        const {
          purchaseOrder: {
            summary: { id: purchaseOrderId }
          }
        } = getState();

        url = `/${urlMap[itemsName]}/${purchaseOrderId}/items/`;
      }

      if (itemsName === 'purchaseOrderItems') {
        url = `/${urlMap[itemsName]}/items/`;
      }

      if (itemsName === 'delivery') {
        const { shipmentId } = getState().delivery.summary;

        url = `/${urlMap[itemsName]}/${shipmentId}/items/`;
      }

      const res = await api.put(url, req);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName,
          items: res.data
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateSublineItems =
  (binId, items) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put('/subline-items', { binId, items });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'locationBin',
          items: res.data.items
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };
