import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_UNALLOCATED_EXPENSES,
  SET_TOAST,
  TOGGLE_LOADING_UNALLOCATED_EXPENSES,
  TOGGLE_LOADING_ALLOCATED_EXPENSES,
  GET_ALLOCATED_EXPENSES
} from '../types';

/* eslint-disable no-unused-vars */

export const getUnallocatedExpenses =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_UNALLOCATED_EXPENSES,
        payload: true
      });
      const res = await api.get(`/expenses/unallocated`);

      dispatch({
        type: GET_UNALLOCATED_EXPENSES,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: TOGGLE_LOADING_UNALLOCATED_EXPENSES,
        payload: false
      });
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getAllocatedExpenses =
  ({ startDate, endDate }) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: TOGGLE_LOADING_ALLOCATED_EXPENSES,
        payload: true
      });
      const res = await api.post(`/expenses/allocated`, {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString()
      });

      dispatch({
        type: GET_ALLOCATED_EXPENSES,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: TOGGLE_LOADING_ALLOCATED_EXPENSES,
        payload: false
      });
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetUnallocatedExpenses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_UNALLOCATED_EXPENSES,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};

export const resetAllocatedExpenses = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALLOCATED_EXPENSES,
      payload: []
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
