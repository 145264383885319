import { getErrorToast, getErrorMessage } from '@utils';
import {
  UPDATE_LOCATION_TRANSFER,
  SET_TOAST,
  GET_LOCATION_TRANSFER,
  BULK_ADD_ITEMS,
  BULK_UPDATE_ITEMS,
  ADD_SHIPMENT,
  UPDATE_LOCATION_TRANSFER_STATUS,
  UPDATE_SHIPMENT_STATUS,
  BULK_CLEAR_ITEMS
} from '../types';

// Temp allowing unused vars until server endpoints set up
// so we can see which args should be passed
/* eslint-disable no-unused-vars */

export const addLocationTransfer =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post('/location-transfer', formValues);

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const deleteLocationTransfer =
  (locationTransferId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/location-transfer/${locationTransferId}`);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateLocationTransferStatus =
  (locationTransferId, statusCode) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.put(
        `/location-transfer/${locationTransferId}/status`,
        {
          statusCode
        }
      );

      if (res.data.statusCode === 'void') {
        dispatch({
          type: BULK_CLEAR_ITEMS,
          payload: { itemsName: 'locationTransfer' }
        });
      }

      dispatch({
        type: UPDATE_LOCATION_TRANSFER_STATUS,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const getLocationTransfer =
  (locationTransferId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/location-transfer/${locationTransferId}`);

      dispatch({ type: GET_LOCATION_TRANSFER, payload: res.data.summary });
      dispatch({
        type: BULK_ADD_ITEMS,
        payload: { itemsName: 'locationTransfer', items: res.data.items }
      });
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateLocationTransfer =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const { locationTransferId, ...summary } = formValues;

      const res = await api.put(
        `/location-transfer/${locationTransferId}`,
        summary
      );

      dispatch({
        type: UPDATE_LOCATION_TRANSFER,
        payload: res.data.summary
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const searchLocationTransferProducts =
  (searchTerm) =>
  async (dispatch, getState, { api }) => {
    const { originWarehouseId } = getState().locationTransfer.summary;
    try {
      const res = await api.post(`/inventory/item/search/available`, {
        warehouseId: originWarehouseId,
        query: searchTerm
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const getLocationTransferItemQuantityRange =
  ({ warehouseId, vendorProductId, unitType }) =>
  async (dispatch, getState, { api }) => {
    // This endpoint will need to return min and max values for the item quantity
    // for a particular product/unitType at a particular location.
    const res = await new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          data: {
            min: 2,
            max: 20
          }
        });
      }, 150)
    );

    return res.data;
  };

export const addLocationTransferShipmentAndLinkToItems =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id: locationTransferId, originWarehouseName } =
        getState().locationTransfer.summary;
      const res = await api.post('/shipment/location-transfer-items', {
        ...formValues,
        originLocation: originWarehouseName,
        locationTransferId
      });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'locationTransfer',
          items: res.data.items
        }
      });

      dispatch({
        type: ADD_SHIPMENT,
        payload: res.data.shipment
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const linkLocationTransferShipmentToItems =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id: locationTransferId } = getState().locationTransfer.summary;
      const res = await api.put(`/shipment/location-transfer-items/`, {
        ...formValues,
        locationTransferId
      });

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: BULK_UPDATE_ITEMS,
        payload: {
          itemsName: 'locationTransfer',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeLocationTransferShipmentLink =
  ({ items }) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id: locationTransferId } = getState().locationTransfer.summary;
      const res = await api.delete(`/shipment/location-transfer/`, {
        data: { items, locationTransferId }
      });

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: {
          itemsName: 'locationTransfer',
          items: res.data
        }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const shipLocationTransfer =
  (shipmentId) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id: locationTransferId } = getState().locationTransfer.summary;
      const res = await api.post(
        `/location-transfer/${locationTransferId}/shipment/${shipmentId}/ship-location-transfer`
      );

      dispatch({
        type: UPDATE_LOCATION_TRANSFER_STATUS,
        payload: res.data.locationTransfer
      });

      dispatch({
        type: UPDATE_SHIPMENT_STATUS,
        payload: {
          shipmentId,
          status: res.data.shipment.status,
          statusCode: res.data.shipment.statusCode
        }
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const revertLocationTransferItemFulfillments =
  (sublineItemIds) =>
  async (dispatch, getState, { api }) => {
    try {
      const { id: locationTransferId, originWarehouseId } =
        getState().locationTransfer.summary;

      const res = await api.put(
        `/location-transfer/${locationTransferId}/items/fulfillment`,
        {
          sublineItemIds,
          originWarehouseId
        }
      );

      dispatch({ type: GET_LOCATION_TRANSFER, payload: res.data.summary });
      dispatch({
        type: BULK_ADD_ITEMS,
        payload: { itemsName: 'locationTransfer', items: res.data.items }
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
