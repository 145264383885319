import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { throttle } from 'lodash';
import { Spinner } from 'reactstrap';
import TextInputWithDropdown from './TextInputWithDropdown';

const Wrapper = styled.div`
  position: relative;
`;

const StyledSpinner = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
`;

const SearchInput = ({
  initialTerm = '',
  handleSearch,
  placeholder = 'Type to search...',
  delay = 250,
  ...rest
}) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = useMemo(
    () =>
      throttle(async (e) => {
        const term = e.target.value;
        if (!term) {
          setResults([]);
          return;
        }

        setLoading(true);
        const res = await handleSearch(term);

        setResults(res);
        setLoading(false);
      }, delay),
    [setResults, setLoading, handleSearch, delay]
  );

  return (
    <Wrapper>
      <TextInputWithDropdown
        {...rest}
        defaultValue={initialTerm}
        items={results}
        onChange={onChange}
        placeholder={placeholder}
      />

      {loading && (
        <StyledSpinner>
          <Spinner size='sm' />
        </StyledSpinner>
      )}
    </Wrapper>
  );
};

SearchInput.propTypes = {
  initialTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSearch: PropTypes.func,
  placeholder: PropTypes.string,
  delay: PropTypes.number
};

export default SearchInput;
