import React from 'react';
import styled from 'styled-components';
import { Container, Spinner } from 'reactstrap';

const SpinnerWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loading = () => {
  return (
    <Container>
      <SpinnerWrap>
        <Spinner color='primary' size='lg' />
      </SpinnerWrap>
    </Container>
  );
};

export default Loading;
