export const formatPhone = (number, extension) => {
  const val = `${number}${extension || ''}`;

  const cleaned = `${val}`.replace(/\D/g, '');
  const len = cleaned.length;
  const areaCode = cleaned.substring(0, 3);
  const middle = cleaned.substring(3, 6);
  const last = cleaned.substring(6, 10);
  const ext = cleaned.substring(10);

  if (len > 10) {
    return `(${areaCode}) ${middle} - ${last} x${ext}`;
  }
  if (len > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  }
  if (len > 3) {
    return `(${areaCode}) ${middle}`;
  }
  if (len > 0) {
    return `(${areaCode})`;
  }
  return '';
};

export const unformatPhone = (val) => {
  const [number, extension = null] = val.split('x');

  return {
    phone: number.replace(/[- )(]/g, ''),
    extension
  };
};
