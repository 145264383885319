import {
  getErrorToast,
  getSuccessToast,
  getErrorMessage,
  generateCSV,
  downloadFile
} from '@utils';
import {
  SET_TOAST,
  GET_DELIVERY,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_PROPERTIES,
  AGGREGATE_ITEMS,
  BULK_ADD_ITEMS
} from '../types';

export const getDeliveryHeader =
  (shipmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/shipment/${shipmentId}/header`);

      dispatch({ type: GET_DELIVERY, payload: res.data });
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const getDeliveryItems =
  (shipmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/shipment/${shipmentId}/items`);

      dispatch({
        type: BULK_ADD_ITEMS,
        payload: { itemsName: 'delivery', items: res.data }
      });
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const getAggregateDeliveryItems =
  (shipmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/shipment/${shipmentId}/aggregateItems`);
      dispatch({
        type: AGGREGATE_ITEMS,
        payload: { itemsName: 'aggregateItems', items: res.data }
      });
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const updateDelivery =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const { shipmentId, ...summary } = formValues;

      const res = await api.put(`/shipment/${shipmentId}/header`, summary);

      dispatch({
        type: UPDATE_DELIVERY,
        payload: res.data
      });

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const deleteShipment =
  (shipmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.delete(`/shipment/${shipmentId}`);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };
export const createShipment =
  (formValues) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/shipment`, formValues);
      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
      return null;
    }
  };

export const bulkUpdateSerialIds =
  (formValues, shipmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const res = await api.post(
        `/shipment/${shipmentId}/bulkUpdateSerialIds`,
        formValues,
        config
      );
      if (!res.data.validData) {
        const columns = ['serialNumber', 'sublineItemId', 'err'];
        const data = res.data.processedData.map((rec) =>
          columns.map((col) => rec[col])
        );
        dispatch({
          type: SET_TOAST,
          payload: getErrorToast({
            body: 'Failed updating serial numbers. Downloading error report'
          })
        });
        const uri = generateCSV([columns, ...data]);
        downloadFile(uri, `ErrorReport.csv`);
      }
      dispatch({
        type: SET_TOAST,
        payload: getSuccessToast({
          body: 'Successfully updated serial numbers'
        })
      });
      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

export const revertUnloadDelivery =
  (shipmentId) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(
        `/shipment/${shipmentId}/revertunloadtoreceiving`
      );

      dispatch({
        type: UPDATE_DELIVERY_PROPERTIES,
        payload: res.data
      });

      dispatch({
        type: SET_TOAST,
        payload: getSuccessToast({
          body: 'Delivery reverted from "In Inspection" to "In Transit"'
        })
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
