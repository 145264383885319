import React from 'react';
import PropTypes from 'prop-types';
import { StyledSelect } from '../StyledComponents';

const Select = ({ options, children = null, ...props }) => {
  return (
    <StyledSelect {...props} tag='select'>
      {/* If options prop passed, use to render select options */}
      {options?.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}

      {/* Also render children in case options passed that way */}
      {children}
    </StyledSelect>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    })
  ),
  children: PropTypes.node
};

export default Select;
