import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMountedRef } from '@hooks';
import Cell from './Cell';
import EditableCellValue from './EditableCellValue';
import EditableCellForm from './EditableCellForm';

const EditableCell = ({
  value,
  data,
  column: {
    id,
    Field,
    validator,
    resolveFormValues,
    resolveValue,
    resolveInitialValue
  },
  cell: {
    row: { values },
    getCellProps
  },
  handleUpdateData,
  setEditingCell,
  setSkipPageReset
}) => {
  const [isFocused, setFocused] = useState(false);
  const { key } = getCellProps();
  const isMounted = useMountedRef();

  const handleSubmit = async (formValues) => {
    const newValue = formValues[id];
    const bothFalsy = !value && !newValue;
    if (newValue === value || bothFalsy) {
      setFocused(false);
      return;
    }

    const resolvedValues = resolveFormValues
      ? { [id]: resolveFormValues(formValues[id]) }
      : formValues;

    const item = data.find(({ id: dataId }) => dataId === values.id);

    await handleUpdateData({
      // If no matching data, then this is an aggregate row, so just use
      // the row values in lieu of the raw data object
      item: item || values,
      values: resolvedValues
    });

    if (isMounted.current) {
      setFocused(false);
    }
  };

  useEffect(() => {
    if (isFocused) {
      setSkipPageReset(true);
      setEditingCell(key);
    } else {
      setSkipPageReset(false);
      setEditingCell(null);
    }
  }, [isFocused, setSkipPageReset, setEditingCell, key]);

  return (
    <Cell
      onClick={() => setFocused(true)}
      onFocus={() => setFocused(true)}
      tabIndex={-1}
      data-testid={`editable-cell-${id}`}
    >
      {isFocused ? (
        <EditableCellForm
          name={id}
          value={value}
          resolveInitialValue={resolveInitialValue}
          Field={Field}
          validator={validator}
          handleSubmit={handleSubmit}
          handleCancel={() => setFocused(false)}
        />
      ) : (
        <EditableCellValue value={value} resolveValue={resolveValue} />
      )}
    </Cell>
  );
};

EditableCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  column: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired,
  handleUpdateData: PropTypes.func.isRequired,
  setEditingCell: PropTypes.func.isRequired,
  setSkipPageReset: PropTypes.func.isRequired
};

export default EditableCell;
