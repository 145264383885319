import * as Yup from 'yup';
import { format, isDate, startOfTomorrow } from 'date-fns';

export const alphaNumeric = (fieldName, required) => {
  const validator = Yup.string()
    .typeError(`${fieldName} must be string`)
    .matches(/[A-Za-z0-9]/)
    .typeError(`${fieldName} should be alpha numeric`);
  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const positiveNumber = (fieldName, required) => {
  const validator = Yup.number()
    .typeError(`${fieldName} must be a number`)
    .positive(`${fieldName} must be a positive number`);
  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const positiveInteger = (fieldName, required) => {
  const validator = positiveNumber(fieldName).integer(
    `${fieldName} must be an integer`
  );
  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const nonNegativeNumber = (fieldName, isInteger, required) => {
  let validator = Yup.number()
    .typeError(`${fieldName} must be a number`)
    .min(0, `${fieldName} cannot be negative`);

  if (isInteger) {
    validator = validator.integer(`${fieldName} must be an integer`);
  }

  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const nonNegativeInteger = (fieldName, required) => {
  return nonNegativeNumber(fieldName, true, required);
};

export const rangeNumber = (fieldName, min, max, required, isInteger) => {
  let validator = Yup.number().typeError(`${fieldName} must be a number`);

  if (isInteger) {
    validator = validator.integer(`${fieldName} must be a positive integer`);
  }

  validator = validator
    .min(min, `${fieldName} must be at least ${min}`)
    .max(max, `${fieldName} can't be more than ${max}`);
  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const rangeInteger = (fieldName, min, max, required) =>
  rangeNumber(fieldName, min, max, required, true);

export const email = (fieldName, required) => {
  const validator = Yup.string().email(`${fieldName} must be an email address`);
  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const date = (fieldName, min, max, required) => {
  const dMin = min ? new Date(min) : null;
  const dMax = max ? new Date(max) : null;

  let validator = Yup.date(`${fieldName} must be a valid date`);
  if (isDate(dMin)) {
    validator = validator.min(
      dMin,
      `Earliest date can be ${format(dMin, 'MM-dd-yyyy')}`
    );
  }
  if (isDate(dMax)) {
    validator = validator.max(
      dMax,
      `Latest date can be ${format(dMax, 'MM-dd-yyyy')}`
    );
  }

  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const futureDate = (fieldName, required) => {
  return date(fieldName, startOfTomorrow(), null, required);
};

export const postingPeriodDate = (fieldName, postingPeriods, required) => {
  const dMin =
    [...postingPeriods]
      .map(({ startDate }) => startDate)
      .sort((a, b) => a - b)[0] || new Date();
  const dMax =
    [...postingPeriods]
      .map(({ endDate }) => endDate)
      .sort((a, b) => b - a)[0] || new Date();

  const validator = Yup.date(`${fieldName} must be a valid date`)
    .min(dMin, `Date must be within open posting period`)
    .max(dMax, `Date must be within open posting period`);

  return required ? validator.required(`${fieldName} is required`) : validator;
};

export const phoneNumber = (fieldName, required) => {
  // With formatting, complete phone numbers will be 16 chars long
  // This could be improved using regex, although the DateInput field
  // is sanitizing the value as it's entered.
  const validator = Yup.string().min(16, 'Must be a complete phone number');
  return required ? validator.required(`${fieldName} is required`) : validator;
};
