import React from 'react';
import { NavbarText } from 'reactstrap';
import PropTypes from 'prop-types';
import { Breadcrumbs, StyledNavbar } from '@components';

const NavBar = ({ breadcrumbItems, color = 'dark' }) => {
  return (
    <StyledNavbar color={color} dark>
      <NavbarText>
        <Breadcrumbs items={breadcrumbItems} />
      </NavbarText>
    </StyledNavbar>
  );
};

NavBar.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
  ),
  color: PropTypes.string
};

export default NavBar;
