import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import { getResolvedCellValue } from './utils';

const ValueCell = ({ className, ...tableInstance }) => {
  const value = getResolvedCellValue(tableInstance);
  return (
    <Cell className={className}>
      <span>{value}</span>
    </Cell>
  );
};

ValueCell.propTypes = {
  cell: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default ValueCell;
