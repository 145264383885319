import React, { useState } from 'react';
import { FormGroup } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useConfirm } from '@hooks';
import vars from '@styles/_export.module.scss';
import InlineTextInputForm from './InlineTextInputForm';
import { ActionLink, ActionLinkGroup } from '../ActionLink';

const FormGroupHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${vars.colorMediumGray};
  padding-bottom: 0.25rem;

  label {
    font-weight: bold;
    margin-bottom: 0;
  }
`;

const Item = styled.li`
  display: ${(props) => (props.isEditing ? 'block' : 'flex')};
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${vars.colorMediumGray};
`;

const EditableList = ({
  name,
  label,
  inputVariant = 'TextInput',
  validator,
  items,
  handleSave,
  handleRemove,
  hiddenFields,
  className = ''
}) => {
  const [isAddingNew, setAddingNew] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const { isConfirmed } = useConfirm();

  const field = {
    name,
    componentName: inputVariant,
    validator,
    required: true,
    className: editingId ? 'mb-0' : '',
    initialValue: editingId
      ? items.find(({ id }) => editingId === id)?.text
      : ''
  };

  const buttons = [
    {
      text: 'Save',
      color: 'primary',
      type: 'submit'
    },
    {
      text: 'Cancel',
      color: 'secondary',
      onClick: editingId ? () => setEditingId(null) : () => setAddingNew(false)
    }
  ];

  const handleSubmit = async (...args) => {
    await handleSave(...args);
    setAddingNew(false);
    setEditingId(null);
  };

  const getActionItems = (id, text) => {
    const actionItems = [
      {
        text: 'Edit',
        iconClassName: 'fa-pencil',
        onClick: () => {
          setAddingNew(false);
          setEditingId(id);
        }
      }
    ];

    if (handleRemove) {
      actionItems.push({
        text: 'Remove',
        iconClassName: 'fa-trash',
        onClick: async () => {
          const confirmed = await isConfirmed(`Delete "${text}"?`);
          if (confirmed) {
            await handleRemove(id);
          }
        }
      });
    }

    return actionItems;
  };

  return (
    <FormGroup className={className}>
      <FormGroupHeader>
        <label>{label}</label>
        <ActionLink
          text='New'
          iconClassName='fa-plus'
          className='ml-auto'
          onClick={() => {
            setEditingId(null);
            setAddingNew(true);
          }}
          disabled={isAddingNew}
        />
      </FormGroupHeader>

      <ul className='pl-0 mb-2'>
        {items.map(({ id, text }) => (
          <Item key={id} isEditing={editingId === id}>
            {editingId === id ? (
              <InlineTextInputForm
                field={field}
                handleSubmit={handleSubmit}
                buttons={buttons}
                hiddenFields={[
                  ...hiddenFields,
                  { name: 'id', initialValue: id }
                ]}
              />
            ) : (
              <>
                <span>{text}</span>
                <ActionLinkGroup
                  className='ml-auto'
                  items={getActionItems(id, text)}
                />
              </>
            )}
          </Item>
        ))}
      </ul>

      {isAddingNew && (
        <InlineTextInputForm
          field={field}
          handleSubmit={handleSubmit}
          buttons={buttons}
          hiddenFields={[...hiddenFields, { name: 'id', initialValue: '' }]}
        />
      )}
    </FormGroup>
  );
};

EditableList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputVariant: PropTypes.string,
  validator: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  hiddenFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      validator: PropTypes.object
    })
  ),
  handleSave: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  className: PropTypes.string
};

export default EditableList;
