import produce from 'immer';
import {
  GET_ITEM_DETAILS,
  UPDATE_ITEM_INFO,
  GET_ITEM_SUPPORTING_DATA,
  GET_ITEM_VENDORS,
  REMOVE_ITEM_VENDOR,
  ADD_ITEM_VENDOR,
  GET_ITEM_LOCATIONS,
  GET_ITEM_HISTORY,
  GET_ITEM_SUBLINE_ITEMS,
  RESET_ITEM_DETAILS,
  GET_ITEM_TRANSACTIONS,
  SET_ITEM_TRANSACTIONS_LOADING,
  SET_ITEM_TRANSACTIONS_ERROR
} from '@actions/types';

export const initialState = {
  info: {},
  itemTypes: [],
  unitTypes: [],
  manufacturers: [],
  purchasingStatuses: [],
  freightClasses: [],
  vendors: [],
  itemVendors: [],
  netsuiteVendors: [],
  itemLocations: [],
  sublineItems: [],
  naftaCerts: [
    'No NAFTA Certificate / Use Alpine Product',
    'NAFTA-ResearchProducts.gif',
    'NAFTA-Goodman.gif'
  ],
  history: [],
  transactions: [],
  transactionsLoading: false,
  transactionsError: null
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_ITEM_DETAILS:
    case UPDATE_ITEM_INFO: {
      draft.info = action.payload;
      return draft;
    }

    case GET_ITEM_SUPPORTING_DATA: {
      const {
        itemTypes,
        unitTypes,
        manufacturers,
        purchasingStatuses,
        freightClasses,
        vendors,
        netsuiteVendors
      } = action.payload;

      draft.itemTypes = itemTypes;
      draft.unitTypes = unitTypes;
      draft.manufacturers = manufacturers;
      draft.purchasingStatuses = purchasingStatuses;
      draft.freightClasses = freightClasses;
      draft.vendors = vendors;
      draft.netsuiteVendors = netsuiteVendors;

      return draft;
    }

    case GET_ITEM_VENDORS:
      draft.itemVendors = action.payload;
      return draft;

    case ADD_ITEM_VENDOR: {
      draft.itemVendors = [...draft.itemVendors, action.payload];
      return draft;
    }

    case REMOVE_ITEM_VENDOR:
      draft.itemVendors = draft.itemVendors.filter(
        ({ id }) => id !== action.payload
      );
      return draft;

    case GET_ITEM_LOCATIONS:
      draft.itemLocations = [
        ...action.payload.warehouses,
        ...action.payload.dropShippers
      ];
      return draft;

    case GET_ITEM_HISTORY:
      draft.history = action.payload;
      return draft;

    case GET_ITEM_SUBLINE_ITEMS:
      draft.sublineItems = action.payload;
      return draft;

    case GET_ITEM_TRANSACTIONS:
      draft.transactions = action.payload;
      draft.transactionsLoading = false;
      return draft;

    case SET_ITEM_TRANSACTIONS_LOADING:
      draft.transactionsError = null;
      draft.transactionsLoading = action.payload;
      return draft;

    case SET_ITEM_TRANSACTIONS_ERROR:
      draft.transactionsError = action.payload;
      draft.transactionsLoading = false;
      return draft;

    case RESET_ITEM_DETAILS:
      return { ...initialState };

    default:
      return draft;
  }
}, initialState);
