import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_ITEM_DETAILS,
  SET_TOAST,
  UPDATE_ITEM_INFO,
  GET_ITEM_SUPPORTING_DATA,
  GET_ITEM_VENDORS,
  ADD_ITEM_VENDOR,
  REMOVE_ITEM_VENDOR,
  GET_ITEM_LOCATIONS,
  GET_ITEM_HISTORY,
  TOGGLE_PAGE_ERROR,
  RESET_ITEM_DETAILS,
  GET_ITEM_SUBLINE_ITEMS,
  GET_ITEM_TRANSACTIONS,
  SET_ITEM_TRANSACTIONS_LOADING,
  SET_ITEM_TRANSACTIONS_ERROR
} from '../types';
import * as fixtures from './fixtures';

/* eslint-disable no-unused-vars */

export const getItemDetails =
  (itemId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/inventory/item/${itemId}`);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: GET_ITEM_DETAILS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getItemSupportingData =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/inventory/item/new`);

      if (!res.data) {
        throw new Error();
      }

      dispatch({
        type: GET_ITEM_SUPPORTING_DATA,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateItemInfo =
  (itemId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/inventory/item/${itemId}`, formValues);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: UPDATE_ITEM_INFO,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getItemVendors =
  (itemId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/inventory/sublistitem/${itemId}`);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: GET_ITEM_VENDORS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addItemVendor =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/inventory/sublistitem/`, formValues);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: ADD_ITEM_VENDOR,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateItemVendor =
  (sublistItemId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/inventory/sublistitem/${sublistItemId}`,
        formValues
      );

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: GET_ITEM_VENDORS,
        payload: res.data.vendorSublistItems
      });
      dispatch({
        type: GET_ITEM_DETAILS,
        payload: res.data.inventoryItem
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeItemVendor =
  (sublistItemId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/inventory/sublistitem/${sublistItemId}`);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: REMOVE_ITEM_VENDOR,
        payload: res.data.id
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getItemLocations =
  (itemId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/inventory/item/${itemId}/stock`);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: GET_ITEM_LOCATIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getItemHistory =
  (itemId) =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch({
        type: GET_ITEM_HISTORY,
        payload: fixtures.history
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const resetItemDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ITEM_DETAILS
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};

export const getItemSublineItems =
  (itemId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/inventory/item/${itemId}/subline-items`);

      dispatch({
        type: GET_ITEM_SUBLINE_ITEMS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getItemTransactions =
  (itemId) =>
  async (dispatch, _getState, { api }) => {
    try {
      dispatch({
        type: SET_ITEM_TRANSACTIONS_LOADING,
        payload: true
      });
      const res = await api.get(`/inventory/item/${itemId}/transactions`);

      dispatch({
        type: GET_ITEM_TRANSACTIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_ITEM_TRANSACTIONS_ERROR,
        payload: getErrorMessage(e)
      });
    }
  };
