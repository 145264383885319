import React from 'react';
import PropTypes from 'prop-types';
import Base from './WysiwygBase';
import AutoSaver from './WysiwygAutoSaver';

const Wysiwyg = ({ variant = 'base', ...rest }) => {
  switch (variant) {
    case 'base':
      return <Base {...rest} />;

    case 'autosave':
      return <AutoSaver {...rest} />;

    default:
      return <Base {...rest} />;
  }
};

Wysiwyg.propTypes = {
  variant: PropTypes.oneOf(['base', 'autosave'])
};

export default Wysiwyg;
