import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_ATTACHMENTS,
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  SET_TOAST
} from '../types';

/* eslint-disable no-unused-vars */

export const getAttachments =
  (recordType, recordId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/attachments/${recordType}/${recordId}`);

      dispatch({
        type: GET_ATTACHMENTS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addAttachments =
  (files, recordType, recordId) =>
  async (dispatch, getState, { api }) => {
    try {
      const form = new FormData();
      files.forEach((file) => {
        form.append('upload', file);
      });

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const res = await api.post(
        `/attachments/${recordType}/${recordId}`,
        form,
        config
      );

      dispatch({
        type: ADD_ATTACHMENTS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeAttachment =
  (id) =>
  async (dispatch, getState, { api }) => {
    try {
      await api.delete(`/attachments/${id}`);

      dispatch({
        type: REMOVE_ATTACHMENT,
        payload: id
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
