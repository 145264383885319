import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMountedRef } from '@hooks';
import SearchInput from './Forms/SearchInput';
import BaseField from './Forms/BaseField';
import TextInput from './Forms/TextInput';

const SearchSelector = ({
  fieldName,
  handleSearch,
  initialValue = '',
  resolveValue = '',
  Item,
  Header
}) => {
  const [isActive, setActive] = useState(false);
  const searchInputRef = useRef();
  const isMounted = useMountedRef();

  useEffect(() => {
    if (isActive) {
      searchInputRef.current.focus();
    }
  }, [isActive]);

  const handleBlur = () => {
    // Need timeout to allow for clicking items
    // in the dropdown
    setTimeout(() => {
      if (isMounted.current) {
        setActive(false);
      }
    }, 300);
  };

  if (!isActive) {
    return (
      <>
        <BaseField
          id={`${fieldName}-selector`}
          name={`${fieldName}-selector`}
          value={resolveValue ? resolveValue(initialValue) : initialValue}
          placeholder='Type to search...'
          onFocus={() => setActive(true)}
          className='mb-0'
        />

        {/* Include extra field with hidden input to store un-resolved value.
        In formik v3, we may be able to handle this without a hidden field
        https://github.com/formium/formik/issues/1525 */}
        <BaseField
          id={fieldName}
          name={fieldName}
          Component={(props) => (
            <TextInput {...props} style={{ display: 'none' }} />
          )}
        />
      </>
    );
  }

  return (
    <SearchInput
      handleSearch={handleSearch}
      onBlur={handleBlur}
      innerRef={searchInputRef}
      initialTerm={resolveValue ? resolveValue(initialValue) : initialValue}
      Header={Header}
      Item={({ item, onClick }) => (
        <Item
          item={item}
          onClick={() => {
            if (onClick) {
              onClick(item);
            }
          }}
        />
      )}
      className='mb-3'
    />
  );
};

SearchSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  resolveValue: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  Item: PropTypes.func.isRequired,
  Header: PropTypes.func
};

export default SearchSelector;
