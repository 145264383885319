import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  display: block;
  margin: 0 auto;
`;

const IndeterminateCheckbox = ({
  allItems,
  checkedItems,
  onChange,
  onIndeterminate,
  ...rest
}) => {
  const checkboxRef = useRef();

  const checked =
    allItems.length > 0 &&
    allItems.every((item) => checkedItems.includes(item));

  const indeterminate =
    !checked && allItems.some((item) => checkedItems.includes(item));

  useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
    if (onIndeterminate) {
      onIndeterminate(indeterminate);
    }
  }, [indeterminate, onIndeterminate]);

  return (
    <StyledCheckbox
      ref={checkboxRef}
      type='checkbox'
      checked={checked}
      onChange={onChange}
      {...rest}
    />
  );
};

IndeterminateCheckbox.propTypes = {
  allItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  checkedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onChange: PropTypes.func,
  onIndeterminate: PropTypes.func
};
export default IndeterminateCheckbox;
