import inMemoryJWT from './utils/inMemoryJWTManager';

const authProvider = {
  // authentication
  login: () => {
    return Promise.resolve();
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      inMemoryJWT.eraseToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      return inMemoryJWT.getToken() ? Promise.resolve : Promise.reject();
    });
  },
  logout: () => {
    inMemoryJWT.eraseToken();
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

export default authProvider;
