import produce from 'immer';
import {
  GET_ITEM_FULFILLMENT,
  UPDATE_ITEM_FULFILLMENT_PROPERTY
} from '@actions/types';

export const initialState = {
  summary: {
    id: null,
    created: '',
    createdBy: '',
    customer: '',
    poNumericId: null,
    poid: '',
    postingPeriod: '',
    status: '',
    type: '',
    warehouse: '',
    warehouseId: null
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_ITEM_FULFILLMENT: {
      draft.summary = action.payload;
      return draft;
    }
    case UPDATE_ITEM_FULFILLMENT_PROPERTY: {
      draft.summary[action.payload.key] = action.payload.value;
      return draft;
    }

    default:
      return draft;
  }
}, initialState);
