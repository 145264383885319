import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import NotFound from './NotFound';
import Loading from './Loading';

const LayoutInner = ({ isLoading, hasError, children }) => {
  if (hasError) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container fluid className='pb-4'>
      {children}
    </Container>
  );
};

LayoutInner.propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};

export default LayoutInner;
