import React, { useEffect, useRef } from 'react';
import { useToasts } from '@hooks';
import ToastLayout from './ToastLayout';

const Toast = () => {
  const {
    toast: { isOpen, setIsOpen, timeout = 4000, ...rest }
  } = useToasts();

  const toastTimeout = useRef();

  useEffect(() => {
    if (toastTimeout.current) {
      clearTimeout(toastTimeout.current);
    }

    if (isOpen && timeout > 0) {
      toastTimeout.current = setTimeout(() => {
        setIsOpen(false);
      }, timeout);
    }
  }, [isOpen, setIsOpen, timeout]);

  return <ToastLayout isOpen={isOpen} setIsOpen={setIsOpen} {...rest} />;
};

export default Toast;
