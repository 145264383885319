import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const BaseForm = ({
  fields,
  renderForm: RenderForm,
  handleSubmit,
  formRef,
  wrapper: Wrapper = Form,
  disableSubmitOnEnter = false,
  className,
  ...rest
}) => {
  const initialValues = fields.reduce((values, field) => {
    const { name, initialValue } = field;
    return { ...values, [name]: initialValue };
  }, {});

  const validationSchema = Yup.object(
    fields.reduce((schema, field) => {
      const { name, validator } = field;
      return { ...schema, [name]: validator };
    }, {})
  );

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }, []);

  return (
    <Formik
      {...rest}
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {(formik) => (
        <Wrapper
          noValidate
          onKeyDown={disableSubmitOnEnter ? onKeyDown : undefined}
          className={className}
        >
          <RenderForm {...formik} />
        </Wrapper>
      )}
    </Formik>
  );
};

BaseForm.propTypes = {
  tag: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired,
      validator: PropTypes.object
    })
  ),
  handleSubmit: PropTypes.func,
  renderForm: PropTypes.func,
  formRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  wrapper: PropTypes.func,
  disableSubmitOnEnter: PropTypes.bool,
  className: PropTypes.string
};

export default BaseForm;
