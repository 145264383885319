import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import { Popover, PopoverBody } from 'reactstrap';
import { formatDate } from '@utils';
import { PopoverScreen } from '../StyledComponents';
import TextInput from './TextInput';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangeInput = ({
  name,
  value = '',
  onChange,
  disabled = false,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);
  const [range, setRange] = useState([]);
  const target = useRef();

  const handleSelection = ({ selection }) => {
    const newRange = [selection.startDate, selection.endDate];
    setRange(newRange);

    const val = newRange.map((date) => formatDate(date)).join(' - ');
    onChange({ target: { name, value: val } });
  };

  useEffect(() => {
    if (!value && range.length > 0) {
      setRange([]);
    }
  }, [range, value]);

  return (
    <>
      <TextInput
        {...props}
        name={name}
        innerRef={target}
        value={value}
        onFocus={() => setFocused(true)}
        onChange={onChange}
        readOnly
        disabled={disabled}
        $disableReadOnlyStyle={!disabled}
      />
      {target.current && (
        <Popover
          placement='auto'
          isOpen={isFocused}
          target={target.current}
          popperClassName='mw-100'
          hideArrow
        >
          <PopoverBody>
            <DateRange
              ranges={[
                {
                  key: 'selection',
                  startDate: range[0] || new Date(),
                  endDate: range[1] || new Date()
                }
              ]}
              onChange={handleSelection}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              direction='horizontal'
            />
          </PopoverBody>
        </Popover>
      )}
      {isFocused && (
        <PopoverScreen onClick={() => setFocused(false)} role='button' />
      )}
    </>
  );
};

DateRangeInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default DateRangeInput;
