import { getErrorToast, getErrorMessage } from '@utils';
import {
  GET_GLOBAL_SETTINGS_REQUEST,
  GET_GLOBAL_SETTINGS_SUCCESS,
  GET_GLOBAL_SETTINGS_FAILURE,
  GET_SHIPPING_LOCATIONS,
  GET_POSTING_PERIODS,
  ADD_POSTING_PERIOD,
  UPDATE_POSTING_PERIOD,
  ADD_LOCATION_SECTIONS,
  SET_TOAST,
  GET_VENDORS,
  GET_LOCATION_TRANSFERS,
  GET_SHIPMENTS,
  REMOVE_SHIPMENT,
  GET_ITEM_FULFILLMENTS,
  GET_SHIPMENT_NOTIFICATIONS,
  GET_WAREHOUSE_DOORS,
  GET_SCHEDULED_SHIPMENTS
} from '../types';

// eslint-disable-next-line import/prefer-default-export
export const getGlobalSettings =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch({
        type: GET_GLOBAL_SETTINGS_REQUEST
      });

      const res = await api.get('/global-settings');

      dispatch({
        type: GET_GLOBAL_SETTINGS_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: GET_GLOBAL_SETTINGS_FAILURE,
        payload: err.message
      });
    }
  };

export const getShippingLocations =
  () =>
  async (dispatch, getState, { api }) => {
    const res = await api.get(`/warehouse`);

    dispatch({
      type: GET_SHIPPING_LOCATIONS,
      payload: res.data
    });
  };

export const getPostingPeriods =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/accounting-periods`);

      dispatch({
        type: GET_POSTING_PERIODS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addPostingPeriod =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/accounting-periods`);

      dispatch({
        type: ADD_POSTING_PERIOD,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const togglePostingPeriod =
  (id, period) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(`/accounting-periods/${id}`, period);

      dispatch({
        type: UPDATE_POSTING_PERIOD,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getLocationSections =
  (warehouseId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/warehouse/${warehouseId}`);

      dispatch({
        type: ADD_LOCATION_SECTIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getVendors =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/vendors`);

      dispatch({
        type: GET_VENDORS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getLocationTransfers =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/location-transfer`);

      dispatch({
        type: GET_LOCATION_TRANSFERS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getWarehouseDoors =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/door`);

      dispatch({
        type: GET_WAREHOUSE_DOORS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getItemFulfillments =
  ({ startDate, endDate }) =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.post(`/item-fulfillment`, {
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(new Date(endDate).setHours(24, 0, 0, 0)).toISOString()
      });

      dispatch({
        type: GET_ITEM_FULFILLMENTS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getShipments =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/shipment`);

      dispatch({
        type: GET_SHIPMENTS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getScheduledShipments =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/shipment/scheduledShipments`);

      dispatch({
        type: GET_SCHEDULED_SHIPMENTS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeShipment =
  (shipmentId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/shipment/${shipmentId}`);

      dispatch({
        type: REMOVE_SHIPMENT,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const getMobileNotifications =
  () =>
  async (dispatch, _getState, { api }) => {
    try {
      const res = await api.get(`/shipment/notifications`);

      dispatch({
        type: GET_SHIPMENT_NOTIFICATIONS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };
