import { parseISO, isWithinInterval } from 'date-fns';

const getPostingPeriodFromDate = (date, postingPeriods) => {
  if (!date) {
    return null;
  }

  const d = typeof date === 'string' ? parseISO(date) : date;

  if (!d) {
    return null;
  }

  return postingPeriods.filter(({ startDate, endDate }) =>
    isWithinInterval(d, { start: startDate, end: endDate })
  )[0];
};

export default getPostingPeriodFromDate;
