import produce from 'immer';
import {
  GET_LOCATION_TRANSFER,
  UPDATE_LOCATION_TRANSFER,
  UPDATE_LOCATION_TRANSFER_STATUS
} from '@actions/types';

export const initialState = {
  summary: {
    status: '',
    statusCode: '',
    date: '',
    originWarehouseId: null,
    originWarehouseName: '',
    destinationWarehouseId: null,
    destinationWarehouseName: '',
    notes: ''
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case UPDATE_LOCATION_TRANSFER:
    case GET_LOCATION_TRANSFER: {
      draft.summary = action.payload;
      return draft;
    }

    case UPDATE_LOCATION_TRANSFER_STATUS: {
      draft.summary.status = action.payload.status;
      draft.summary.statusCode = action.payload.statusCode;
      return draft;
    }

    default:
      return draft;
  }
}, initialState);
