const formatTime = (time, type = 'UTC') => {
  if (!time) {
    return '';
  }

  const d = new Date(time);

  if (type === 'UTC-British') {
    return d.toLocaleTimeString([], {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC'
    });
  }

  let hours = d.getUTCHours();
  let minutes = d.getUTCMinutes();

  if (type !== 'UTC') {
    hours = d.getHours();
    minutes = d.getMinutes();
  }
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes} ${ampm}`;
};

export default formatTime;
