import produce from 'immer';
import {
  GET_DELIVERY,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_PROPERTIES
} from '@actions/types';

export const initialState = {
  summary: {
    id: null,
    type: '',
    typeCode: '',
    carrier: '',
    origin: '',
    destination: '',
    warehouseDoor: '',
    status: '',
    statusCode: '',
    trackingNumber: ''
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case UPDATE_DELIVERY:
    case GET_DELIVERY: {
      draft.summary = action.payload;
      return draft;
    }

    case UPDATE_DELIVERY_PROPERTIES: {
      draft.summary = { ...draft.summary, ...action.payload };
      return draft;
    }

    default:
      return draft;
  }
}, initialState);
