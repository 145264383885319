import React from 'react';
import NumberFormat from 'react-number-format';
import { formatPhone } from '@utils';
import { StyledInput } from '../StyledComponents';

const PhoneInput = ({ ...props }) => {
  return (
    <NumberFormat
      {...props}
      type='tel'
      customInput={StyledInput}
      format={formatPhone}
    />
  );
};

export default PhoneInput;
