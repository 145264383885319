import { getErrorToast, getErrorMessage } from '@utils';
import {
  ADD_ASSOCIATION,
  UPDATE_ASSOCIATION,
  REMOVE_ASSOCIATION,
  SET_TOAST,
  GET_ALPINE_ITEM_INFO,
  TOGGLE_PAGE_ERROR,
  RESET_ASSOCIATION
} from '../types';

/* eslint-disable no-unused-vars */

export const getAlpineItemInfo =
  (productId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/product/item/${productId}`);

      if (res.data) {
        dispatch({
          type: GET_ALPINE_ITEM_INFO,
          payload: res.data
        });
      }
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      dispatch({
        type: TOGGLE_PAGE_ERROR,
        payload: true
      });
    }
  };

export const getInventoryAssocation =
  (productId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.get(`/inventory/item/link/${productId}`);

      if (res.data) {
        dispatch({
          type: ADD_ASSOCIATION,
          payload: res.data
        });
      }
    } catch (e) {
      // swallow 400 error, because it just means user
      // needs to add the item association
      if (e.response?.status !== 400) {
        dispatch({
          type: SET_TOAST,
          payload: getErrorToast({
            body: getErrorMessage(e)
          })
        });
      }
    }
  };

export const addInventoryAssociation =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/inventory/item/link`, formValues);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: ADD_ASSOCIATION,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const addInventoryItemAndAssociation =
  (formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/inventory/linkeditem`, formValues);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: ADD_ASSOCIATION,
        payload: res.data[1]
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const updateInventoryAssociation =
  (productId, formValues) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.put(
        `/inventory/item/link/${productId}`,
        formValues
      );

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: UPDATE_ASSOCIATION,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const removeInventoryAssociation =
  (productId) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.delete(`/inventory/item/link/${productId}`);

      if (!res.data) {
        throw new Error(res.message);
      }

      dispatch({
        type: REMOVE_ASSOCIATION,
        payload: res.data.id
      });
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });
    }
  };

export const searchInventoryItems =
  (query) =>
  async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(`/inventory/item/search`, { query });

      if (!res.data) {
        throw new Error(res.message);
      }

      return res.data;
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: getErrorToast({
          body: getErrorMessage(e)
        })
      });

      return [];
    }
  };

export const resetInventoryAssociation = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_ASSOCIATION
    });
  } catch (e) {
    dispatch({
      type: SET_TOAST,
      payload: getErrorToast({
        body: getErrorMessage(e)
      })
    });
  }
};
