import castAsNumber from './castAsNumber';

const formatMoney = (value = 0) => {
  const resolvedValue = castAsNumber(value);
  const amount = Number.isNaN(value) ? 0 : resolvedValue;
  return `${amount.toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`;
};

export default formatMoney;
